/**
 *  PROPS
 * 
 *      img: "string" url of the image from s3 bucket
 */

import React from 'react';
import DivWithBackground from './divWithBackground';

const HeroImage = (props) => {
    return (
        props.content
            ?
            <DivWithBackground
                className="hero"
                content={props.content}
            >
                {props.children}
            </DivWithBackground>
            :
            <div
                className="hero"
                style={{ ...props.style,
                    backgroundImage: `url('${props.img}')`
                }}
            >
                {props.children}
            </div>
    )
}

export default HeroImage;