import $ from "jquery";
import React from 'react';
import Context from "../../context";
import FlipMove from "react-flip-move";

class MealPlanTester extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			conditions: [],
			recipes: [],
			mealPlan: [],
			mealPlanRanks: [],
			mealPlanRecipes: [],
			selectedConditions: [],
			working: false
		}
	}

	componentDidMount() {
		//Get the list of conditions
		this.getConditions()
	}

	getRecipeWithId(id) {
		// console.log(this.state.recipes)
		return this.state.mealPlanRecipes
			.filter(r => r.id === id)[0] || null;
	}

	getRecipes(conditions) {
		$.post({
			url: `${this.context.apiRoot}/meal-plans/test/recipes`,
			data: JSON.stringify({
				conditions
			}),
			contentType: "application/json"
		}).done(response => {
			this.setState({
				recipes: response.results,
				mealPlan: [],
				mealPlanRecipes: []
			});
		}).catch(err => {
			console.error("Error getting recipes for test: ", err);
		});
	}

	getRecipesForMealPlan(ids) {
		$.post({
			url: `${this.context.apiRoot}/recipes/retrieve`,
			data: JSON.stringify(ids),
			contentType: "application/json"
		}).done(response => {
			// console.log("RECIPES:", response.recipes);
			this.setState({
				mealPlanRecipes: response.recipes,
				recipes: [],
			});
		}).catch(err => {
			console.error("Error getting recipes for a meal plan")
		});
	}

	getMealPlan(conditions) {
		$.post({
			url: `${this.context.apiRoot}/meal-plans/test/generate`,
			data: JSON.stringify({ conditions }),
			contentType: "application/json"
		}).done(response => {
			//Flattening the mealPlan array and removing duplicate recipe id's
			this.setState({
				mealPlan: response.mealPlan.plan,
				// mealPlanRanks: response.mealPlan.ranks
			});
			let recipesToGet = [...new Set([].concat.apply([], response.mealPlan.plan))].filter(id => id)
			//Getting recipes
			this.getRecipesForMealPlan(recipesToGet);
		}).catch(err => {
			console.error("Error getting meal-plan for test", err);
		})
	}

	generateNutritionSample() {
		this.setState({ working: true });
		$.post({
			url: `${this.context.apiRoot}/meal-plans/test/nutrition/summary?type=csv`,
			data: JSON.stringify({ conditions: this.state.selectedConditions }),
			contentType: "application/json"
		}).done(response => {
			this.download(`Plan_nutrition_data_${Date.now()}.csv`, response);
			this.setState({ working: false });
		}).catch(err => {
			console.error("Error getting meal-plan sample nutrition:", err);
			this.setState({ working: false });
		});
	}

	download(fileName, text) {
		if (window.navigator.msSaveBlob) { // IE
			let blob = new Blob([text], { type: "text/plain;charset=utf-8;" });
			window.navigator.msSaveOrOpenBlob(blob, fileName);
		} else { // Reasonable browsers. 
			let element = document.createElement('a');
			element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
			element.setAttribute('download', fileName);
			element.style.display = 'none';
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
		}
	}

	generateNutritionForAllRecipes() {
		this.setState({ working: true });
		$.get({ url: `${this.context.apiRoot}/meal-plans/test/nutrition/allRecipeCalories?type=csv` }).done(response => {
			this.download(`All_recipes_nutrition_data_${Date.now()}.csv`, response);
			this.setState({ working: false });
		}).catch(err => {
			console.error("Error getting meal-plan sample nutrition:", err);
			this.setState({ working: false });
		});
	}

	getConditions() {
		$.get(`${this.context.apiRoot}/conditions`).done(res => {
			// console.log(res.results)
			this.setState({ conditions: res.results })
		}).catch(err => {
			console.error("Error getting conditions:", err)
		})
	}

	renderRecipe(recipe) {
		return (
			<div className="recipe" key={`${recipe.id}_${Math.random()}`}>
				<div className="recipe-inner">
					<div
						className="mealImage"
						style={{
							backgroundImage: (recipe.images && recipe.images[0]) ?
								`url("${recipe.images[0]}")` :
								`url("/static/media/meals/${recipe.name}.jpg")`
						}}
					/>
					<div className="name">
						{recipe.name}
					</div>
				</div>
			</div>
		)
	}

	renderMealPlan() {
		//Splitting meal plan into weeks
		let week1 = this.state.mealPlan.slice(0, 7);
		let week2 = this.state.mealPlan.slice(7, 14);
		let week3 = this.state.mealPlan.slice(14, 21);
		let week4 = this.state.mealPlan.slice(21, 28);
		return (
			<div className="mealPlan">
				{/* Iterate weeks */}
				{[week1, week2, week3, week4].map((weekDays, weekIndex) => (
					<div className={"container week" + weekIndex + 1} key={weekIndex}>
						<h2>Week {weekIndex + 1}</h2>
						{weekDays.map((day, weekDayIndex) => {
							return <div className="day row shadow2" key={`${weekIndex}_${weekDayIndex}`}>
								<div className="dayName">{weekDayIndex + (weekIndex * 7) + 1}</div>
								{day.map((recipe_id, mealIndex) => {
									let recipe = this.getRecipeWithId(recipe_id);
									return recipe
										?
										<div className="meal-plan-recipe col-12 col-md-4" key={`${mealIndex}_${weekDayIndex}_${weekIndex}`}>
											<div className="recipe-inner">
												<div
													className="mealImage"
													style={{
														backgroundImage: (recipe.images && recipe.images[0]) ?
															`url("${recipe.images[0]}")` :
															`url("/static/media/meals/${recipe.name}.jpg")`
													}}
												/>
												<div className="name">
													{recipe.name}
												</div>
											</div>
										</div>
										:
										<div className="meal-plan-recipe col-12 col-md-4" key={`${mealIndex}_${weekDayIndex}_${weekIndex}`}>
											<div className="recipe-inner">
												<div
													className="mealImage"
													style={{
														backgroundImage: 'url("/static/media/meals/Vegan Curried Vegetables.jpg")',
														opacity: "0.3"
													}}
												/>
												<div className="name">
													NO RECIPE
                                                </div>
											</div>
										</div>
								})}
							</div>
						})}
					</div>
				))}
			</div>
		)
	}

	render() {
		return (
			<div className="adminTab MealPlanTesterTab">
				<h2 className="title">Meal Plan Tester</h2>
				<div className="tester">
					<div className="inner">
						<FlipMove
							className="conditions"
							enterAnimation="fade"
							leaveAnimation="fade"
						>
							<label>Select conditions.</label>
							{/* {this.renderConditions()} */}
							{this.state.conditions.map(condition => {
								return <div
									key={condition.id}
									className={this.state.selectedConditions.includes(condition.id) ? "condition selected" : "condition"}
									onClick={e => {
										let alreadySelected = this.state.selectedConditions.includes(condition.id);
										// Toggle
										this.setState({
											selectedConditions: alreadySelected ?
												this.state.selectedConditions.filter(c => c !== condition.id)
												:
												[...this.state.selectedConditions, condition.id]
										})
									}}
								>
									{condition.name}
								</div>
							})}
						</FlipMove>
						{/* For generating and downloading sample data for meal plans */}
						<div className="sampleDataGenerator">
							<label style={{ marginTop: 20, marginBottom: 0 }}>Create a sample export of a meal plan's nutrition using the conditions selected.</label>
							<button className="button" onClick={this.generateNutritionSample.bind(this)} disabled={this.state.working}>
								{this.state.working ? "Working.." : "Generate Nutrition Data"}
							</button>
						</div>
						{/* For generating and downloading nutrition data for all recipes. */}
						<div className="sampleDataGenerator">
							<label style={{ marginTop: 20, marginBottom: 0 }}>Export nutritional data for all recipes.</label>
							<button className="button" onClick={this.generateNutritionForAllRecipes.bind(this)} disabled={this.state.working}>
								{this.state.working ? "Working.." : "Export all nutrition data"}
							</button>
						</div>

						<label style={{ marginTop: 20, marginBottom: 0 }}>List all matching recipes, or generate example meal plans.</label>
						<div
							className={this.state.selectedConditions.length ? "button left" : "button disabled left"}
							onClick={e => {
								if (this.state.selectedConditions.length)
									this.getRecipes(this.state.selectedConditions)
							}}
						>
							List Matching Recipes
                        </div>
						<div
							className="button right"
							onClick={e => {
								this.getMealPlan(this.state.selectedConditions)
							}}
						>
							Generate Meal Plan
                        </div>
						{this.state.recipes.length
							?
							<div className="recipes">
								{this.state.recipes.map(recipe => {
									return this.renderRecipe(recipe);
								})}
							</div>
							:
							null
						}
						{this.state.mealPlanRecipes.length
							?
							<div className="mealPlan">
								{this.renderMealPlan()}
							</div>
							:
							null
						}
					</div>
				</div>
			</div>
		)
	}
}

MealPlanTester.contextType = Context;

export default MealPlanTester;