import React from "react";
import Field from "./field";
import $ from "jquery";
import Context from "../context";
import ItemFinderField from "../fields/itemFinderField";

class RecipeCombinationField extends Field {
    constructor(props) {
        super(props)
        this.state.recipes = [];
        this.state.addingTo = null;
        this.settings = {
            type: "recipeCombinationField"
        }
    }

    componentDidUpdate(prevProps) {
        let currentVal = this.props.children || [];
        let lastVal = prevProps.children || [];
        if (JSON.stringify(lastVal) !== JSON.stringify(currentVal))
            this.getRecipes([...new Set(currentVal.filter(id => id))]);
    }

    getRecipes(ids) {
        if (!ids.length) return null;
        $.post({
            url: `${this.context.apiRoot}/recipes/retrieve`,
            data: JSON.stringify(ids),
            contentType: "application/json"
        }).done(response => {
            this.setState({
                recipes: response.recipes
            });
        }).catch(err => {
            console.error("Error getting recipes: ", err);
        });
    }

    getRecipe(id) {
        if (!id) return null;
        return this.state.recipes.filter(r => r.id === id)[0] || null;
    }

    renderField() {
        let plan = this.props.children || [null, null, null, null, null, null];
        // Function that actually renders the slot tile.
        const renderSlot = (slot) => {
            // Get a recipe, or a blank object.
            let recipe = this.getRecipe(plan[slot]) || {};
            // Get a plain text name for the meal.
            let meal = ["BREAKFAST", "LUNCH", "DINNER"][slot % 3];
            // Return a slot.
            return <div
                className="slot"
                key={slot}
            >
                <div className="slot-label">{meal} - {slot + 1}</div>
                <div
                    className="slot-inner"
                    style={recipe ? {
                        backgroundImage: (recipe.images && recipe.images[0]) ?
                            `url("${recipe.images[0]}")` :
                            `url("/static/media/meals/${recipe.name}.jpg")`
                    } : {}}
                >
                    {this.state.addingTo !== slot
                        ?
                        <div
                            className="recipe-name"
                            onClick={e => {
                                this.setState({
                                    addingTo: slot
                                });
                            }}
                        >
                            {plan[slot] ? recipe.name : "CLICK TO ADD RECIPE"}
                        </div>
                        :
                        <ItemFinderField
                            itemName="recipes"
                            change={(err, recipe) => {
                                let newPlan = [...plan];
                                newPlan[slot] = recipe.id;
                                this.setState({
                                    addingTo: null
                                }, () => {
                                    this.change(newPlan);
                                });
                            }}
                        />
                    }
                </div>
                {plan[slot] ? <span
                    className="remove-recipe"
                    onClick={e => {
                        let newPlan = [...plan];
                        newPlan[slot] = null;
                        this.change(newPlan);
                    }}
                >x</span> : null}
            </div>
        }
        return (
            <div className="container">
                <div className="row slots">
                    <div className="col-12 col-sm-6">
                        {[0, 1, 2].map(slot => renderSlot(slot))}
                    </div>
                    <div className="col-12 col-sm-6">
                        {[3, 4, 5].map(slot => renderSlot(slot))}
                    </div>
                </div>
            </div>
        )
    }

}

RecipeCombinationField.contextType = Context;

export default RecipeCombinationField;

