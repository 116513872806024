import $ from "jquery";
import Context from "../context";
import React from "react";

class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content: null,
            hovered: false
        }
    }

    componentDidUpdate(pp, ps) {
        let locator = this.props.content || this.props.children;
        let pLocator = pp.content || pp.children;
        if (pLocator !== locator && typeof locator === "string")
            this.getContent(locator);
    }

    componentDidMount() {
        let locator = this.props.content || this.props.children;
        if (locator && typeof locator === "string")
            this.getContent(locator);
    }

    getContent(name) {
        $.ajax({ url: `${this.context.apiRoot}/content/by-name/${name}` })
            .done(response => {
                this.setState({
                    content: response.content
                });
            }).catch(err => {
                console.error("Content error: ", err);
            });
    }

    renderContentBlock(block, index) {
        let content;
        let body = block.body.replace(/&quot;/g, "\"");
        switch (block.type) {
            case "h1":
            case "h2":
            case "h3":
            case "h4":
                content = <block.type>{body}</block.type>;
                break;
            case "image":
                content = <img src={body} alt="post" />;
                break;
            case "video":
                content = <div className="iframeWrapper">
                    <iframe src={body} frameBorder="0" />
                </div>
                break;
            default:
                content = <div dangerouslySetInnerHTML={{ __html: body }} />;
        }
        // return the container with the column width.
        return <div key={index} className={`col-12 col-md-${block.width} blog-column`}>
            {content}
        </div>
    }

    renderContent({ type, value, name }) {
        let result;
        let isAdmin = this.context.session.user && this.context.session.user.isAdmin;
        // Hover handlers.
        const hover = e => isAdmin ? this.setState({ hovered: true }) : null;
        const leave = e => isAdmin ? this.setState({ hovered: false }) : null;

        switch (type) {
            case "image":
                result = <img
                    onMouseOver={hover}
                    onMouseLeave={leave}
                    title={isAdmin ? name : null}
                    src={value}
                    alt={value}
                />;
                break;
            case "html":
            case "richText":
                result = <div
                    onMouseOver={hover}
                    onMouseLeave={leave}
                    title={isAdmin ? name : null}
                    dangerouslySetInnerHTML={{ __html: value }}
                />;
                break;
            case "video":
                result = <iframe
                    onMouseOver={hover}
                    onMouseLeave={leave}
                    title={isAdmin ? name : null}
                    src={value}
                    frameBorder="0"
                ></iframe>;
                break;
            case "contentBlocks":
                result = <div
                    onMouseOver={hover}
                    onMouseLeave={leave}
                    title={isAdmin ? name : null}
                    className="container"
                >
                    <div className="row">
                        {JSON.parse(value).map(this.renderContentBlock)}
                    </div>
                </div>
                break;
            default:
                result = <span
                    onMouseOver={hover}
                    onMouseLeave={leave}
                    title={isAdmin ? name : null}
                >{value}</span>;
                break;
        }
        return result || null;
    }

    render() {
        return this.state.content && this.state.content.value ? <React.Fragment>
            {this.renderContent(this.state.content)}
            {this.state.hovered
                ?
                <a
                    className="content-hover-name"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/admin/content?name=${this.state.content.name}`}
                    onMouseLeave={e => {
                        this.setState({ hovered: false });
                    }}
                    onMouseEnter={e => {
                        this.setState({ hovered: true });
                    }}
                >
                    {this.state.content.name}
                </a>
                : null
            }
        </React.Fragment> : null;
    }

}

Content.contextType = Context;

export default Content;