import React from "react";
import Field from "./field";

class VideoField extends Field {

    constructor(props) {
        super(props)
        this.settings = { type: "videoField" }
    }

    createEmbedUrl(rawValue) {
        let embedString = "",
            videoId;
        if (/youtube.com/g.test(rawValue)) {
            // Youtube
            if (videoId = /v=([^=&?]+)/.exec(rawValue)) {
                // Standard YT links.
                embedString = `//www.youtube.com/embed/${videoId[1]}`;
            } else if (videoId = /embed\/([^/?&\s]+)/.exec(rawValue)) {
                // YT embed URLs.
                embedString = `//www.youtube.com/embed/${videoId[1]}`;
            } else {
                // Failures.
                embedString = null;
            }
        } else if (/vimeo.com/g.test(rawValue)) {
            // Vimeo
            videoId = /vimeo.com\/([^=&?/]+)/g.exec(rawValue);
            if (videoId)
                embedString = `//player.vimeo.com/video/${videoId[1]}`;
        } else {
            embedString = null;
        }
        return embedString;
    }

    renderPreview(rawValue) {
        if (!rawValue) {
            return (
                <div className="preview"></div>
            )
        } else if (this.createEmbedUrl(rawValue)) {
            return (
                <div className="preview has-video">
                    <iframe
                        title="preview"
                        src={this.createEmbedUrl(this.props.children)}
                        ref="iframe"
                        height="300"
                        frameBorder="0"
                    >
                    </iframe>
                </div>
            )
        } else {
            return (
                <div className="preview show-error">
                    <i className="fas fa-ban no-video"></i>
                </div>
            )
        }
    }

    change(value) {
        let eUrl = this.createEmbedUrl(value);
        super.change(eUrl ? eUrl : value);
    }

    renderField(tag) {
        // Generate the class for the preview container.
        let url = this.createEmbedUrl(this.props.children);
        let previewClass = ((value) => {
            if (!value) return "";
            return url ? "has-video" : "bad-video";
        })(this.props.children);

        return (
            <div>
                {/* {this.renderPreview(this.props.children)} */}
                <div className={"preview " + previewClass}>

                    <iframe
                        title="preview"
                        src={url}
                        ref="iframe"
                        height="300"
                        frameBorder="0"
                    />

                    <i className="fas fa-ban"></i>

                </div>
                <div className="url">
                    <label>Video URL:</label>
                    <input
                        value={this.props.children}
                        type="text"
                        onChange={e => {
                            this.change(e.target.value)
                        }}
                    />
                </div>
            </div>
        )
    }

}

export default VideoField;