import React from "react";
import Tab from "./tab";
import ContentCreator from "../creators/contentCreator";

class Content extends Tab {

    itemCreator(item) {
        return (
            <ContentCreator
                key={"contentCreator" + (item ? item.id : "new")}
                displayName={this.displayName}
                item={item}
                itemUpdate={(changes, id) => {
                    this.editItem(changes, id)
                }}
                itemCreate={item => {
                    this.createItem(item)
                }}
                itemDelete={id => {
                    this.deleteItem(id)
                }}
                close={() => {
                    this.setState({ adding: false, selected: null })
                }}
            /> 
        );
    }
}

export default Content;