import React from "react";
import Tab from "./tab";
import FlipMove from "react-flip-move";
import UserCreator from "../creators/userCreator";

class Users extends Tab {
    constructor(props, context) {
        super(props, context);
        this.displayName = "uname"
    }

    itemCreator(item) {
        return (
            <UserCreator
                key={"UserCreator" + (item ? item.id : "new")}
                item={item}
                displayName={this.displayName}
                updateUser={item => {
                    let items = this.state.items.map(i => {
                        if (i.id === item.id) return item;
                        return i
                    })
                    this.setState({ items });
                }}
                close={() => {
                    this.setState({ adding: false, selected: null })
                }}
            />
        )
    }


    renderContent() {
        let query = this.state.query.toLowerCase();
        let items = this.state.items.filter(item => {
            if (query) {
                return (item[this.displayName] + "").toLowerCase().includes(query);
            } else {
                return true;
            }
        });
        //Limit results to 20 items per page (if more than 20 items)
        if (items.length > 15) {
            let start = this.state.page * 15;
            let end = start + 15;
            items = items.slice(start, end);
        }
        return items.map(item => {
            if (item.id === this.state.selected) {
                //If you want to edit this item
                return this.itemCreator(item);
            } else {
                return (
                    <div
                        className="item"
                        key={item.id}
                        onClick={e => {
                            if (e.target.className === "optionsImg") return null;
                            this.setState({ selected: item.id, adding: false })
                        }}
                    >
                        {this.renderRowHeader(item)}
                        {this.renderItemOptions(item)}
                    </div>
                )
            }
        })
    }

    render() {
        return (
            <div className={`adminTab ${this.props.name}Tab`}>
                <h2 className="title">{this.props.name}</h2>
                {this.renderAbove ?
                    <div className="above">
                        {this.renderAbove()}
                    </div>
                    : null
                }
                <FlipMove
                    className="itemList"
                    enterAnimation="fade"
                    leaveAnimation="fade"
                    duration={200}
                >
                    <input
                        type="text"
                        placeholder="Filter items.."
                        defaultValue={this.context.query.name ? this.context.query.name : ""}
                        onChange={e => {
                            let query = e.target.value;
                            if (this.delay) clearTimeout(this.delay);
                            this.delay = setTimeout(() => {
                                this.setState({ query });
                            }, 250);
                        }}
                    />
                    {this.renderContent()}
                    {this.state.items.length > 15
                        ?
                        this.renderPageChanger()
                        :
                        null
                    }
                </FlipMove>
                {this.renderBelow ?
                    <div className="below">
                        {this.renderBelow()}
                    </div>
                    : null
                }
            </div>
        )
    }

}

export default Users;
