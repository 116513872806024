import React from "react";
import Recaptcha from "react-google-recaptcha";

class SimpleForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                // Arrays
            },
            values: {
                // Direct values
            },
            recaptchaResponse: false
        }
    }
    canSubmit() {
        let canSubmit = true;
        // If there are errors, no go.
        Object.keys(this.state.errors).forEach(field => {
            if (this.state.errors[field])
                canSubmit = false;
        });
        // Next check for required values.
        this.props.fields
            .filter(field => field.required)
            .forEach(field => {
                if (!this.state.values[field.varname])
                    canSubmit = false;
            });
        // Return the result.
        return canSubmit;
    }
    change(changedField, value) {
        // Force lowercase for email address
        if (changedField.postProcess) {
            value = changedField.postProcess(value);
        }
        // Get errors object ready.
        let errors = {};
        // Assign the new value into the values object.
        let values = Object.assign({}, this.state.values, {
            [changedField.varname]: value
        });
        // Iterate all fields.
        this.props.fields.forEach(field => {
            // Get a result for each field.
            if (values[field.varname] !== undefined) {
                let result = field.validator ?
                    field.validator(values[field.varname], Object.assign({}, values))
                    :
                    true;
                // Handle error.
                if (result !== true) {
                    errors[field.varname] = (typeof result === "string" ? result : "Bad value.");
                }
            }
        });
        if (this.props.onChange)
            this.props.onChange(errors, values);
        this.setState({
            // Add the changed value to state.
            values,
            // Add the errors.
            errors
        });
    }
    renderField(fieldData) {
        // Create the classes for the field.
        let fieldClasses = ["field", "field-type-" + (fieldData.type || "text")];
        // Field been used?
        if (this.state.values[fieldData.varname] !== undefined) {
            fieldClasses.push("dirty");
            // Also check if required and without value.
            if (fieldData.required && !this.state.values[fieldData.varname])
                fieldClasses.push("bad");
        } else {
            fieldClasses.push("clean");
        }
        // Field has an error?
        if (
            this.state.errors[fieldData.varname] &&
            !fieldClasses.includes("bad")
        ) {
            fieldClasses.push("bad");
        } else {
            fieldClasses.push("good");
        }
        // Render actual field.
        let inner;
        if (fieldData.type === "select") {
            inner = <select
                onChange={e => {
                    this.change(fieldData, e.target.value)
                }}
            >
                {fieldData.options.map(option => {
                    return <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                })}
            </select>
        } else if (fieldData.type === "password") {
            inner = <input
                value={this.state.values[fieldData.varname] || ""}
                type="password"
                placeholder={fieldData.label || fieldData.varname}
                onChange={e => {
                    this.change(fieldData, e.target.value);
                }}
            />
        } else if (fieldData.type === "textarea") {
            inner = (
                <textarea
                    value={this.state.values[fieldData.varname] || ""}
                    placeholder={fieldData.label || fieldData.varname}
                    onChange={e => {
                        this.change(fieldData, e.target.value);
                    }}
                />
            )

        } else if (fieldData.type === "recaptcha" && this.props.recaptchaSiteKey) {
            inner = <Recaptcha
                sitekey={this.props.recaptchaSiteKey}
                onChange={res => {
                    this.change(fieldData, res)
                }}
            />;
        } else if (fieldData.type === "custom") {
            inner = fieldData.render({
                handler: this.change.bind(this),
                fieldData
            });
        } else {
            // Default to text.
            inner = <input
                value={this.state.values[fieldData.varname] || ""}
                type="text"
                placeholder={fieldData.label || fieldData.varname}
                onChange={e => {
                    this.change(fieldData, e.target.value);
                }}
            />
        }
        // render the wrapper and any errors.
        let error = this.state.errors[fieldData.varname];
        return <div className={fieldClasses.join(" ")} key={fieldData.varname}>
            {fieldData.overLabel ? <label>{fieldData.overLabel}</label> : null}
            {inner}
            {error ? <div className="errors">
                <span key={error} className="error">{error}</span>
            </div> : null}
        </div>
    }
    render() {

        return <div className="form">
            <div className="fields">
                {this.props.fields.map(field => {
                    return this.renderField(field)
                })}
            </div>
            {this.props.onSubmit ? <div className={"submit" + (!this.canSubmit() ? " hidden" : "")}>
                <div
                    className="button"
                    onClick={e => {
                        if (this.canSubmit() && this.props.onSubmit)
                            this.props.onSubmit(Object.assign({}, this.state.values), () => {
                                //Callback function to hide the save button
                                this.setState({ values: {} })
                            });
                    }}>
                    {this.props.submitLabel ? this.props.submitLabel : "Submit"}
                </div>
            </div> : null}
        </div>
    }

}

export default SimpleForm;