import $ from "jquery";
import React from 'react';
import Recipe from "../account/mealPlan/recipe";
import Context from "../../context";

//Media import
import backArrow from "../../media/icon-arrow-white.svg"

class RecipePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recipe: null
        }
    }
    componentDidMount() {
        if (Boolean(this.context.session.user) && !this.state.recipe) 
            this.getRecipe(this.props.router.match.params.recipeId);
        this.scroll();
    }
    scroll(cb) {
        let scrollTo = $("header").height() + $(".hero").height();
        $("html").animate({ scrollTop: scrollTo }, 500);
        if (cb) cb();
    }
    componentDidUpdate(prevProps) {
        let recipeId = this.props.router.match.params.recipeId;
        if (Boolean(this.context.session.user) && (!this.state.recipe || recipeId !== prevProps.router.match.params.recipeId))  {
            this.getRecipe(this.props.router.match.params.recipeId);
            this.scroll();
        }
    }
    getRecipe(id) {
        $.post({
            url: `${this.context.apiRoot}/recipes/retrieve`,
            data: JSON.stringify([Number(id)]),
            contentType: "application/json"
        }).done(response => {
            this.setState({
                recipe: response.recipes[0]
            });
        }).catch(err => {
            console.error("Error getting recipes: ", err);
        });
    }
    render() {
        return <div className="container single-recipe-view">
            <div className="row">
                <div className="col-12">
                    {this.state.recipe
                        ?
                        <Recipe 
                            recipe={this.state.recipe} 
                            key={this.state.recipe.id}
                        />
                        :
                        null
                    }
                    <div 
                        className="goBack button"
                        onClick={() => {
                            this.props.router.history.goBack()    
                        }}
                    >
                        Go back
                        <img src={backArrow} alt="back arrow"/>
                    </div>
                </div>
            </div>
        </div>
    }
}

RecipePage.contextType = Context;

export default RecipePage;