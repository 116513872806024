import React from "react";
import ItemCreator from "./itemCreator";
import TextField from "../../../fields/textField";
import RecipeCombinationField from "../../../fields/recipeCombinationField";


class RecipeCombinationCreator extends ItemCreator {
    canSubmit() {
        if (this.props.item) {
            //If updating an existing ingredient
            if (Object.keys(this.state.changes).length) return true;
            return false;
        } else {
            if (this.state.item.name && this.state.item.plan) {
                return true;
            }
        }
        //Return false by default.
        return false;
    }

    renderCreator() {
        return (
            <div className="creatorFields">
                <TextField
                    label="Name"
                    change={(err, val) => {
                        this.itemChanged("name", val);
                    }}
                >
                    {this.state.item.name}
                </TextField>
                <RecipeCombinationField
                    label="Combo Selector"
                    change={(err, val) => {
                        this.itemChanged("plan", val);
                    }}
                >
                    {this.state.item.plan}
                </RecipeCombinationField>
            </div>
        )
    }
}

export default RecipeCombinationCreator;