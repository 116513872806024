import React from "react";
import { Helmet } from 'react-helmet';
import Page from "./page";
import HeroImage from "../assets/hero";
import Login from "../assets/login";

//Media import 
import heroImg from "../media/hero-home.jpg";
import logo from "../media/RYG-Logo-Blue.svg";
import Context from "../context";

class LoginPage extends Page {
    pageContent() {
        return (
            <>
                <Helmet>
                    <title>Reset your Health Login</title>
                </Helmet>
                <HeroImage img={heroImg} style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                }}>
                    <div className="hero-content">
                        <div className="textImg">
                            <h2>Login</h2>
                        </div>
                    </div>
                </HeroImage>
                <div className="container" style={{ minHeight: "46vh" }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="boxWrapper">
                                <img className="logo" src={logo} alt="logo"/>
                                <Login />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

LoginPage.contextType = Context;

export default LoginPage