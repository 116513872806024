import $ from "jquery";
import React from 'react';
import Context from "../../../context";
import { NavLink, Link } from "react-router-dom";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from "moment";
import Content from "../../../assets/content";
import DivWithBackground from "../../../assets/divWithBackground";
import SuccessAlert from "../../../assets/successAlert";

// Media import
import closeIcon from "../../../media/icon-close-white.svg";
import warningYellow from "../../../media/warningIcon-yellow.svg";
import warningIcon from "../../../media/warningIcon.svg";
import arrowIcon from "../../../media/icon-arrow-white.svg";
import calendarIcon from "../../../media/icon-calendar-white.svg";
import { discountCodes } from "../../../discountCodes/discountsCodes";

// Date questionnaires are available after.
const Q_DATE = "2019-09-20";

class BeforeYouStart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            modalLocked: false,
            recipes: [],
            showCalendar: false,
            pushBackValue: null,
            questionnaireRejected: false,
            isRenderedQuestionnaire: true,
            showRestartPlan: false,
            discountCode: null,
            MealPlanResponse: null,
        }
    }

    getMealPlan() {
        $.get(`${this.context.apiRoot}/meal-plans/`).then(res => {
            if (res) this.setState({ MealPlanResponse: res })
            if (res.mealPlan.discountCode) {
                let mealPlanDiscountCode = res.mealPlan.discountCode;
                this.setState({ discountCode: mealPlanDiscountCode.toUpperCase() })
            }
        }).catch(err => {
            console.error("Error getting meal plan", err);
        })
    }

    setCookie(name, value) {
        let expires = new Date(Date.now() + (1000 * 60 * 60 * 24));
        document.cookie = `${name}=${value}; Expires=${expires.toUTCString()}`;
    }

    daysSinceStarting() {
        if (!this.props.start) return 0;
        return moment().diff(this.props.start, "days");
    }

    showQuestionnairePrompts() {
        if (!this.props.start) return false;
        // console.log(moment(Q_DATE), this.props.start);
        return moment(Q_DATE).isSameOrBefore(this.props.start);
    }

    getCookie(name) {
        let result = {};
        document.cookie
            .split(";")
            .map(p => p.trim())
            .filter(p => p)
            .map(p => p.split("="))
            .forEach(pair => {
                result[pair[0]] = pair[1];
            });
        return result[name];
    }

    componentDidUpdate(prevProps, prevState) {
        const { modal, modalLocked, discountCode, MealPlanResponse } = this.state;
        const { questionnaireSubmitType } = this.props;
        if (modal !== prevState.modal || modalLocked !== prevState.modalLocked) {
            if (modal) {
                $(".customModal").fadeIn(200);
            } else {
                $(".customModal").fadeOut(200);
            }
        }

        if (questionnaireSubmitType !== prevProps.questionnaireSubmitType ||
            discountCode !== prevState.discountCode) {
            if (discountCode && discountCodes.includes(discountCode)) {
                this.setState({
                    modal: false,
                    modalLocked: false
                });
            } else if (
                MealPlanResponse &&
                questionnaireSubmitType === "before" &&
                this.showQuestionnairePrompts()
            ) {
                this.setState({
                    modal: "questionnaire-1",
                    modalLocked: true
                });
            } else if (
                !this.getCookie("questionnaireRejected") &&
                questionnaireSubmitType === "after" &&
                this.daysSinceStarting() > 28 &&
                this.showQuestionnairePrompts()
            ) {
                this.setState({
                    modal: "questionnaire-2",
                    modalLocked: true
                });
            }
        }
    }

    componentDidMount() {
        this.getMealPlan();
        this.getCookie();
        if (
            this.props.questionnaireSubmitType === "before" &&
            this.showQuestionnairePrompts()
        ) {
            this.setState({
                modal: "questionnaire-1",
                modalLocked: true
            });
        } else if (
            !this.getCookie("questionnaireRejected") &&
            this.props.questionnaireSubmitType === "after" &&
            this.daysSinceStarting() > 28 &&
            this.showQuestionnairePrompts()
        ) {
            this.setState({
                modal: "questionnaire-2",
                modalLocked: true
            });
        }
    }

    modalContent(option, closeModal) {
        switch (option) {
            case "b12":
                return (
                    <React.Fragment>
                        <h2 className="heading">
                            <img src={warningYellow} alt="warning icon" />
                            B12 Warning for Vegans and Vegetarians
                        </h2>
                        <Content>before-page-b12-warning-content</Content>
                    </React.Fragment>
                )
            case "contact nutri":
                return (
                    <React.Fragment>
                        <h2 className="heading">
                            Contact a Nutritionist or Dietitian
                        </h2>
                        <Content>useful-info-contact-nutri</Content>
                    </React.Fragment>
                )
            case "faecal":
                return (
                    <React.Fragment>
                        <h2 className="heading">
                            Consider a faecal test
                        </h2>
                        <Content>useful-info-contact-nutri</Content>
                    </React.Fragment>
                )
            case "questionnaire-1":
                return (
                    <React.Fragment>
                        <h2 className="heading">
                            Remember your pre-plan questionnaire!
                        </h2>
                        <br />
                        <Content>before-page-questionnaire-1-content</Content>
                        <br />
                        <div style={{ textAlign: "center" }}>
                            <Link to="/account/meal-plan/questionnaire">
                                <div className="button">
                                    Start the questionnaire now.
                                </div>
                            </Link>
                        </div>
                    </React.Fragment>
                )
            case "questionnaire-2":
                return (
                    <React.Fragment>
                        <h2 className="heading">
                            Complete your post-plan questionnaire!
                        </h2>
                        <br />
                        <Content>before-page-questionnaire-2-content</Content>
                        <br />
                        <div style={{ textAlign: "center" }}>
                            <Link to="/account/meal-plan/questionnaire">
                                <div className="button" style={{
                                    margin: 10
                                }}>
                                    Start the questionnaire now.
                                </div>
                            </Link>
                            <div className="button" onClick={e => {
                                this.setCookie("questionnaireRejected", "true");
                                closeModal();
                            }} style={{
                                margin: 10
                            }}>
                                I'm not finished yet.
                            </div>
                        </div>
                    </React.Fragment>
                )
            default:
                return null
        }
    }

    render() {
        return (
            <div className="beforeView">
                <div className="customModal">
                    <div className="inner shadow1">
                        <div className="content">
                            {!this.state.modalLocked ? <img
                                className="closeModal"
                                src={closeIcon}
                                alt="close modal icon"
                                onClick={() => {
                                    this.setState({ modal: false, modalLocked: false })
                                }}
                            /> : null}
                            {this.modalContent(this.state.modal, () => {
                                this.setState({ modal: false, modalLocked: false })
                            })}
                        </div>
                    </div>
                </div>
                <div className="container">
                    {this.context.resNewMealPlan &&
                        <SuccessAlert message={'New meal plan successfully created'} />
                    }
                    <div className="row">
                        <div className="col-12">
                            <br /><br />
                            <Content>before-page-heading-text</Content>
                            <div className="pushBack">
                                <div className="daySelector" key={"daySelector"}>
                                    <Content>before-page-pushback-text</Content>
                                    <span>Push back my plan to...</span>
                                    <div className="dayPickerWrapper">
                                        <div
                                            className="button showCalendar"
                                            onClick={() => {
                                                let showCalendar = !this.state.showCalendar;
                                                this.setState({ showCalendar })
                                            }}
                                        >
                                            <img className="calendarIcon" src={calendarIcon} alt="calendarIcon" />
                                            Select Date
                                        </div>
                                        {discountCodes.includes(this.state.discountCode) &&
                                            <span style={{ margin: '0 8px' }}> Or </span>
                                        }
                                        {discountCodes.includes(this.state.discountCode) &&
                                            <NavLink
                                                exact
                                                to="/restart"
                                                className="button"
                                            >
                                                Restart plan
                                            </NavLink>
                                        }
                                        {this.state.showCalendar && this.props.start
                                            ?
                                            <DayPicker
                                                selectedDays={this.state.pushBackValue || this.props.start.clone().toDate()}
                                                onDayClick={day => {
                                                    this.setState({
                                                        showCalendar: false,
                                                        pushBackValue: day
                                                    })
                                                }}
                                            />
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                {this.state.pushBackValue !== null
                                    ?
                                    <div className="newDate" key="newDate">
                                        <span>Your new start date will be the </span>
                                        <span className="date">{moment(this.state.pushBackValue).format("Do MMMM YYYY")}.</span>
                                        <div className="buttons">
                                            <div
                                                className="button"
                                                onClick={() => {
                                                    this.props.startDateChanges(moment(this.state.pushBackValue).format("YYYY-MM-DD 00:00:00"), () => {
                                                        this.setState({ pushBackValue: null })
                                                    })
                                                }}
                                            >
                                                Confirm
                                            </div>
                                            <div
                                                className="button cancel"
                                                onClick={() => {
                                                    this.setState({ pushBackValue: null })
                                                }}
                                            >
                                                Cancel
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row tiles-row">
                        <div className="col-12 col-md-6 col-lg-4 tile-col">
                            <div className="pageTile orange">
                                <h2><span>RYH</span> Questionnaire</h2>
                                <Content>before-page-questionnaire-text-content</Content>
                                {(submitType => {
                                    if (submitType === "before") {
                                        return <NavLink
                                            to="/account/meal-plan/questionnaire"
                                            className="button shadow2"
                                        >
                                            PRE-PLAN QUESTIONNAIRE
                                        </NavLink>
                                    } else if (submitType === "after") {
                                        return <NavLink
                                            to="/account/meal-plan/questionnaire"
                                            className="button shadow2"
                                        >
                                            POST-PLAN QUESTIONNAIRE
                                        </NavLink>
                                    } else if (submitType === false) {
                                        return <NavLink
                                            to="/account/meal-plan/questionnaire"
                                            className="button shadow2"
                                        >
                                            All completed! Great job.
                                        </NavLink>
                                    } else {
                                        return null;
                                    }
                                })(this.props.questionnaireSubmitType)}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 tile-col">
                            <DivWithBackground
                                className="pageTile bgImg"
                                content="before-page-tile-1-img"
                            >
                                <div>
                                    <h2>Weekly</h2>
                                    <h1>GUIDELINES</h1>
                                </div>
                                <NavLink
                                    exact
                                    to="/useful-information/weekly-guidelines"
                                    className="button shadow2"
                                >
                                    GUIDES
                                </NavLink>
                            </DivWithBackground>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 tile-col">
                            <DivWithBackground
                                className="pageTile bgImg"
                                content="before-page-tile-2-img"
                            >
                                <div>
                                    <h1>TOP TIPS</h1>
                                    <h2>To reset your health</h2>
                                </div>
                                <NavLink
                                    exact
                                    to="/useful-information/top-tips"
                                    className="button shadow2"
                                >
                                    TOP TIPS
                                </NavLink>
                            </DivWithBackground>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 tile-col">
                            <DivWithBackground
                                className="pageTile bgImg"
                                content="before-page-tile-3-img"
                            >
                                <div>
                                    <h2>Healthy</h2>
                                    <h1>SNACKS</h1>
                                </div>
                                <NavLink
                                    exact
                                    to="/useful-information/healthy-snacks"
                                    className="button shadow2"
                                >
                                    SNACKS
                                </NavLink>
                            </DivWithBackground>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 tile-col">
                            <DivWithBackground
                                className="pageTile bgImg"
                                content="before-page-tile-4-img"
                            >
                                <div>
                                    <h2>How does the</h2>
                                    <h1>RESET YOUR HEALTH</h1>
                                    <h2>programme work?</h2>
                                </div>
                                <NavLink
                                    exact
                                    to="/useful-information/how-does-reset-your-gut-programme-work"
                                    className="button shadow2"
                                >
                                    RYH PROGRAMME
                                </NavLink>
                            </DivWithBackground>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 tile-col">
                            <div className="pageTile noPadding">
                                <div
                                    className="tileLink fecal"
                                    onClick={() => {
                                        this.setState({ modal: "contact nutri" })
                                    }}
                                >
                                    <img src={arrowIcon} alt="arrow icon" />
                                    <span>CONTACT A NUTRITIONIST OR DIETITIAN</span>
                                </div>
                                {[
                                    {
                                        text: "SUPPLEMENT ADVICE",
                                        url: "/useful-information/supplement-advice"
                                    },
                                ].map(link => {
                                    return (
                                        <NavLink
                                            key={link.text}
                                            exact
                                            to={link.url}
                                            className="tileLink"
                                        >
                                            <img src={arrowIcon} alt="arrow icon" />
                                            <span>{link.text}</span>
                                        </NavLink>
                                    )
                                })}
                                <div
                                    className="tileLink fecal"
                                    onClick={() => {
                                        this.setState({ modal: "faecal" })
                                    }}
                                >
                                    <img src={arrowIcon} alt="arrow icon" />
                                    <span>CONSIDER A FAECAL TEST</span>
                                </div>
                                <div
                                    className="tileLink b12"
                                    onClick={() => {
                                        this.setState({ modal: "b12" })
                                    }}
                                >
                                    <img src={warningIcon} alt="warning icon" />
                                    <span>B12 WARNING FOR VEGANS &amp; VEGETARIANS</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

BeforeYouStart.contextType = Context;

export default BeforeYouStart;