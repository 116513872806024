import React from "react";
import Tab from "./tab";
import RecipeCombinationCreator from "../creators/recipeCombinationCreator";

class RecipeCombinations extends Tab {

    constructor(props, context) {
        super(props, context);
        this.displayName = "name"
    }

    itemCreator(item) {
        return (
            <RecipeCombinationCreator
                key={"recipeCombinationCreator" + (item ? item.id : "new")}
                displayName={this.displayName}
                item={item}
                itemUpdate={(changes, id) => {
                    this.editItem(changes, id)
                }}
                itemCreate={item => {
                    this.createItem(item)
                }}
                itemDelete={id => {
                    this.deleteItem(id)
                }}
                close={() => {
                    this.setState({ adding: false, selected: null })
                }}
            /> 
        ) 
    }

}

export default RecipeCombinations;