/**
 * FOOTER
 * 
 *      PROPS:
 *          links: ARRAY of OBJECTS { name: "", path: "" } 
 */

import React from 'react';
import { NavLink } from "react-router-dom";
import Content from "./content";

//Media import
import logo from "../media/RYG-Logo-Nav.svg";
import terms_of_use from "../media/otherDocs/Terms of use - Reset Your Health.pdf";
import privacyNotice from "../media/otherDocs/Privacy Notice - Reset Your Health.pdf";
import cookiePolicy from "../media/otherDocs/Cookie policy - Reset Your Health.pdf";

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-3 links">
                            <ul className="footer-nav">
                                {this.props.links.filter(l => l.name !== "LOGIN").map(link => {
                                    return (
                                        <li key={`${link.path} footer`}>
                                            <a href={link.path}>{link.name}</a>
                                        </li>
                                    )
                                })}
                                <br />
                                {/* <li>
                                    <NavLink
                                        activeClassName="active"
                                        to="/privacy"
                                    >
                                        PRIVACY POLICY
                                    </NavLink>
                                </li> */}
                                <li>
                                    <NavLink
                                        to="/contact"
                                    >
                                        CONTACT US
                                    </NavLink>
                                </li>
                                <li>
                                    <a href={terms_of_use} target="_blank" rel="noopener noreferrer">TERMS OF USE</a>
                                </li>
                                <li>
                                    <a href={privacyNotice} target="_blank" rel="noopener noreferrer">PRIVACY POLICY</a>
                                </li>
                                <li>
                                    <a href={cookiePolicy} target="_blank" rel="noopener noreferrer">COOKIE POLICY</a>
                                </li>
                                <br/>
                            <li>RYH © {new Date().getFullYear()}</li>
                                <li>ALL RIGHTS RESERVED</li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-5 disclaimer">
                            <Content>footer-disclaimer</Content>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="logo">
                                <img src={logo} alt="logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

Footer.defaultProps = {
    links: [
        {
            name: "HOME",
            path: "https://resetyourhealth.com/"
        },
        {
            name: "ABOUT US",
            path: "https://resetyourhealth.com/about/ryh/"
        },
        {
            name: "FAQ",
            path: "https://resetyourhealth.com/faq/"
        },
        {
            name: "REGISTER",
            path: "https://resetyourhealth.com/register/"
        },
        {
            name: "LOGIN",
            path: "https://resetyourhealth.com/login"
        }
    ]
}

export default Footer;