import React from "react";
import Field from "./field";

import closeIcon from "../media/icon-close.svg";

class TextBuilder extends Field {
    constructor(props) {
        super(props)
        this.settings = {
            type: "textBuilderField"
        }
    }

    change(value, index) {
        let error = null;
        if (this.props.validator) {
            error = this.props.validator(value);
            this.setState({ error })
        }
        if (this.props.change) {
            this.props.change(error, value, index)
        }
    }

    renderField() {
        let items = this.props.children || [];

        return (
            <div>
                {items.map((item, index) => {
                    return (
                        <div
                            className="textBuilderItem"
                            key={index}
                        >
                            <span className="counter">{index+1}.</span>
                            <textarea
                                placeholder={`Step ${index + 1}`}
                                value={item}
                                onChange={e => {
                                    let newItems = [...items];
                                    newItems[index] = e.target.value;
                                    this.change(newItems);
                                }}
                            >
                            </textarea>
                            <img
                                src={closeIcon}
                                alt="remove"
                                onClick={() => {
                                    let newItems = [...items];
                                    newItems.splice(index, 1);
                                    this.change(newItems);
                                }}
                            />
                        </div>
                    )
                })}
                <div
                    key="add-btn"
                    className="add-btn button"
                    onClick={() => {
                        this.change([...items, ""]);
                    }}
                >
                    Add method section
                </div>
            </div>
        )
    }
}

export default TextBuilder;