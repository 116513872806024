import chevron from "../media/icon-chevron-right.svg";
import React from "react";

class DayDropdown extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }

    renderOptions() {
        let options = [];
        for (let i = 0; i <= this.props.days; i++) {
            let date = this.props.start.clone().add(i, "d");
            // Checking if days the user can select are within the accepted range.
            if (this.props.max) {
                if (date.isSameOrBefore(this.props.max, "day")) {
                    options.push(this.props.start.clone().add(i, "d"));
                }
            } else {
                options.push(this.props.start.clone().add(i, "d"));
            }
        }
        return options.map((option, index) => {
            return (
                <div
                    key={index + "day"}
                    className="option"
                    onClick={() => {
                        this.setState({ open: false })
                        if (this.props.onChange)
                            this.props.onChange(option.clone())
                    }}
                >
                    {option.format("ddd Do MMM")}
                </div>
            )
        })
    }

    render() {
        let open = this.state.open;
        return (
            <div className="dropdown dayDrop">
                <div
                    className={open ? "currentValue open" : "currentValue"}
                    onClick={() => {
                        this.setState({ open: !this.state.open })
                    }}
                >
                    {this.props.value.format("ddd Do MMM")}
                    <img
                        alt=">"
                        className="chevron"
                        src={chevron}
                    />
                </div>
                <div className={open ? "options open" : "options"}>
                    <div className="inner">
                        {this.renderOptions()}
                    </div>
                </div>
            </div>
        )
    }
}

export default DayDropdown;