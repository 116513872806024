import chevron from "../media/icon-chevron-right.svg";
import React from "react";

class DayDropdown extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }

    renderOptions() {
        let options = [];
        for (let i = 1; i <= this.props.pages; i++) {
            options.push(i);
        }
        return options.map(option => {
            return (
                <div
                    key={option + "page"}
                    className="option"
                    onClick={() => {
                        this.setState({ open: false })
                        if (this.props.onChange)
                            this.props.onChange(option - 1)
                    }}
                >
                    {option}
                </div>
            )
        })
    }

    render() {
        let open = this.state.open;
        return (
            <div className="dropdown pageDrop">
                <div
                    className={open ? "currentValue open" : "currentValue"}
                    onClick={() => {
                        this.setState({ open: !this.state.open })
                    }}
                >
                    {this.props.value + 1}
                    <img
                        alt=">"
                        className="chevron"
                        src={chevron}
                    />
                </div>
                <div className={open ? "options open" : "options"}>
                    <div className="inner">
                        {this.renderOptions()}
                    </div>
                </div>
            </div>
        )
    }
}

export default DayDropdown;