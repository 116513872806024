import React, { useState, useEffect } from 'react';

const ErrorAlert = ({ message }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(false), 4000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={`alert alert-danger ${show ? 'd-block' : 'd-none'}`} role="alert" style={{ marginTop: '1em', maxWidth: '600px' }}>
      {message}
    </div>
  );
};

export default ErrorAlert;