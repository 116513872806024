/**
 *  PROPS
 * 
 *      meal: "NUMBER" 0 - breakfast , 1 - lunch , 2 - dinner
 *      changeMealHandler: FUNCTION , changes which meal is shown
 *      dayPlan: ARRAY of 3 meal recipes for the day
 */

import $ from "jquery";
import React from 'react';
import Recipe from "./recipe";

//Media import
import arrow from "../../../media/icon-arrow.svg";
import LogoForPrint from "../../../media/RYG_Logo_Orange.svg";


class RecipesAccordion extends React.Component {

    scroll(cb) {
        let scrollTo = $("header").height() + $(".hero").height() + 250;
        $("html").animate({ scrollTop: scrollTo }, 500);
        if (cb) cb();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.meal !== this.props.meal) {
            let nextItem = $(this.refs["recipe" + this.props.meal]);
            $(".recipe-container").each(function () {
                if ($(this).is(nextItem)) {
                    $(this)
                        .slideDown()
                        .parent()
                        .addClass("expanded");
                } else {
                    $(this)
                        .slideUp()
                        .parent()
                        .removeClass("expanded");
                }
            });
            this.scroll();
        }
    }

    componentDidMount() {
        // Open the current recipe on mount.
        $(this.refs["recipe" + this.props.meal])
            .slideDown()
            .parent()
            .addClass("expanded");
    }

    render() {
        return (
            <React.Fragment>
                <div className="logoForPrint">
                    <img src={LogoForPrint} alt="ryg logo"/>
                </div>
                <div className="recipes shadow2">
                    {this.props.recipes.map((recipe, index) => {
                        return (
                            <div className="recipe" key={recipe.id}>
                                <div
                                    className="recipeType"
                                    onClick={() => {
                                        this.props.changeMealHandler(index);
                                    }}
                                >
                                    <img
                                        className="arrow"
                                        src={arrow}
                                        alt="arrow icon"
                                    />
                                    <h1 className="type">{["BREAKFAST", "LUNCH", "DINNER"][index]}</h1>
                                </div>
                                <div
                                    ref={"recipe" + index}
                                    className="recipe-container"
                                >
                                    <Recipe
                                        key={recipe.id}
                                        recipe={recipe}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }
}

export default RecipesAccordion;