import React from "react";
import Field from "./field";
import chevron from "../media/icon-chevron-right.svg";

class DropdownField extends Field {
    constructor(props) {
        super(props)
        this.state.open = false;
        this.settings = {
            type: "dropdownField"
        }
    }

    renderField() {
        let open = this.state.open
        return (
            <div className="dropdown" style={this.props.fullWidth ? {
                width: "100%"
            } : {}}>
                <div
                    className={open ? "currentValue open" : "currentValue"}
                    onClick={() => {
                        this.setState({ open: !this.state.open })
                    }}
                >
                    {this.props.children || "Select value"}
                    <img
                        className="chevron"
                        src={chevron}
                        alt=""
                    />
                </div>
                <div className={open ? "options open" : "options"}>
                    <div className="inner">
                        {this.props.options
                            .map(option => {
                                if (typeof option === "object") {
                                    return option;
                                } else {
                                    return {
                                        value: option,
                                        label: option
                                    }
                                }
                            })
                            .map(option => {
                                return (
                                    <div
                                        key={option.value}
                                        className="option"
                                        onClick={() => {
                                            this.setState({ open: false })
                                            this.change(option.value)
                                        }}
                                    >
                                        {option.label}
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
        )
    }

}

export default DropdownField;