import $ from "jquery";
import React from "react";
import Popup from "reactjs-popup";
import ItemCreator from "./itemCreator";
import TextField from "../../../fields/textField";
import CheckboxField from "../../../fields/checkboxField";
import Context from "../../../context";

class PartnerCreator extends ItemCreator {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            registrations: []
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.getRegistrations();
    }

    getRegistrations() {
        if (this.props.item && this.props.item.id) {
            $.get(`${this.context.apiRoot}/partners/${this.props.item.id}/registrations`).then(res => {
                if (res.results && res.results.length) {
                    this.setState({ registrations: res.results });
                }
            }).catch(err => {
                console.error("Error getting registrations", err);
            })
        }
    }

    canSubmit() {
        if (this.props.item) {
            //If updating an existing partner
            if (Object.keys(this.state.changes).length) return true;
            return false;
        } else {
            if (this.state.item.name) {
                return true;
            }
        }
        //Return false by default.
        return false;
    }

    setRegistrationPaidState(row, rowIndex, paid) {
        this.setState(state => {
            const registrations = state.registrations.map((item, j) => {
                if (j === rowIndex) {
                    return {
                        month: row.month,
                        count: row.count,
                        amount: row.amount,
                        paid
                    };
                } else {
                    return item;
                }
            });

            return {
                registrations,
            };
        });
    }

    renderRegistrations() {
        return (
            <div className="partnerRegistration">
                <div className="container">
                    <div className="inner">
                        <h2>Partner Registrations</h2>
                    </div>
                    {/* Iterate registrations */}
                    {this.state.registrations.map((row, rowIndex) => (
                        <div className="registration shadow2" key={rowIndex}>
                            <div className="monthName row col-12">
                                <div className="col-md-10">
                                    {new Intl.DateTimeFormat("en-GB", {
                                        year: "numeric",
                                        month: "long"
                                    }).format(new Date(row.month))}
                                </div>
                                <div className="col-md-2">
                                    <CheckboxField
                                        label="Paid"
                                        change={(err, val) => {
                                            if (window.confirm(`Are you sure you want to mark this month as ${val ? "paid" : "unpaid"}?`)) {
                                                let paid = val ? 1 : 0;
                                                this.setRegistrationPaidState(row, rowIndex, paid);
                                                $.ajax({
                                                    type: "PUT",
                                                    url: `${this.context.apiRoot}/partners/${this.props.item.id}/paymentStatus`,
                                                    contentType: "application/json",
                                                    data: JSON.stringify({
                                                        paymentPeriod: row.month,
                                                        paid
                                                    })
                                                }).catch(err => {
                                                    console.error(err)
                                                })
                                            }
                                        }}
                                    >
                                        {row.paid}
                                    </CheckboxField>
                                </div>
                            </div>
                            <div className="registrationRow row col-12">
                                <div className="col-md-6">
                                    <TextField
                                        readonly
                                        label="Number of Registrations"
                                    >
                                        {row.count}
                                    </TextField>
                                </div>
                                <div className="col-md-6">
                                    <TextField
                                        readonly
                                        label="Total Value"
                                    >
                                        {new Intl.NumberFormat("en-GB", {
                                            style: "currency",
                                            currency: "GBP"
                                        }).format((row.amount / 100).toFixed(2))}
                                    </TextField>
                                </div>
                                <div className="name col-md-2">

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    renderCreator() {
        return (
            <div className="creatorFields">
                <TextField
                    label="Name"
                    change={(err, val) => {
                        this.itemChanged("name", val);
                    }}
                >
                    {this.state.item.name}
                </TextField>
                <TextField
                    label="Discount Percentage"
                    change={(err, val) => {
                        this.itemChanged("discount", val);
                    }}
                >
                    {this.state.item.discount}
                </TextField>
                <TextField
                    label="Discount Code"
                    change={(err, val) => {
                        this.itemChanged("token", val);
                    }}
                >
                    {this.state.item.token}
                </TextField>
                <div className="partnerUrlButton">
                    <Popup trigger={<button className="button"> Discount Link</button>} position="bottom center" modal>
                        <div className="admin-popup-content">
                            Pass this link to your partner:
                            <hr />
                            <span className="textLink">{process.env.REACT_APP_PARTNER_BASE_URL + this.state.item.token}</span>
                        </div>
                    </Popup>
                </div>
                {this.state.item && this.state.item.id ?
                    <div className="partnerRegistrations">
                        {this.renderRegistrations()}
                    </div>
                    : null}
            </div>
        )
    }
}

PartnerCreator.contextType = Context;

export default PartnerCreator;