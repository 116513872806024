import React from 'react';
import UsefulInfoTemplate from "./index";
import Content from "../../assets/content";

//pdf import
import pdf from "../../media/pdf/ryh-clean15-dirtydozen.pdf";
import download from "../../media/icon-download-white.svg";

class DirtyDozen extends UsefulInfoTemplate {

    content() {
        return (
            <div className="dirtyDozen">
                <Content>useful-info-dirtydozen-heading-text</Content>
                <div className="contentBlock shadow2">
                    <Content>useful-info-dirtydozen-content</Content>
                </div>
                <div className="downloadBlock">
                    <div className="text">
                        <h5>Download this guide to help you along the way...</h5>
                        <p>You can even print it off to stick on the fridge!</p>
                    </div>
                    <a href={pdf} download className="button">
                        DOWNLOAD
                        <img src={download} alt="download icon" />
                    </a>
                </div>
            </div>
        )
    }

}

export default DirtyDozen;