/**
 *  PROPS
 * 
 *      day: NUMBER from 1-28
 *      week: NUMBER form 1-4
 *      date: MOMENT JS Object    
 *      changeDayHandler: FUNCTION  
 */

import React from 'react';

//Media import (project specific)
import chevronLeft from "../../../media/icon-chevron-left.svg";
import chevronRight from "../../../media/icon-chevron-right.svg";

class DayChanger extends React.Component {

    render() {
        let date = this.props.date.clone();
        let displayDate = this.props.date.clone().add(-1, "d");
        let day = date.diff(this.props.min, "d");
        // console.log(`DAY: ${day}`, "C: ", date.format("DD/MM"), "min", this.props.min.format("DD/MM"), "max", this.props.max.format("DD/MM"))
        return (
            <div className="dayChanger shadow2">
                <div
                    className="chevron left"
                >
                    {date.isAfter(this.props.min.clone().add(1, "d"))
                        ?
                        <img
                            onClick={() => {
                                this.props.changeDayHandler(-1);
                            }}
                            src={chevronLeft}
                            alt="chevron left"
                        />
                        :
                        null
                    }
                </div>
                <div className="inner">
                    <div className="dayNumber">
                        <span>Day</span>
                        <span>{day}</span>
                    </div>
                    <div className="dayName">
                        {displayDate.format("dddd")}
                    </div>
                    <div className="date">
                        <span>{displayDate.format("D")}</span>
                        <span>{displayDate.format("MMM")}</span>
                    </div>
                </div>
                <div
                    className="chevron right"
                >
                    {date.isSameOrBefore(this.props.max.clone().add(-1, "d"))
                        ?
                        <img
                            onClick={() => {
                                this.props.changeDayHandler(1);
                            }}
                            src={chevronRight}
                            alt="chevron right"
                        />
                        :
                        null
                    }
                </div>
            </div>
        )
    }
}

export default DayChanger;