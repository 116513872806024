import $ from "jquery";
import React from 'react';
import { Switch, Route, Redirect, NavLink, Link } from "react-router-dom";
import moment from "moment";
import MealPlanCarousel from "./mealPlanCarousel";
import RecipesAccordion from "./recipesAccordion";
import DayChanger from "./dayChanger"
import ShoppingList from "./shoppingList";
import BeforeYouStart from "./beforeYouStart";
import AndBeyond from "./andBeyond";
import EssentialRecipes from "./essentialRecipes";
import PaymentField from "../../../fields/paymentField";
import Content from "../../../assets/content";
import Questionnaire from "./questionnaire";
import DivWithBackground from "../../../assets/divWithBackground";

//Media import
import week1 from "../../../media/week-1.svg";
import week2 from "../../../media/week-2.svg";
import week3 from "../../../media/week-3.svg";
import week4 from "../../../media/week-4.svg";
import list from "../../../media/icon-shoppinglist.svg";
import whatsIn from "../../../media/icon-whats-in.svg";
import whatsOut from "../../../media/icon-whats-out.svg";
import whiteArrow from "../../../media/icon-arrow-white.svg";

// Context. 
import Context from "../../../context";

class MealPlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            whatsIn: null,
            whatsOut: null,
            dateSelected: null,
            start: null,
            shoppingListStart: moment(),
            shoppingListEnd: moment().add(2, "d"),
            plan: [],
            recipes: [],
            changes: null,
            userConditions: [],
            charging: false,
            paymentError: null,
            upgradeSuccess: false,
            submitType: null,
            pastQuestionnaires: null
        }
        this.gotPlan = false;
        this.gotConditions = false;
        this.gotQuestionnaires = false;
        this.weekNumber = [week1, week2, week3, week4];
    }

    componentDidMount() {
        if (this.context.session.user && this.context.session.ready) {
            if (!this.gotPlan) {
                this.gotPlan = true;
                this.getPlan();
            }
            if (!this.gotConditions) {
                this.gotConditions = true;
                this.getUserConditions();
            }
            if (!this.gotQuestionnaires) {
                this.gotQuestionnaires = true;
                this.getPastQuestionnaires();
            }
        }
    }

    componentDidUpdate() {
        if (this.context.session.user && this.context.session.ready) {
            if (!this.gotPlan) {
                this.gotPlan = true;
                this.getPlan();
            }
            if (!this.gotConditions) {
                this.gotConditions = true;
                this.getUserConditions();
            }
            if (!this.gotQuestionnaires) {
                this.gotQuestionnaires = true;
                this.getPastQuestionnaires();
            }
        }
    }

    getPastQuestionnaires(cb) {
        $.ajax({
            url: `${this.context.apiRoot}/questionnaires/me`,
        }).then(response => {
            this.setState({
                pastQuestionnaires: response.results,
                submitType: (qs => {
                    if (!qs.length) {
                        // If no submissions, do the before one.
                        return "before";
                    } else if (qs.length === 1) {
                        // If there's one, we can do the after one.
                        return "after";
                    } else {
                        // False to say submitting is not allowed.
                        return false;
                    }
                })(response.results)
            }, () => {
                if (cb) cb();
            });
        }).catch(err => {
            console.error("Couldn't check questionnaires: ", err);
        });
    }

    getUserConditions() {
        $.get(`${this.context.apiRoot}/conditions/me`).done(response => {
            this.setState({ userConditions: response.conditions });
            // console.log("response:", response);
        }).catch(err => {
            console.error("Error getting conditions: ", err);
        })
    }

    getRecipes(ids) {
        $.post({
            url: `${this.context.apiRoot}/recipes/retrieve`,
            data: JSON.stringify(ids),
            contentType: "application/json"
        }).done(response => {
            //Returned recipes
            let recipes = response.recipes;
            //Array which will hold any ingredient recipes
            let extraRecipes = [];
            // Construct array of recipe ids we already have in state,
            // and have just received.
            let currentRecipes = this.state.recipes
                .map(r => r.id)
                .concat(recipes.map(r => r.id));
            // iterate over recipes.
            recipes.forEach(rec => {
                // iterating over ingredients of an recipe
                rec.ingredients.forEach(ing => {
                    // find any recipe ingredients with a recipe, and add them to an array if not there already
                    if (
                        ing.ingredient_recipe &&
                        !extraRecipes.includes(ing.ingredient_recipe) &&
                        !currentRecipes.includes(ing.ingredient_recipe)
                    ) {
                        extraRecipes.push(ing.ingredient_recipe)
                    }
                })
            })
            // Setting the state with all new recipes.
            this.setState({
                recipes: [...this.state.recipes, ...recipes]
            });
            // Get the missing recipes if there are any.
            if (extraRecipes.length) {
                this.getRecipes(extraRecipes);
            }
        }).catch(err => {
            console.error("Error getting recipes: ", err);
        });
    }

    /**
     * @param {String} id ID of the recipe to get from
     * the recipes array.
     */
    getRecipeWithId(id) {
        // console.log(this.state.recipes)
        return this.state.recipes
            .filter(r => r.id === id)[0] || null;
    }

    getPlan() {
        // set state this.plan
        $.get(`${this.context.apiRoot}/meal-plans`).done(response => {
            //Checking plan start dates
            // console.log(response)
            let start = moment(response.mealPlan.started);
            let shoppingListStart = start.isBefore(moment()) ? moment() : start;
            let shoppingListEnd = shoppingListStart.clone().add(2, "d");
            this.setState({
                mealsEnabled: response.mealPlan.mealsEnabled,
                paid: response.mealPlan.paid,
                plan: response.mealPlan.plan,
                changes: response.mealPlan.changes,
                start,
                shoppingListStart,
                shoppingListEnd
            })
            let recipesToGet = [].concat.apply([], response.mealPlan.plan);
            this.getRecipes(recipesToGet);
        }).catch(err => {
            console.error("Error getting meal plan: ", err);
        });
    }

    renderWeekTabs() {
        return [1, 2, 3, 4].map(tab => {
            return (
                <NavLink
                    activeClassName="selected"
                    to={`/account/meal-plan/week/${tab}`}
                    key={"week_" + tab}
                    className={this.state.tab === tab ? "week selected" : "week"}
                    onClick={e => {
                        this.setState({
                            whatsIn: null,
                            whatsOut: null
                        })
                    }}
                >
                    <span className="number">{tab}</span>
                </NavLink>
            )
        });
    }

    getEmbeddedRecipes(recipe) {
        // Return an empty array to handle null recipes.
        if (!recipe) return [];
        let result = [];
        recipe.ingredients
            // Filter for ingredients that have a recipe.
            .filter(ingredient => ingredient.ingredient_recipe)
            // Map and get the recipe for each.
            .forEach(ingredient => {
                if (!result.filter(r => r && r.id === ingredient.recipe).length) {
                    // Get the full recipe object.
                    let fullRecipe = this.getRecipeWithId(ingredient.ingredient_recipe);
                    // If it was ready,
                    if (fullRecipe) {
                        // Add it on to the result.
                        result.push(fullRecipe);
                        // And attempt to find any recipes embedded in it.
                        result = result.concat(this.getEmbeddedRecipes(fullRecipe));
                    }
                }
            });
        // Finally return the result.
        return result;
    }

    getRecipesForDayRange(start, end) {
        // Adding a moment date object to each day on the meal plan
        let plan = this.state.plan.map((day, index) => {
            return {
                date: this.state.start.clone().add(index, "d"),
                plan: day
            }
        }).filter(item => {
            return (item.date.isSameOrAfter(start, "day") && item.date.isSameOrBefore(end, "day"));
        }).map(item => {
            return item.plan
                // First filter out excluded meals.
                .filter((recipeId, index) => this.state.mealsEnabled[index])
                // Then get the recipe object for each.
                .map(recipeId => {
                    return this.getRecipeWithId(recipeId)
                })
        });
        // Flatten and return.
        let recipes = [].concat.apply([], plan);

        // Get all the embedded recipes.
        let embeddedRecipes = [];
        recipes.forEach(recipe => {
            embeddedRecipes = embeddedRecipes.concat(this.getEmbeddedRecipes(recipe));
        });
        // Attempt to merge them with recipes.
        embeddedRecipes.forEach(recipe => {
            if (!recipes.filter(r => r.id === recipe.id).length) {
                recipes.push(recipe);
            }
        });
        // Return the recipes.
        return recipes;
    }

    areTagsExcluded(tagIds) {
        //Return false if no tagIds passed
        if (!tagIds.length) return false;
        let excludedTags = tagIds.filter(id => {
            for (let i = 0; i < this.state.userConditions.length; i++) {
                for (let j = 0; j < this.state.userConditions[i].tags.length; j++) {
                    if (
                        this.state.userConditions[i].tags[j].tag_id === id &&
                        !this.state.userConditions[i].tags[j].is_positive
                    ) return true;
                }
            }
            return false;
        });
        return excludedTags.length === tagIds.length;
    }

    upgrade(data) {
        $.post({
            url: `${this.context.apiRoot}/accounts/upgrade`,
            data: JSON.stringify(data),
            contentType: "application/json"
        }).then(response => {
            if (response.client_secret) {
                this.context.stripe.handleCardAction(response.client_secret).then(result => {
                    if (!result.error) {
                        this.upgrade({ intent_id: result.paymentIntent.id });
                    } else {
                        this.setState({
                            working: false,
                            loginError: result.error.message
                        });
                    }
                });
            } else {
                // Successful upgrade, so re-get plan.
                this.setState({
                    upgradeSuccess: true,
                    charging: false
                });
                this.getPlan();
            }
        }).catch(err => {
            console.error("error paying: ", err);
        });
    }

    renderWeekView(weekNumber, router) {
        let weekData = [
            {
                whatsOut: [
                    {
                        icon: "icon-circle-gluten",
                        text: "No Gluten",
                        tagId: [6]
                    },
                    {
                        icon: "icon-circle-dairy",
                        text: "No Dairy",
                        tagId: [3]
                    },
                    {
                        icon: "icon-circle-processed",
                        text: "No processed food or artificial sweeteners",
                        tagId: []
                    },
                    {
                        icon: "icon-circle-sugarcarbs",
                        text: "No sugar or white carbs",
                        tagId: [20]
                    },
                    {
                        icon: "icon-circle-eggs",
                        text: "No eggs",
                        tagId: [4]
                    },
                    {
                        icon: "icon-circle-alcohol",
                        text: "No alcohol or fizzy drinks",
                        tagId: []
                    },
                    {
                        icon: "icon-circle-grains",
                        text: "Avoid all grains and pulses",
                        tagId: [7, 12]
                    }
                ]
            },
            {
                whatsOut: [
                    {
                        icon: "icon-circle-gluten",
                        text: "No Gluten",
                        tagId: [6]
                    },
                    {
                        icon: "icon-circle-dairy",
                        text: "No Dairy",
                        tagId: [3]
                    },
                    {
                        icon: "icon-circle-processed",
                        text: "No processed food or artificial sweeteners",
                        tagId: []
                    },
                    {
                        icon: "icon-circle-sugarcarbs",
                        text: "No sugar or white carbs",
                        tagId: [20]
                    },
                    {
                        icon: "icon-circle-alcohol",
                        text: "No alcohol or fizzy drinks",
                        tagId: []
                    },
                    {
                        icon: "icon-circle-grains",
                        text: "Avoid all grains",
                        tagId: [7]
                    }
                ],
                whatsIn: [
                    {
                        icon: "icon-circle-grains",
                        text: "Pulses",
                        tagId: [12]
                    },
                    {
                        icon: "icon-circle-eggs",
                        text: "Eggs",
                        tagId: [4]
                    }
                ]
            },
            {
                whatsOut: [
                    {
                        icon: "icon-circle-gluten",
                        text: "No Gluten",
                        tagId: [6]
                    },
                    {
                        icon: "icon-circle-processed",
                        text: "No processed food or artificial sweeteners",
                        tagId: []
                    },
                    {
                        icon: "icon-circle-sugarcarbs",
                        text: "No sugar or white carbs",
                        tagId: [20]
                    },
                    {
                        icon: "icon-circle-alcohol",
                        text: "No alcohol or fizzy drinks",
                        tagId: []
                    }
                ],
                whatsIn: [
                    {
                        icon: "icon-circle-dairy",
                        text: "Dairy",
                        tagId: [3]
                    },
                    {
                        icon: "icon-circle-grains",
                        text: "Grains",
                        tagId: [7]
                    }
                ]
            },
            {
                whatsOut: [
                    {
                        icon: "icon-circle-gluten",
                        text: "No Gluten",
                        tagId: [6]
                    },
                    {
                        icon: "icon-circle-processed",
                        text: "No processed food or artificial sweeteners",
                        tagId: []
                    },
                    {
                        icon: "icon-circle-sugarcarbs",
                        text: "No sugar or white carbs",
                        tagId: [20]
                    },
                    {
                        icon: "icon-circle-alcohol",
                        text: "No alcohol or fizzy drinks",
                        tagId: []
                    }
                ]
            }
        ];
        let week = weekNumber - 1;
        return <div className="week-view">
            <div className="heading">
                <div className="left">
                    <h1>Week</h1>
                    <img src={this.weekNumber[week]} alt="week number" />
                    <div className="mobileWeekChange">
                        <span className="weekText">Week</span>
                        {this.renderWeekTabs()}
                    </div>
                </div>
                <div className="right">
                    <div className="rubric">
                        <Content>{`meal-plan-page-week-${weekNumber}-rubric`}</Content>
                    </div>
                    <div className="sub-rubric">
                        <Content>meal-plan-page-sub-rubric</Content>
                    </div>
                    {weekData[week].whatsIn ? <div
                        className={this.state.whatsIn !== null ? "button in active" : "button in"}
                        onClick={() => {
                            let whatsIn = this.state.whatsIn !== null ? null : week
                            this.setState({
                                whatsOut: null,
                                whatsIn
                            })
                        }}
                    >
                        WHAT'S IN
                        <img src={whatsIn} alt="in arrow" />
                    </div> : null}
                    {weekData[week].whatsOut ? <div
                        className={this.state.whatsOut !== null ? "button out active" : "button out"}
                        onClick={() => {
                            let whatsOut = this.state.whatsOut !== null ? null : week
                            this.setState({
                                whatsOut,
                                whatsIn: null
                            })
                        }}
                    >
                        WHAT'S OUT
                        <img src={whatsOut} alt="out arrow" />
                    </div> : null}
                    {/* Whats out popup */}
                    {this.state.whatsOut !== null
                        ?
                        <div className="popup">
                            <div className="inner shadow1">
                                {this.renderWhatsOut(weekData[this.state.whatsOut].whatsOut)}
                            </div>
                        </div>
                        :
                        null
                    }
                    {this.state.whatsIn !== null
                        ?
                        <div className="popup">
                            <div className="inner shadow1">
                                {this.renderWhatsIn(weekData[this.state.whatsIn].whatsIn)}
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            {/* Actual day meals carousel */}
            <div style={{
                opacity: this.state.whatsOut !== null || this.state.whatsIn !== null ? "0.4" : "1"
            }}>
                <MealPlanCarousel
                    key={weekNumber + "week"}
                    week={weekNumber}
                    type="mealPlan"
                    mealsEnabled={this.state.mealsEnabled}
                    paid={this.state.paid}
                    // Slice out the items for this week.
                    items={this.state.plan.slice(
                        week * 7,
                        (week * 7) + 7
                    ).map((day, index) => {
                        //Cloning moment object
                        let date = this.state.start.clone();
                        //Getting the number of the day in the meal plan
                        let dayNumber = (week * 7) + (index + 1);
                        // Map over each day, which has 3 recipe ids.
                        let meals = day
                            // Get the full recipe for each.
                            .map(recipeId => this.getRecipeWithId(recipeId))
                            // Simplify recipe object.
                            .map(recipe => {
                                return recipe ? {
                                    name: recipe.name,
                                    images: recipe.images
                                } : null;
                            });
                        return {
                            day: dayNumber,
                            date: date.add(dayNumber - 1, "d"),
                            meals
                        }
                    })}
                />
                <div className="under-carousel">
                    Remember! Lunch and dinner are <b>interchangeable</b>. Pick the meal first that best suits your lifestyle or your day!
                </div>
            </div>
        </div>
    }

    renderWhatsOut(data) {
        if (!data) return null;
        return (
            <React.Fragment>
                <div className="popupTitle" key="out">Give it a rest</div>
                <div className="popupText">{`Here's what we are cutting out in week ${this.state.whatsOut + 1}`}</div>
                <div className="out">
                    {data.map((item, index) => {
                        if (!this.areTagsExcluded(item.tagId)) {
                            return (
                                <div
                                    className="item"
                                    key={index + "out"}
                                >
                                    <img alt="whats out" src={`/static/media/whats_out_icons/${item.icon}.svg`} />
                                    <div className="text">{item.text}</div>
                                </div>
                            )
                        } else {
                            return null
                        }
                    })}
                </div>
            </React.Fragment>
        )
    }

    renderWhatsIn(data) {
        if (!data) return null;
        return (
            <React.Fragment>
                <div className="popupTitle" key="in">Whats new</div>
                <div className="popupText">{`Here's what we are adding in week ${this.state.whatsIn + 1}`}</div>
                <div className="in">
                    {data.map((item, index) => {
                        if (!this.areTagsExcluded(item.tagId)) {
                            return (
                                <div
                                    className="item"
                                    key={index + "in"}
                                >
                                    <img alt="whats out" src={`/static/media/whats_out_icons/${item.icon}.svg`} />
                                    <div className="text">{item.text}</div>
                                </div>
                            )
                        } else {
                            return null
                        }
                    })}
                </div>
            </React.Fragment>
        )
    }

    renderDayView(router) {
        let
            week = Number(router.match.params.week),
            day = Number(router.match.params.day),
            meal = Number(router.match.params.meal);
        let dayNumber = (7 * (week - 1)) + day;
        let isLocked = this.state.plan.length && !this.state.paid && dayNumber > 3;
        // console.log("WEEK: ", week, "DAY: ", day);
        // Render.
        return this.state.start ? <div className="day-view">
            {/* Bundled UI for changing day */}
            <DayChanger
                min={this.state.start.clone()}
                max={this.state.start.clone().add(28, "d")}
                date={this.state.start.clone().add(dayNumber, "d")}
                changeDayHandler={change => {
                    let newWeek = week;
                    let newDay = (day + change);
                    if (newDay > 7) {
                        newWeek += 1;
                        newDay = 1;
                    } else if (newDay < 1) {
                        newWeek -= 1;
                        newDay = 7;
                    }
                    // let newWeek = Math.ceil(day / 7);
                    router.history.push(`/account/meal-plan/week/${newWeek}/day/${newDay}/0`);
                }}
            />
            {/* Button to return to carousel view */}
            <Link
                to={`/account/meal-plan/week/${week}`}
                className="button goBack"
            >
                Go back
                <img src={whiteArrow} alt="arrow" />
            </Link>
            {!isLocked && this.state.recipes.length && this.state.plan.length
                ?
                <RecipesAccordion
                    key={week + day}
                    recipes={this.state.plan[dayNumber - 1].map(recipeId =>
                        this.getRecipeWithId(recipeId)
                    )}
                    meal={meal}
                    changeMealHandler={newMeal => {
                        // console.log(newMeal)
                        // console.log("WEEK: ", week, "DAY: ", day);
                        router.history.push(`/account/meal-plan/week/${week}/day/${day}/${newMeal}`)
                    }}
                />
                : null}
            {isLocked
                ?
                <div className="lockedDay shadow2">
                    <h3>Sorry! Meals past day 3 are not included in the free trial.</h3>
                    <h5>
                        If you're enjoying RYH, please consider purchasing the full plan below!
                    </h5>
                    <div className="padlock">
                        <img src="/static/media/padlock.svg" alt="locked" />
                    </div>
                    <button className="button" onClick={e => {
                        $('html, body').animate({
                            scrollTop: $(".payment-container").offset().top
                        }, 300)
                    }}>FULL PLAN</button>
                </div>
                : null
            }
        </div> : null
    }

    renderShoppingList() {
        // Get all recipes in the date range.
        let recipes = this.getRecipesForDayRange(
            this.state.shoppingListStart,
            this.state.shoppingListEnd
        ).filter(r => r);
        // console.log(recipes.map(r => r.name).sort())
        // console.log("Essential recipes: ", recipes.filter(r => r.basic).map(r => r.name));
        return <ShoppingList
            recipes={recipes}
            planStart={this.state.start.clone()}
            planEnd={this.state.start.clone().add(this.state.paid ? 27 : 2, "d")}
            start={this.state.shoppingListStart}
            end={this.state.shoppingListEnd}
            onDateRangeChange={range => {
                this.setState({
                    shoppingListStart: range.start,
                    shoppingListEnd: range.end
                })
            }}
        />
    }

    renderPaymentContainer() {
        return <div className="payment-container">
            <div className="triangle"></div>
            {this.state.upgradeSuccess
                ?
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10">
                        <h2>Thank you for supporting RYH, and committing to positively changing your health!</h2>
                        <br /><br />
                        <div className="moreInfo">
                            Any questions, please don't hesitate to <a target="_blank" href="/contact">get in touch</a> or email us at <a href="mailto:info@ryghealth.com">info@ryghealth.com</a> .
                        </div>
                    </div>
                </div>
                :
                <div className="container">
                    <div className="price-badge">
                        <div className="badge-label">
                            <div className="price">
                                £{process.env.REACT_APP_MEAL_PLAN_PRICE}
                            </div>
                            one off fee
                    </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-10">
                            <h2>We hope you are enjoying your RYH experience!</h2>
                            <br /><br />
                            <div className="moreInfo">
                                To unlock the rest of your plan, we ask for a one off fee of
                            <b className="price"> £{process.env.REACT_APP_MEAL_PLAN_PRICE}</b>
                                <br /><br />
                                You’ve already agreed to our terms of use, but here is a <a target="_blank" href="/static/media/terms_of_use.63f9d33a.pdf">refresher</a>.
                        </div>
                        </div>
                        <div className="col-12 col-sm-10">
                            <br /><br />
                            <PaymentField
                                price={process.env.REACT_APP_MEAL_PLAN_PRICE}
                                onMethod={(err, method_id) => {
                                    if (!err) {
                                        this.setState({
                                            charging: true,
                                            paymentError: null
                                        });
                                        this.upgrade({ method_id });
                                    } else {
                                        console.error("Error with payment method: ", err);
                                    }
                                }}
                            />
                            {this.state.paymentError ?
                                <div className="error">
                                    {this.state.paymentError.message || String(this.state.paymentError)}
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>}
            <div className={"overlay" + (this.state.charging ? "" : " hidden")}>
                <div>Working...</div>
            </div>
        </div>
    }

    render() {
        return (
            <div className="calendar">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tab-selector">
                                <NavLink
                                    to="/account/meal-plan/before-you-start"
                                    activeClassName="selected"
                                    className="beforeStart tab"
                                >
                                    <span>Before</span>
                                    <span>you start</span>
                                </NavLink>
                                <NavLink
                                    to="/account/meal-plan/essential-recipes"
                                    activeClassName="selected"
                                    className="recipesToPrep tab"
                                >
                                    <span>Prep</span>
                                    <span>recipes</span>
                                </NavLink>
                                <div
                                    className={this.props.router.location.pathname.includes("/week") ? "desktopWeekTab tab selected" : "desktopWeekTab tab"}
                                >
                                    <span>Week</span>
                                    {this.renderWeekTabs()}
                                </div>
                                <NavLink
                                    to="/account/meal-plan/week"
                                    activeClassName="selected"
                                    className="mobileWeekTab tab"
                                >
                                    <span>Week</span>
                                    <span>by Week</span>
                                </NavLink>
                                <NavLink
                                    to="/account/meal-plan/and-beyond"
                                    activeClassName="selected"
                                    className="andBeyond tab"
                                >
                                    <span>and</span>
                                    <span>beyond</span>
                                </NavLink>
                                <NavLink
                                    to="/account/meal-plan/shopping-list"
                                    activeClassName="selected"
                                    className="shoppingListTab tab"
                                >
                                    <span>Shopping list</span>
                                    <img src={list} alt="shopping list icon" />
                                </NavLink>
                            </div>
                            <div className="tab-view">
                                <Switch>
                                    <Route
                                        path="/account/meal-plan/week/:week/day/:day/:meal?"
                                        render={router => (
                                            this.renderDayView(router)
                                        )}
                                    />
                                    <Route
                                        path="/account/meal-plan/week/:week"
                                        render={router => (
                                            this.renderWeekView(router.match.params.week, router)
                                        )}
                                    />
                                    <Route
                                        path="/account/meal-plan/questionnaire"
                                        render={router => {
                                            return (
                                                <Questionnaire
                                                    questionnaires={this.state.pastQuestionnaires}
                                                    submitType={this.state.submitType}
                                                    router={router}
                                                    triggerRetrieveQuestionnaire={(cb) => {
                                                        this.getPastQuestionnaires(cb);
                                                    }}
                                                />
                                            )
                                        }}
                                    />
                                    <Route
                                        path="/account/meal-plan/shopping-list"
                                        render={router => (
                                            this.state.plan && this.state.start ? this.renderShoppingList() : null
                                        )}
                                    />
                                    <Route
                                        path="/account/meal-plan/essential-recipes"
                                        render={router => (
                                            <EssentialRecipes />
                                        )}
                                    />
                                    <Route
                                        path="/account/meal-plan/and-beyond"
                                        render={router => (
                                            <AndBeyond />
                                        )}
                                    />
                                    <Route
                                        path="/account/meal-plan/week"
                                        render={router => (
                                            <Redirect to="/account/meal-plan/week/1" />
                                        )}
                                    />
                                    <Route
                                        path="/account/meal-plan/before-you-start"
                                        render={router => (
                                            <div className="tab-view bys">
                                                <BeforeYouStart
                                                    changes={this.state.changes}
                                                    start={this.state.start}
                                                    questionnaireSubmitType={this.state.submitType}
                                                    startDateChanges={(val, cb) => {
                                                        $.post({
                                                            url: `${this.context.apiRoot}/meal-plans/me/push`,
                                                            data: JSON.stringify({ date: val }),
                                                            contentType: "application/json"
                                                        }).done(res => {
                                                            this.setState({
                                                                changes: res.changes,
                                                                start: moment(res.started)
                                                            })
                                                            if (cb) cb();
                                                        }).catch(err => {
                                                            console.error("Error changing the start date", err);
                                                        })
                                                    }}
                                                />
                                            </div>
                                        )}
                                    />
                                    <Route
                                        path="/account/meal-plan"
                                        render={router => (
                                            <Redirect to="/account/meal-plan/before-you-start" />
                                        )}
                                    />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
                <Route
                    path="/account/meal-plan/before-you-start"
                    render={router => {
                        return <div className="tab-view bys">
                            <div className="beforeView">
                                <DivWithBackground
                                    className="otherInfo"
                                    content="before-page-other-info-img"
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <Content>before-page-other-information-heading-text</Content>
                                                <div className="otherLinks">
                                                    {[
                                                        {
                                                            text: "Dos and don'ts",
                                                            url: "/useful-information/do-and-donts"
                                                        },
                                                        {
                                                            text: "The clean 15 and the dirty dozen",
                                                            url: "/useful-information/clean-15-and-the-dirty-dozen"
                                                        },
                                                        {
                                                            text: "Overview",
                                                            url: "/useful-information/overview"
                                                        },
                                                        {
                                                            text: "Feeding your gut bacteria",
                                                            url: "/useful-information/feeding-your-gut-bacteria"
                                                        },
                                                        {
                                                            text: "Guidelines for rebalancing your gut bacteria",
                                                            url: "/useful-information/rebalancing-your-gut-bacteria"
                                                        }
                                                    ].map(link => {
                                                        return (
                                                            <NavLink
                                                                key={link.text}
                                                                exact
                                                                to={link.url}
                                                                className="otherLink"
                                                            >
                                                                <div className="imgWrap">
                                                                    <img src={whiteArrow} alt="arrow icon" />
                                                                </div>
                                                                <span>{link.text}</span>
                                                            </NavLink>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DivWithBackground>
                            </div>
                        </div>
                    }}
                />
                {this.state.plan.length && (!this.state.paid || this.state.upgradeSuccess) ? this.renderPaymentContainer() : null}
            </div>
        )
    }
}

MealPlan.contextType = Context;

export default MealPlan;