/**
 *  Tag item structure
 * 
 *      {
            description: "string"
            id: 1
            name: "string"
 *      }       
 */
import React from "react";
import ItemCreator from "./itemCreator";
import TextField from "../../../fields/textField";
import VideoField from "../../../fields/videoField";
import ImageField from "../../../fields/imageField";
import DropdownField from "../../../fields/dropdownField";
import RichTextField from "../../../fields/richTextField";
import BlogContentField from "../../../fields/blogContentField";
import HtmlField from "../../../fields/htmlField";

class ContentCreator extends ItemCreator {

    canSubmit() {
        if (this.props.item) {
            //If updating an existing ingredient
            let canSubmit = false;
            Object.keys(this.state.changes).forEach(key => {
                if (key && this.state.changes[key]) {
                    canSubmit = true;
                }
            });
            if (canSubmit) return true;
        } else {
            if (this.state.item.name && this.state.item.type) {
                return true;
            }
        }
        // Return false by default.
        return false;
    }

    makeBlogContentSafe(raw) {
        return JSON.stringify(raw.map(block => {
            block.body = block.body.replace(/"/g, "&quot;");
            return block;
        }));
    }

    renderValueEditor(type, value) {
        let Editor;
        switch (type) {
            case "video":
                Editor = VideoField;
                break;
            case "image":
                Editor = ImageField;
                break;
            case "richText":
                Editor = RichTextField;
                break;
            case "html":
                Editor = HtmlField;
                break;
            case "contentBlocks":
                Editor = BlogContentField;
                // Attempt json parse of data.
                try {
                    value = JSON.parse(value || "[]");
                } catch (error) {
                    value = [];
                }
                break;
            default:
                Editor = TextField;
                break;
        }
        return <Editor
            label="Value"
            change={(err, val) => {
                this.itemChanged("value", type === "contentBlocks" ? this.makeBlogContentSafe(val) : val);
            }}
        >{value || ""}</Editor>
    }

    renderCreator() {
        return (
            <div className="creatorFields">
                <TextField
                    label="Name"
                    change={(err, val) => {
                        val = val
                            .replace(/\s/g, "-")
                            .replace(/-{2,}/g, "-")
                            .replace(/[^a-z0-9-_]/g, "")
                            .substring(0, 45);
                        this.itemChanged("name", val);
                    }}
                >
                    {this.state.item.name}
                </TextField>
                <DropdownField
                    label="Type"
                    options={["richText", "text", "image", "video", "html", "contentBlocks"]}
                    change={(err, val) => {
                        if (
                            this.state.item &&
                            this.state.item.type &&
                            this.state.item.type !== "contentBlocks" &&
                            val === "contentBlocks"
                        ) {
                            this.itemChanged("value", JSON.stringify(
                                [
                                    {
                                        type: this.state.item.type,
                                        body: this.state.item.value || "",
                                        width: 12
                                    }
                                ]
                            ), () => {
                                this.itemChanged("type", val);
                            });
                        } else {
                            this.itemChanged("type", val);
                        }
                    }}
                >
                    {this.state.item.type}
                </DropdownField>
                {this.state.item.type ?
                    this.renderValueEditor(this.state.item.type, this.state.item.value || "")
                    : null
                }
            </div>
        )
    }
}

export default ContentCreator;