import $ from "jquery";
import React from 'react';
import { Helmet } from 'react-helmet';
import Page from "./page";
import FlipMove from "react-flip-move";
import moment from "moment";
import Content from "../assets/content";
import { Redirect } from "react-router-dom";

// Assets
import DayDropdown from "../assets/dayDropdown";
import SimpleForm from "../assets/simpleForm";
import HeroImage from "../assets/hero";
import PaymentField from "../fields/paymentField";
import CheckboxField from "../fields/checkboxField";
import DropdownField from "../fields/dropdownField";

// Media import
// import heroBg from "../media/hero-register.jpg";
import startsHere from "../media/starts-here.svg";
import close from "../media/close-white.svg";
import tick from "../media/tick-white.svg";
import camera from "../media/camera.svg"

class RegisterPage extends Page {

	constructor(props) {
		super(props);
		this.stageNumber = 1;
		this.state = {
			conditions: [],
			selectedConditions: [],
			conditionFilter: null,
			working: false,
			mealsEnabled: [true, true, true],
			form: {
				firstName: null,
				lastName: null,
				uname: null,
				pwd: null,
				repeatPwd: null,
				startDate: moment().add(1, "d"),
				termsTrial: false,
				termsPayment: false
			},
			yob: null,
			gender: null,
			// canSubmit: true,
			canSubmit: false,
			loginError: null,
			priceSettings: null,
			partnerToken: null,
		}
	}

	login(user, cb) {
		$.ajax({
			type: "POST",
			url: `${this.context.apiRoot}/auth/login`,
			data: JSON.stringify(user),
			contentType: "application/json"
		}).done(res => {
			this.context.setSession({ token: res.token, user: res.user });
			if (cb) cb();
		}).catch(err => {
			console.error(err.msg)
		})
	}

    registerAndCharge(data, mealPlanPrice) {
        $.post({
            url: `${this.context.apiRoot}/accounts/register/charge`,
            data: JSON.stringify(data),
            contentType: "application/json"
        }).done(response => {
            // Response will be an object with the client secret from the payment intent.
            // If there is a client secret, we need to do 3ds secure.
            if (response.client_secret) {
                this.context.stripe.handleCardAction(response.client_secret).then(result => {
                    if (!result.error) {
                        let newData = Object.assign({}, data, {
                            intent_id: result.paymentIntent.id
                        });
                        delete newData.method_id;
                        this.registerAndCharge(newData, mealPlanPrice);
                    } else {
                        this.setState({
                            working: false,
                            loginError: result.error.message
                        });
                    }
                });
            } else {
                // Otherwise, it's all done!
                if (window.fbq) window.fbq('track', 'Purchase', { value: mealPlanPrice, currency: 'GBP' });
                this.login({
                    uname: data.uname,
                    pwd: data.pwd
                }, () => {
                    return <Redirect to="/account" />
                });
            }
        }).catch(err => {
            console.error("Couldn't register: ", err);
            this.setState({
                working: false,
                loginError: err.responseJSON.msg
            })
        });
    }

    registerFreeTrial(data, mealPlanPrice) {
        $.post({
            url: `${this.context.apiRoot}/accounts/register/trial`,
            data: JSON.stringify(data),
            contentType: "application/json"
        }).done(response => {
            //Facebook tracking
            if (window.fbq) window.fbq('track', 'StartTrial', { value: '0.00', currency: 'GBP', predicted_ltv: mealPlanPrice.toString() });
            //Logging user after successful registration
            this.login({
                uname: data.uname,
                pwd: data.pwd
            }, () => {
                window.location.href = "/account";
            });
        }).catch(err => {
            console.error("Couldn't register: ", err);
            this.setState({
                working: false,
                loginError: err.responseJSON.msg
            });
        });
    }

	canSubmit(values) {
		if (
			values.firstName &&
			values.lastName &&
			values.uname &&
			values.pwd &&
			values.repeatPwd === values.pwd &&
			values.startDate
		) return true;
		return false;
	}

	componentDidUpdate() {
		if (!this.state.conditions.length && this.context.session.ready) {
			this.getConditions();
		}
	}

	componentDidMount() {
		super.componentDidMount();
		this.context.setSession({
			token: null,
			user:null
		})
		if (!this.state.conditions.length && this.context.session.ready) {
			this.getConditions();
		}
		this.getPriceSettings(this.context.query.partnerToken);
	}

	getConditions() {
		$.get(`${this.context.apiRoot}/conditions?tags=true`).done(response => {
			// console.log("conditions: ", response.results)
			this.setState({
				conditions: response.results.filter(c => !c.suspended)
			});
		}).catch(err => {
			console.error("Error getting conditions: ", err);
		});
	}

	conditionAddedCheck(id) {
		//Check if id passed belongs to any of the conditions from the selectedConditions array
		let result = false;
		let selectedConditions = this.state.selectedConditions;
		//Stop check if no elements in the array
		if (!selectedConditions.length) return result;
		selectedConditions.forEach(condition => {
			if (id === condition.id) result = true;
		})
		return result;
	}

	renderConditions(conditions) {
		//If no conditions stop
		if (!conditions.length) return null;
		//Apply filter if added
		let filter = this.state.conditionFilter;
		if (filter) {
			conditions = conditions.filter(c => {
				return c.name.toLowerCase().includes(filter.toLowerCase())
			})
		}
		// Sorting them alphabetically.
		conditions.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			} else if (a.name > b.name) {
				return 1;
			} else {
				return 0;
			}
		})
		// Creating an array for render
		let splitConditions = [
			// {
			//     text: "Eating Preferences?",
			//     subText: <p>
			//         By default, the RYG meal plan includes <b>meat, egg, dairy, fish and other foods</b>. If you have a specific way of eating that you follow, select it from the below, and the plan will be generated to suit your needs. Any others please contact us direct <a href='mailto:info@ryghealth.com'>info@ryghealth.com</a>.
			//         <br /><br />
			//         We already exclude <b>gluten, processed foods</b> and <b>artificial sweeteners</b> and Reset your health is designed to keep your blood sugar stable sugar stable. If you do not exclude them completely now, <b>pulses and eggs</b> will be added back in <b>week 2</b> and <b>diary and grain</b> in <b>week 3</b>.
			//     </p>,
			//     items: conditions.filter(c => c.category === "diet" || c.category === "exclusion")
			// },
			{
				text: "Do you have any of these health problems?",
				subText: <Content>register-page-text-5</Content>,
				items: conditions.filter(c => c.category === "health")
			},
			{
				text: "Anything else that affects your diet?",
				items: conditions.filter(c =>
					c.category !== "diet" &&
					c.category !== "exclusion" &&
					c.category !== "health"
				)
			}
		]
		// Function to convert the condition into a button.
		const createButton = condition => {
			// Look to see if it has been selected already.
			let checked = this.state.selectedConditions.filter(c => c.id === condition.id).length;
			return <ToggleButton
				key={condition.id}
				classes={["condition"]}
				disabled={!checked && this.isConditionExcluded(condition, this.state.selectedConditions)}
				state={checked}
				onChange={state => {
					let selectedConditions = [...this.state.selectedConditions];
					if (state) {
						selectedConditions.push(condition);
					} else {
						// Remove it from selected list
						selectedConditions = selectedConditions.filter(c => {
							return c.id !== condition.id
						})
					}
					// Get the simplest set possible.
					selectedConditions = this.findSimplestSelection(selectedConditions);
					this.setState({ selectedConditions });
				}}
			>{condition.name.toUpperCase()}</ToggleButton>
		}

		// Map over conditions
		return <React.Fragment>
			<div className="category" >
				<div className="stageTitle extraMargin">
					<span className="stage-number">{this.stageUp()}</span>
					<h1>Eating Preferences?</h1>
				</div>
				<div className="subText">
					<Content>register-page-text-1</Content>
				</div>
				<div className="conditions">
					{conditions.filter(c => c.category === "diet").map(c => createButton(c))}
				</div>
				<br />
				<div className="subText">
					<Content>register-page-text-2</Content>
				</div>
				<div className="conditions">
					{conditions.filter(c => c.category === "exclusion").map(c => createButton(c))}
				</div>
			</div>

			{splitConditions.map((category, index) => {
				return (
					category.items.length
						?
						<div className="category" key={`category_${index}`}>
							<div className="stageTitle extraMargin">
								<span className="stage-number">{this.stageUp()}</span>
								<h1>{category.text}</h1>
							</div>
							{category.subText ? <div className="subText" >{category.subText}</div>
								: null}
							<div className={category.items.length > 2 ? "conditions addHeight" : "conditions"}>
								{category.items.map(condition => createButton(condition))}
							</div>
						</div>
						:
						null
				)
			})}
		</React.Fragment>

	}

	findSimplestSelection(conditions = []) {
		// Calculates the simplest selection it can based on tag exclusions.
		return [...conditions]
			.sort((a, b) => a.tags.length - b.tags.length)
			// Now filter from the top.
			.filter(condition => {
				// We need to check the condition against a new set of conditions
				// where it is excluded.
				let setWithoutCondition = conditions.filter(c => c !== condition);
				return !this.isConditionExcluded(condition, setWithoutCondition);
			});
	}

	isConditionExcluded(condition, conditions) {
		// First, if the condition has no tags, keep it on.
		if (!condition.tags.length) return false;
		// We need to see if current conditions completely exclude this one.
		// First, let's get all the currently applied tags by selected conditions.
		let activeTagIds = conditions
			.map(c => c.tags)
			.flat()
			.map(t => t.tag_id);
		// Now go over the tags in this condition, and see if all of them are excluded.
		let tagCount = 0;
		for (let i = 0; i < condition.tags.length; i++) {
			// If it's in the active tags, add to the count.
			if (activeTagIds.includes(condition.tags[i].tag_id))
				tagCount++;
			// If the count has hit the same length as the condition's tags, it's excluded.
			if (tagCount >= condition.tags.length) return true;
		}
		// console.log(activeTags);
		return false;
	}

	renderSelectedConditions(conditions) {
		if (!conditions.length) return null;
		// Sorting them alphabetically.
		conditions.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			} else if (a.name > b.name) {
				return 1;
			} else {
				return 0;
			}
		})
		// Map over conditions
		return conditions.map(condition => {
			return (
				<div
					className="condition"
					key={`${condition.id}_added`}
					onClick={() => {
						let selectedConditions = [...conditions]
							.filter(c => {
								return c.id !== condition.id
							});
						this.setState({ selectedConditions })
					}}
				>
					<span>{condition.name.toUpperCase()}</span>
					<div className="outerCircle">
						<div className="innerCircle">
							<img src={close} alt="close" />
						</div>
					</div>
				</div>
			)
		})
	}

	stageUp() {
		return this.stageNumber++;
	}

	renderFreeTrial(mealPlanPrice) {
		return <div className="col-12 col-md-5 paymentOption free-trial">
			<div className="paymentOption-heading">
				Try before you buy?
			</div>
			<div className="inner">
				<h3>FREE TRIAL</h3>
				<br />
				<div className="moreInfo">
					<Content>register-page-text-2</Content>
				</div>
				<br />
				<div className="tsAndCs">
					<span>
						Confirm you've read, and are happy with our
		<a target="_blank" href="/static/media/Terms%20of%20use%20-%20Reset%20Your%20Health.a55cd562.pdf"> terms of use</a>
					</span>
					<CheckboxField
						change={(err, termsTrial) => {
							this.setState({
								form: Object.assign({}, this.state.form, {
									termsTrial
								})
							});
						}}
					>
						{this.state.form.termsTrial}
					</CheckboxField>
				</div>
				<br />
				<FlipMove
					enterAnimation="fade"
					leaveAnimation="fade"
					duration={200}
				>
					{this.state.form.termsTrial ? <button
						key="button"
						className="freeTrial"
						onClick={e => {
							this.setState({
								working: true,
								loginError: null
							});
							this.registerFreeTrial({
								firstName: this.state.form.firstName,
								lastName: this.state.form.lastName,
								gender: this.state.gender,
								yob: this.state.yob,
								mealsEnabled: this.state.mealsEnabled,
								uname: this.state.form.uname,
								pwd: this.state.form.pwd,
								startDate: this.state.form.startDate.format("YYYY-MM-DD"),
								conditions: this.state.selectedConditions
									.map(c => c.id)
							}, mealPlanPrice);
						}}
					>
						START FREE TRIAL
					</button> : null}
				</FlipMove>
			</div>
		</div>
	}

	renderFullPlan(mealPlanPrice) {
		return <div className="inner">
			<div className="moreInfo">
				To generate your plan, we ask for a one off fee of <b className="price">£{mealPlanPrice}</b>. Once payment succeeds, we'll take you straight through to your account page where you can start exploring!
				<br /><br />
				The system will immediately generate you a 4 week plan based on the dietary preferences you've selected.
				<br /><br />
			</div>
			<div className="tsAndCs">
				<span>
					Confirm you've read, and are happy with our
	<a target="_blank" href="/static/media/Terms%20of%20use%20-%20Reset%20Your%20Health.a55cd562.pdf"> terms of use</a>
				</span>
				<CheckboxField
					change={(err, termsPayment) => {
						this.setState({
							form: Object.assign({}, this.state.form, {
								termsPayment
							})
						});
					}}
				>
					{this.state.form.termsPayment}
				</CheckboxField>
			</div>
			<br />
			<FlipMove
				enterAnimation="fade"
				leaveAnimation="fade"
				duration={200}
			>
				{/* Payment field */}
				{this.state.form.termsPayment ? <div key="paymentWidget" className="payment">
					<PaymentField
						disabled={false}
						onMethod={(err, paymentMethod) => {
							if (!err) {
								let registrationData = {
									firstName: this.state.form.firstName,
									lastName: this.state.form.lastName,
									gender: this.state.gender,
									yob: this.state.yob,
									mealsEnabled: this.state.mealsEnabled,
									method_id: paymentMethod,
									uname: this.state.form.uname,
									pwd: this.state.form.pwd,
									startDate: this.state.form.startDate.format("YYYY-MM-DD"),
									conditions: this.state.selectedConditions
										.map(c => c.id),
								};

								if('partnerToken' in this.context.query){
									registrationData.partnerToken = this.context.query.partnerToken;
								}

								this.registerAndCharge(registrationData, mealPlanPrice);
							} else {
								this.setState({
									working: false,
									loginError: err.message
								});
							}
						}}
						price={mealPlanPrice}
					/>
				</div> : null}
			</FlipMove>
			<div className={"price-badge" + ((this.state.priceSettings !== 0) ? " specialDiscount" : "")}>
				<div className="badge-label">
					<div className="price">
						£{mealPlanPrice}
					</div>
					one off fee
				</div>
			</div>
		</div>
	}

	renderFullDiscountPlan(mealPlanPrice) {
		return <div className="inner">
			<div className="moreInfo">
				We'll take you straight through to your account page where you can start exploring!
				<br /><br />
				The system will immediately generate you a 4 week plan based on the dietary preferences you've selected.
				<br /><br />
			</div>
			<div className="tsAndCs">
				<span>
					Confirm you've read, and are happy with our
	<a target="_blank" href="/static/media/Terms%20of%20use%20-%20Reset%20Your%20Health.a55cd562.pdf"> terms of use</a>
				</span>
				<CheckboxField
					change={(err, termsPayment) => {
						this.setState({
							form: Object.assign({}, this.state.form, {
								termsPayment
							})
						});
					}}
				>
					{this.state.form.termsPayment}
				</CheckboxField>
			</div>
			<br />
			<FlipMove
				enterAnimation="fade"
				leaveAnimation="fade"
				duration={200}
			>
				{this.state.form.termsPayment ? <button
						key="button"
						className="freeTrial"
						onClick={e => {
							this.setState({
								working: true,
								loginError: null
							});
							let registrationData = {
								firstName: this.state.form.firstName,
								lastName: this.state.form.lastName,
								gender: this.state.gender,
								yob: this.state.yob,
								mealsEnabled: this.state.mealsEnabled,
								uname: this.state.form.uname,
								pwd: this.state.form.pwd,
								startDate: this.state.form.startDate.format("YYYY-MM-DD"),
								conditions: this.state.selectedConditions
									.map(c => c.id),
							};

							if('partnerToken' in this.context.query){
								registrationData.partnerToken = this.context.query.partnerToken;
							}

							this.registerAndCharge(registrationData, mealPlanPrice);
						}}
					>
						START PLAN
					</button> : null}

			</FlipMove>
		</div>
	}


    renderPaymentSection(freeTrial, mealPlanPrice) {
        // Render out the actual section.
        return <div className="paymentSection registration-section col-12">
            <div className="row">
                <div className="stageTitle col-12">
                    <span className="stage-number">{this.stageUp()}</span>
                    <h1>Nearly there!</h1>
                </div>
            </div>
            <div className="row">
                {freeTrial ? this.renderFreeTrial(mealPlanPrice) : null }
				<div className={"col-12 paymentOption full-plan" + (freeTrial ? " col-md-7" : "")}>
					<div className="paymentOption-heading">
						I'm ready for the 4 week plan.
					</div>
					{(this.state.priceSettings && this.state.priceSettings.discount && this.state.priceSettings.discount === 100) ? this.renderFullDiscountPlan(mealPlanPrice) : this.renderFullPlan(mealPlanPrice)}
				</div>
			</div>
			{this.state.loginError ? <div className="loginError">
				{this.state.loginError.message || String(this.state.loginError)}
			</div> : null}
		</div>
	}

	getPriceSettings(partnerToken) {
		const urlParams = partnerToken ? `?partnerToken=${partnerToken}` : '';

		$.ajax({
            type: "GET",
            url: `${this.context.apiRoot}/settings/price${urlParams}`
        }).done(res => {
            this.setState({ priceSettings: res.settings });
        }).catch(err => {
            console.error("Error getting price settings: ", err);
        });
	}

    pageContent() {
        this.stageNumber = 1;
		let showFreeTrial = this.context.settings.length && this.context.settings.filter(s => (s.id === 2 && s.name === "freeTrial"))[0].value === "true";

        return (
            <React.Fragment>
                <Helmet>
                    <meta name="keywords" content="Improve gut health" />
                    <meta name="description" content="Your journey starts here -Improve gut health by joining our online gut health course and take control of your health. Sign up online!" />
                    <title>Improve gut health - Join our online Reset your health course</title>
                </Helmet>
                <HeroImage>
                    <div className="hero-content">
                        <h2 className="heroText">
                            Your journey
                        </h2>
						<div className="textImg">
							<img src={startsHere} alt="week by week" />
						</div>
						<div className="register-video-link">
							<a
								className="button"
								href="https://www.youtube.com/watch?v=vYEibHCn1LU"
								target="_blank"
								rel="noopener noreferrer"
							>
								REGISTERING, STEP BY STEP
                                <img src={camera} alt="camera icon" />
							</a>
						</div>
					</div>
				</HeroImage>
				<div className="backgroundTransition"></div>
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="topText">
								{showFreeTrial
									?
									<Content>register-page-text-top</Content>
									:
									<Content>register-page-text-top-no-free-trial</Content>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="container partnerToken">
					{!this.context.query.partnerToken
						?
						<div className="row">
							<div className="col-4 topTextRight">
								Have a discount code?
							</div>
							<div className="col-4">
								<input type="text" name="partnerToken" onChange={e => this.setState({...this.state, partnerToken: e.target.value})} />
							</div>
							<div className="col-4">
								<a
									className="button"
									href={process.env.REACT_APP_PARTNER_BASE_URL + this.state.partnerToken}
								>
									USE IT
								</a>
							</div>
						</div>
						:
						(this.state.priceSettings && this.state.priceSettings.discount &&
						<div className="row">
							<div className="col-12">
								<div className="topText">
									<div>
										<p>You've qualified for a discount price of £{(this.state.priceSettings.amount /100).toFixed(2)}</p>
									</div>
								</div>
							</div>
						</div>
						)
					}
				</div>
				<div className={"userRegistration" + (this.state.working ? " working" : "")}>
					<div className="container">
						<FlipMove
							className="row"
							enterAnimation="fade"
							leaveAnimation="fade"
						>

							{/* Conditions and preferences */}
							<div className="userConditions registration-section col-12">
								<FlipMove className="conditionsList">
									{this.renderConditions(this.state.conditions)}
								</FlipMove>
								<FlipMove className="selectedConditions">
									{this.state.selectedConditions.length
										?
										this.renderSelectedConditions(this.state.selectedConditions)
										:
										<div className="noConditionsSelected">
											You haven't selected any dietary requirements.
                                        </div>
									}
								</FlipMove>
							</div>

							{/* Commitment levels. */}
							<div className="commitmentLevels registration-section col-12">
								<div className="row addPa">
									<div className="col-12 stageTitle extraMargin">
										<span className="stage-number closer">{this.stageUp()}</span>
										<h1>Can't commit to 3 meals a day?</h1>
									</div>
									<div className="col-12 col-lg-4 col-xl-5 moreInfo">
										<Content>register-page-text-3</Content>
									</div>
									<div className="col-12 col-lg-8 col-xl-7 selector">
										<CommitmentSelector
											selection={this.state.mealsEnabled}
											onChange={mealsEnabled => {
												this.setState({ mealsEnabled });
											}}
										/>
									</div>
								</div>
							</div>

							{/* Start date */}
							<div className="otherDetails registration-section col-12">
								<div className="stageTitle extraMargin">
									<span className="stage-number">{this.stageUp()}</span>
									<h1>When do you want to start?</h1>
								</div>
								<div className="startDate">
									<div className="moreInfo">
										{showFreeTrial
											?
											<Content>register-page-text-4</Content>
											:
											<Content>register-page-text-4-no-free-trial</Content>
										}
									</div>
									<label>PICK YOUR START DATE</label>
									<DayDropdown
										days={14}
										value={this.state.form.startDate.clone()}
										start={moment().add(1, "d")}
										onChange={startDate => {
											this.setState({
												form: Object.assign({}, this.state.form, {
													startDate
												})
											});
										}}
									/>
								</div>
							</div>

							{/* Basic info */}
							<div className="registerForm registration-section col-12">
								<div className="stageTitle">
									<span className="stage-number">{this.stageUp()}</span>
									<h1>Tell us about yourself</h1>
								</div>
								<SimpleForm
									onChange={(errors, values) => {
										let form = Object.assign({}, this.state.form, values);
										this.setState({
											form,
											canSubmit: Object.keys(errors).length ?
												false : this.canSubmit(form)
										});
									}}
									fields={[
										{
											overLabel: "FIRST NAME",
											label: "FIRST NAME",
											varname: "firstName",
											required: true,
											validator: (val = "") => {
												if (val.length < 2)
													return "Names must be at least 2 characters.";
												if (/["$%^!£@'"_+=]/g.test(val))
													return "Names shouldn't contain special characters.";
												return true;
											}
										},
										{
											overLabel: "SURNAME",
											label: "SURNAME",
											varname: "lastName",
											required: true,
											validator: (val = "") => {
												if (val.length < 2)
													return "Names must be at least 2 characters.";
												if (/["$%^!£@'"_+=]/g.test(val))
													return "Names shouldn't contain special characters.";
												return true;
											}
										},
										{
											overLabel: "EMAIL ADDRESS",
											label: "someone@someaddress.com",
											varname: "uname",
											postProcess: value => (
												value.toLowerCase()
											),
											required: true,
											validator: (val = "") => {
												if (!/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(val))
													return "Must be a valid email.";
												return true;
											}
										},
										{
											overLabel: "PASSWORD",
											label: "************",
											type: "password",
											varname: "pwd",
											required: true,
											validator: (val = "") => {
												if (val.length < 4)
													return "Passwords must be at least 4 characters.";
												return true;
											}
										},
										{
											overLabel: "REPEAT PASSWORD",
											label: "************",
											type: "password",
											varname: "repeatPwd",
											required: true,
											validator: (val = "", values) => {
												if (val !== values.pwd)
													return "Passwords must match.";
												return true;
											}
										}
									]}
								/>
								<div className="more-info d-flex">
									<div className="yob" style={{ width: "50%" }}>
										<DropdownField
											label="YEAR OF BIRTH"
											options={(() => {
												let years = [];
												for (let i = moment().year(); i >= 1900; i--) years.push({ value: i, label: i })
												return years;
											})()}
											change={(err, val) => {
												this.setState({ yob: val })
											}}
										>
											{this.state.yob}
										</DropdownField>
									</div>
									<div className="gender" style={{ width: "50%" }}>
										<DropdownField
											label="GENDER"
											options={[
												{
													value: "male",
													label: "Male"
												},
												{
													value: "female",
													label: "Female"
												},
												{
													value: "other",
													label: "Other"
												},
											]}
											change={(err, val) => {
												this.setState({ gender: val })
											}}
										>
											{this.state.gender}
										</DropdownField>
									</div>
								</div>
							</div>

                            {/* payment */}
                            {(this.state.canSubmit && this.state.yob && this.state.gender) ? this.renderPaymentSection(showFreeTrial, (this.state.priceSettings.amount / 100).toFixed(2)) : null}

						</FlipMove>
					</div>
					<div className="overlay"></div>
				</div>
			</React.Fragment>
		)
	}
}

export class CommitmentSelector extends React.Component {
	renderPlainTextSelection() {
		let items = [
			this.props.selection[0] ? "BREAKFAST" : null,
			this.props.selection[1] ? "LUNCH" : null,
			this.props.selection[2] ? "DINNER" : null,
		].filter(i => i);
		if (!items.length) return "NOTHING";
		if (items.length === 1) return items[0];
		let string = items.join(", ");
		let lastCommaPos = string.lastIndexOf(", ");
		return string.substr(0, lastCommaPos) + " & " + string.substr(lastCommaPos + 2);
	}
	render() {
		// pre-build summary, as it'll get used twice.
		let summarySection = <div className="inner">
			<img src="/static/media/pale-white-arrow.svg" alt="arrow" />
			<p>You have selected</p>
			<br />
			<b>{this.renderPlainTextSelection()}</b>
		</div>
		// Render out the main container.
		return (
			<div className="commitmentSelector container">
				<div className="row">
					<div className="col-12 col-md-6 left">
						<div className="inner">
							{/* Iterate meals and create a toggle for each. */}
							{["BREAKFAST", "LUNCH", "DINNER"].map((meal, index) => {
								let isDisabled = false;
								if (index === 1 && !this.props.selection[2]) isDisabled = true;
								if (index === 2 && !this.props.selection[1]) isDisabled = true;
								return <div className="selectorRow" key={index}>
									<div className="image" style={{
										backgroundImage: `url("/static/media/commitment-thumbs.png")`,
										backgroundPosition: `100% -${(index + 1) * 80}px`,
										opacity: isDisabled ? "0.6" : "1"
									}} />
									<ToggleButton
										disabled={isDisabled}
										state={this.props.selection[index]}
										fullWidth={true}
										onChange={change => {
											let selection = [...this.props.selection];
											if (this.props.onChange) {
												selection[index] = change;
												this.props.onChange(selection);
											}
										}}
									>{meal}</ToggleButton>
								</div>
							})}
						</div>
					</div>
					<div className="col-12 col-sm-6 d-md-block d-none right">
						{summarySection}
					</div>
					<div className="col-12 d-md-none under">
						{summarySection}
					</div>
				</div>
			</div>
		)
	}
}

export class ToggleButton extends React.Component {
	render() {
		let classes = ["toggleButton"].concat(this.props.classes || []);
		if (this.props.disabled) classes.push("disabled");
		if (this.props.state) classes.push("selected");
		if (this.props.fullWidth) classes.push("wide");
		return <div
			className={classes.join(" ")}
			onClick={() => {
				if (this.props.disabled) return null;
				//If condition not added already add it
				if (this.props.onChange) this.props.onChange(!this.props.state);
			}}
		>
			<span>{this.props.children.toUpperCase()}</span>
			<div className="outerCircle">
				<div className="innerCircle">
					<img alt="x" src={tick} />
				</div>
			</div>
		</div>
	}
}

RegisterPage.defaultProps = {
	pageName: "register"
}

export default RegisterPage

