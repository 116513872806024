import React, { useState } from "react";
import EmailLogin from "./EmailLogin";
import ResetPassword from "./ResetPassword";
import ResetPasswordRequest from "./ResetPasswordRequest";
export const EMAIL_LOGIN = "EMAIL_LOGIN";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD = "RESET_PASSWORD";

export default function Login() {
    
    const [mode, setMode] = useState(EMAIL_LOGIN)

	function onRequestFormChange(form) {
		setMode(form);
	}

	return <div className="login">
		<div className="emailLogin">
			{/* Logging in manually */}
			{mode === EMAIL_LOGIN && <>
				<EmailLogin />
				<br />
				<div
					className="link text-center"
					onClick={e => setMode(RESET_PASSWORD_REQUEST)}
				>Forgotten your password? <span>Click here.</span></div>
			</>}

			{/* Requesting a new password */}
			{mode === RESET_PASSWORD_REQUEST && <>
				<ResetPasswordRequest onRequestFormChange={onRequestFormChange} />
				<br />
				<div
					className="link text-center"
					onClick={e => setMode(EMAIL_LOGIN)}
				>Back to login? <span>Click here.</span></div>
			</>}

			{/* Requesting a new password */}
			{mode === RESET_PASSWORD && <>
				<ResetPassword />
				<br />
				<div
					className="link text-center"
					onClick={e => setMode(EMAIL_LOGIN)}
				>Back to login? <span>Click here.</span></div>
			</>}
		</div>
	</div>

}