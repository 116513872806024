import React from "react";
import Field from "./field";
import $ from "jquery";
// Media import
import sliderIcon from "../media/sliderIcon.svg";

class SliderField extends Field {
    constructor(props) {
        super(props)
        this.state = {
            dragging: false
        }
        this.arr = (() => {
            let arr = [];
            for (let i = this.props.min; i <= this.props.max; i += this.props.increment) arr.push(i);
            return arr;
        })()
        this.settings = {
            type: "sliderField"
        }
        this.stopDragging = this.stopDragging.bind(this);
    }

    getPosition() {
        return ((this.props.children * 100) / (this.props.max + 1)) + ((100 / this.arr.length) / 2);
    }

    stopDragging() {
        if (this.state.dragging) this.setState({
            dragging: false
        });
    }

    componentDidMount() {
        $(window).mouseup(this.stopDragging);
        if (this.props.validateAtStart) {
            this.change(this.props.children)
        }

    }

    componentWillUnmount() {
        $(window).off("mouseup", this.stopDragging)
    }

    renderField() {
        let isValid = typeof this.props.children === "number";
        return (
            <div className="slider-wrapper">
                <div className="label label-left d-none d-md-block">
                    Did not apply.
                </div>
                <div className="label label-right d-none d-md-block">
                    Applied strongly.
                </div>
                <div className="slider noSelect">
                    <div className="main-bar" />
                    <div
                        className={`range-bar ${isValid ? "" : "hidden"}`}
                        style={{
                            width: `${this.getPosition()}%`
                        }}
                    />
                    <div
                        className={`circle ${isValid ? "" : "hidden"}`}
                        onMouseDown={() => {
                            this.setState({ dragging: true })
                        }}
                        style={{
                            left: `calc(${this.getPosition()}% - 15px)`,
                            backgroundImage: `url(${sliderIcon})`,
                            borderRadius: 50,
                            backgroundPosition: "center",
                            backgroundSize: "cover"
                        }}
                    />
                </div>
                <div className="scale noSelect">
                    {this.arr.map(number => {
                        return (
                            <div
                                key={number}
                                className="value"
                                style={{
                                    textAlign: "center",
                                    display: "inline-block",
                                    cursor: "pointer",
                                    width: `${100 / this.arr.length}%`
                                }}
                                onMouseOver={() => {
                                    if (this.state.dragging) this.change(number)
                                }}
                                onClick={() => {
                                    this.change(number)
                                }}
                            >
                                <div className="line"></div>
                                {number}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

}

SliderField.defaultProps = {
    max: 10,
    min: 0,
    increment: 1
}

export default SliderField;