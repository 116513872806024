/**
 *  Tag item structure
 * 
 *      {
            description: "string"
            id: 1
            name: "string"
 *      }       
 */

import React from "react";
import ItemCreator from "./itemCreator";
import TextField from "../../../fields/textField";
import TextareaField from "../../../fields/textareaField";

class TagCreator extends ItemCreator {

    canSubmit() {
        if (this.props.item) {
            //If updating an existing ingredient
            let canSubmit = false;
            Object.keys(this.state.changes).forEach(key => {
                if (key && this.state.changes[key]) {
                    canSubmit = true;
                }        
            })
            if (canSubmit) return true;
        } else {
            if (this.state.item.name) {
                return true;
            }
        }
        //Return false by default.
        return false;
    }

    renderCreator() {
        return (
            <div className="creatorFields">
                <TextField
                    label="Name"
                    change={(err, val) => {
                        this.itemChanged("name", val)
                    }}
                >
                    {this.state.item.name}
                </TextField>
                <TextareaField
                    label="Description"
                    change={(err, val) => {
                        this.itemChanged("description", val)
                    }}
                >
                    {this.state.item.description}
                </TextareaField>    
            </div>
        )
    }
}

export default TagCreator;