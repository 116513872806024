import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom"

import Page from "../page";
import MealPlan from "./mealPlan";
import HeroImage from "../../assets/hero";
import RecipePage from '../recipe';

//Media import
import WeekByWeek from "../../media/week-by-week.svg";

class AccountPage extends Page {

    pageContent() {
        return (
            <React.Fragment>
                <HeroImage
                    content="account-page-hero-img"
                >
                    <div className="hero-content">
                        <h2 className="heroText">
                            Your journey
                        </h2>
                        <div className="textImg">
                            <img src={WeekByWeek} alt="week by week" />
                        </div>
                    </div>
                </HeroImage>
                <Switch>
                    <Route
                        path="/account/meal-plan"
                        render={router => (
                            <MealPlan router={router} />
                        )}
                    />
                    <Route
                        path="/account/recipe/:recipeId"
                        render={router => (
                            <RecipePage router={router} />
                        )}
                    />
                    <Route
                        path="/account"
                        render={router => (
                            <Redirect to="/account/meal-plan" />
                        )}
                    />
                </Switch>
            </React.Fragment>
        )
    }
}

AccountPage.defaultProps = {
    pageName: "accounts"
}

export default AccountPage