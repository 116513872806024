import React from "react";

//Media import
import closeIcon from "../../../media/icon-close.svg";

class ItemCreator extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            item: {},
            changes: {},
            tags: []
        }
    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({ item: this.props.item })
            if (this.getTags) {
                //If item has tags, get them
                this.getTags(this.props.item.id)
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.item && this.props.item && prevProps.item.id !== this.props.item.id) {
            this.setState({ item: this.props.item })
        }
    }

    removeEmptyStrings(object) {
        let item = {}
        // Loop through, and remove empty strings.
        Object.keys(object).forEach(key => {
            if (object[key] !== "") item[key] = object[key];
        });

        return item;
    }

    itemChanged(varname, value, cb) {
        // Create item to be sent.
        let item = this.removeEmptyStrings(Object.assign({}, this.state.item, {
            [varname]: value
        }));
        // And set state.
        this.setState({ item }, () => {
            //Handling changes for existing items
            if (this.props.item && value !== undefined) {
                let changes = Object.assign({}, this.state.changes);
                changes[varname] = value;
                this.setState({ changes }, () => {
                    if (cb) cb();
                });
            }
        });
    }

    submit() {
        //If editing an existing task
        if (this.props.item) {
            //If update handler have been passed
            if (this.props.itemUpdate) {
                this.props.itemUpdate(this.state.changes, this.state.item.id)
                //Resetting changes object
                this.setState({ changes: {} })
            }
        } else {
            //If creating a new item
            if (this.props.itemCreate) {
                this.props.itemCreate(this.state.item)
            }
        }
    }

    canSubmit() {
        return 'This method should return a boolean value'
    }

    renderCreator() {
        return "Overwrite me!"
    }

    renderRowHeader() {
        return this.props.item ? ((this.props.item[this.props.displayName]) + " #" + this.props.item.id) : (this.state.item.name || "New Item");
    }

    render() {
        return (
            <div className="itemCreator">
                <div className="heading">
                    {this.renderRowHeader(this.props.item)}
                </div>
                <div className="editArea">
                    <img
                        className="closeIcon"
                        src={closeIcon}
                        alt="close-icon"
                        onClick={() => {
                            this.props.close()
                        }}
                    />
                    {this.renderCreator()}
                    {this.props.itemUpdate || this.props.itemCreate ? <div
                        className={this.canSubmit() ? "button done-btn" : "done-btn button disabled"}
                        onClick={() => {
                            if (this.canSubmit()) {
                                this.submit()
                            }
                        }}
                    >
                        {this.props.item ? "SAVE" : "CREATE"}
                    </div> : null}
                    {this.props.itemDelete ? <div
                        className={"button delete-btn"}
                        onClick={() => {
                            if (!this.props.item) {
                                this.props.close();
                            } else if (window.confirm("Are you sure you want to remove this item?")) {
                                this.props.itemDelete(this.state.item.id);
                            }
                        }}
                    >
                        {this.props.item ? "DELETE" : "CANCEL"}
                    </div> : null}
                </div>
                {(this.props.item && this.renderTagManager)
                    ?
                    this.renderTagManager(this.props.item)
                    :
                    null
                }
            </div>
        )
    }

}


export default ItemCreator;