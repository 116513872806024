export function validateName(value) {
	if (!(/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,30}$/.test(value))) {
		return "Invalid name.";
	} else {
		return true;
	}
}

export function validateEmail(value) {
	if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {
		return "Invalid email.";
	} else {
		return true;
	}
}