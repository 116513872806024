import $ from "jquery";
import React, { useState, useContext, useEffect, useCallback } from 'react';
import FlipMove from "react-flip-move";
import { Helmet } from 'react-helmet';
import { Redirect, useHistory } from 'react-router-dom';
import moment from "moment";
/* global */
import Context from "../context";
/* fields */
import CheckboxField from "../fields/checkboxField";
import PaymentField from "../fields/paymentField";
/* assets */
import Content from '../assets/content';
import DayDropdown from "../assets/dayDropdown";
import ErrorAlert from "../assets/errorAlert";
import { ToggleButton, CommitmentSelector } from './register';
import close from '../media/close.svg';
/* style */
import '../styles/style.scss'

export default function RestartPlan() {

    const { apiRoot, session, settings, setResNewMealPlan, stripe } = useContext(Context);
    const [conditionFilter] = useState(null);
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [mealsEnabled, setMealsEnabled] = useState([true, true, true]);
    const [showFreeTrial, setShowFreeTrial] = useState(false);
    const [responses, setResponses] = useState(null);
    const [startPlan, setStartPlan] = useState(false);
    const [discountCode, setDiscountCode] = useState('');
    const [partnerToken, setPartnerToken] = useState('');
    const [priceSettings, setPriceSettings] = useState({ amount: 0 });
    const [paymentError, setPaymentError] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [termsPayment, setTermsPayment] = useState(false);
    const [priceResponse, setPriceResponse] = useState(null);
    const [isDuplicated, setIsDuplicated] = useState(false);
    const [showMsgErrorDuplicated, setShowMsgErrorDuplicated] = useState(false);
    const { id, uname } = session.user;
    const history = useHistory();
    const [form, setForm] = useState({
        startDate: moment().add(1, "d"),
    });

    const checkFreeTrial = useCallback(() => {
        if (settings.length && settings.filter(s => s.id === 2 && s.name === "freeTrial")[0].value === "true") {
            setShowFreeTrial(true);
        }
    }, [settings]);

    useEffect(() => {
        const fetchConditions = async () => {
            try {
                const response = await fetch(`${apiRoot}/conditions?tags=true`);
                const data = await response.json();
                setConditions(data.results.filter(c => !c.suspended));
            } catch (error) {
                console.error("Error getting conditions: ", error);
            }
        };
        if (partnerToken.length > 0) {
            getDiscountAmmount();
        };
        getMealPlan();
        checkFreeTrial();
        fetchConditions();
    }, [apiRoot, checkFreeTrial]);

    useEffect(() => {
        const fetchData = async () => {
            if (startPlan) {
                const formatedDate = moment(form.startDate).format("YYYY-MM-DD 00:00:00");
                const formatedConditions = {
                    conditions: selectedConditions.map(c => c.id),
                };
                const data = {
                    user: id,
                    started: formatedDate,
                    discountCode: partnerToken,
                    mealsEnabled: JSON.stringify(mealsEnabled),
                    conditions: formatedConditions.conditions,
                };
                try {
                    const response = await fetch(`${apiRoot}/meal-plans/${uname}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                    });
                    const result = await response.json();
                    if (result && result.success) {
                        setResNewMealPlan({resNewMealPlan: response.ok});
                    }
                    setResponses(result);
                } catch (error) {
                    console.error("Error creating a new meal plan: ", error);
                }
            }
        };
        fetchData();
    }, [apiRoot, conditions, form, id, mealsEnabled, startPlan, uname]);

    const getMealPlan = () => {
        $.get(`${apiRoot}/meal-plans/`).then(res => {
            setDiscountCode(res.mealPlan.discountCode)
        }).catch(err => {
            console.error("Error getting meal plan", err);
        })
    }

    const renderConditions = (conditions) => {
        //If no conditions stop
        if (!conditions.length) return null;
        //Apply filter if added
        let filter = conditionFilter;
        if (filter) {
            conditions = conditions.filter(c => {
                return c.name.toLowerCase().includes(filter.toLowerCase())
            })
        }
        // Sorting them alphabetically.
        conditions.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return 0;
            }
        })
        // Creating an array for render
        let splitConditions = [
            {
                text: "Do you have any of these health problems?",
                subText: <Content>register-page-text-5</Content>,
                items: conditions.filter(c => c.category === "health")
            },
            {
                text: "Anything else that affects your diet?",
                items: conditions.filter(c =>
                    c.category !== "diet" &&
                    c.category !== "exclusion" &&
                    c.category !== "health"
                )
            }
        ]
        // Function to convert the condition into a button.
        const createButton = condition => {
            // Look to see if it has been selected already.
            let checked = selectedConditions.filter(c => c.id === condition.id).length;
            return (
                <ToggleButton
                    key={condition.id}
                    classes={["condition"]}
                    disabled={!checked && isConditionExcluded(condition, selectedConditions)}
                    state={checked}
                    onChange={state => {
                        let selected = selectedConditions;
                        if (state) {
                            selected.push(condition);
                        } else {
                            // Remove it from selected list
                            selected = selected.filter(c => {
                                return c.id !== condition.id
                            })
                        }
                        // Get the simplest set possible.
                        selected = findSimplestSelection(selected);
                        setSelectedConditions(selected);
                    }}
                >
                    {condition.name.toUpperCase()}
                </ToggleButton>
            )
        }
        // Map over conditions
        return (
            <React.Fragment>
                <div className="category" >
                    <div className="stageTitle extraMargin">
                        <span className="stage-number">{1}</span>
                        <h1>Eating Preferences?</h1>
                    </div>
                    <div className="subText">
                        <Content>register-page-text-1</Content>
                    </div>
                    <div className="conditions">
                        {conditions.filter(c => c.category === "diet").map(c => createButton(c))}
                    </div>
                    <br />
                    <div className="subText">
                        <Content>register-page-text-2</Content>
                    </div>
                    <div className="conditions">
                        {conditions.filter(c => c.category === "exclusion").map(c => createButton(c))}
                    </div>
                </div>

                {splitConditions.map((category, index) => {
                    let enumIndex = index + 2;
                    return (
                        category.items.length
                            ?
                            <div className="category" key={`category_${index}`}>
                                <div className="stageTitle extraMargin">
                                    <span className="stage-number">{enumIndex}</span>
                                    <h1>{category.text}</h1>
                                </div>
                                {category.subText ? <div className="subText" >{category.subText}</div>
                                    : null}
                                <div className={category.items.length > 2 ? "conditions addHeight" : "conditions"}>
                                    {category.items.map(condition => createButton(condition))}
                                </div>
                            </div>
                            :
                            null
                    )
                })}
            </React.Fragment>
        )
    }

    const renderSelectedConditions = (conditions) => {
        if (!conditions.length) return null;
        // Sorting them alphabetically.
        conditions.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return 0;
            }
        })
        // Map over conditions
        return conditions.map(condition => {
            return (
                <div
                    className="condition"
                    key={`${condition.id}_added`}
                    onClick={() => {
                        let selectedConditions = [...conditions]
                            .filter(c => {
                                return c.id !== condition.id
                            });
                        setSelectedConditions(selectedConditions);
                    }}
                >
                    <span>{condition.name.toUpperCase()}</span>
                    <div className="outerCircle">
                        <div className="innerCircle">
                            <img src={close} alt="close" />
                        </div>
                    </div>
                </div>
            )
        })
    }

    const findSimplestSelection = (conditions = []) => {
        // Calculates the simplest selection it can based on tag exclusions.
        return [...conditions]
            .sort((a, b) => a.tags.length - b.tags.length)
            // Now filter from the top.
            .filter(condition => {
                // We need to check the condition against a new set of conditions
                // where it is excluded.
                let setWithoutCondition = conditions.filter(c => c !== condition);
                return !isConditionExcluded(condition, setWithoutCondition);
            });
    }

    const isConditionExcluded = (condition, conditions) => {
        // First, if the condition has no tags, keep it on.
        if (!condition.tags.length) return false;
        // We need to see if current conditions completely exclude this one.
        // First, let's get all the currently applied tags by selected conditions.
        let activeTagIds = conditions
            .map(c => c.tags)
            .flat()
            .map(t => t.tag_id);
        // Now go over the tags in this condition, and see if all of them are excluded.
        let tagCount = 0;
        for (let i = 0; i < condition.tags.length; i++) {
            // If it's in the active tags, add to the count.
            if (activeTagIds.includes(condition.tags[i].tag_id))
                tagCount++;
            // If the count has hit the same length as the condition's tags, it's excluded.
            if (tagCount >= condition.tags.length) return true;
        }
        // console.log(activeTags);
        return false;
    }

    const getAllMealPlans = () => {
        fetch(`${apiRoot}/meal-plans/plans?uname=${uname}&plan=${partnerToken}`)
            .then(response => response.json())
            .then(data => {
                if(!data.success){
                    setIsDuplicated(true);
                    setShowMsgErrorDuplicated(true);
                    setTimeout(() => {
                        setShowMsgErrorDuplicated(false);
                    }, 3000);
                }else{
                    setIsDuplicated(false);
                };
        })
        .catch(error => {
            console.error(error);
        });
    };

    const getDiscountAmmount = () => {
        fetch(`${apiRoot}/settings/price?partnerToken=${partnerToken}`)
            .then(response => response.json())
            .then(data => {
                setPriceResponse(data.success);
                setPriceSettings({ ...priceSettings, amount: Number(data.settings.amount) });
            })
    }

    const charge = async (data) => {
        try {
          if (!termsPayment) {
            throw new Error("Please confirm you've read and are happy with our terms of use.");
          }

          const response = await fetch(`${apiRoot}/payments/charge`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });

          if (!response.ok) {
            const error = await response.json();
            setPaymentError(error);
            throw new Error("Failed to charge payment");
          } else {
            setResNewMealPlan({ resNewMealPlan: true });
            history.push("/account");
          }
        } catch (err) {
          console.error("Error: ", err);
        }
    };

    const renderFullDiscountPlan = () => {
        return <div className="inner">
            <div className="moreInfo">
                We'll take you straight through to your account page where you can start exploring!
                <br /><br />
                The system will immediately generate you a 4 week plan based on the dietary preferences you've selected.
                <br /><br />
                <div className="tsAndCs">
                    <span>
                        Confirm you've read, and are happy with our
                        <a target="_blank" href="https://my.resetyourhealth.com/static/media/Terms%20of%20use%20-%20Reset%20Your%20Health.a55cd562.pdf"> terms of use</a>
                        <CheckboxField
                            change={(err, termsPayment) => {
                                setTermsPayment(termsPayment)
                            }}
                            >
                            {termsPayment}
                        </CheckboxField>
                    </span>
                </div>
                <br />
            </div>
            <br />
            <div className='container-button'>
                {priceSettings.amount === 0 && termsPayment && priceResponse &&
                    <button key='button' className='freeTrial my-button' onClick={() => {
                        if (partnerToken !== '' && partnerToken !== discountCode) {
                            setLoginError(null);
                            setStartPlan(true)
                        }
                    }}>
                        RESTART PLAN
                    </button>
                    }
                    {   responses && responses.success === false && 
                        <ErrorAlert message={responses.msg} />
                    }
                    
                {
                    responses && responses.success === true && 
                    <Redirect to='account/meal-plan/before-you-start' />
                }
                {
                    paymentError && <ErrorAlert message={'payment error'} />
                }
            </div>
        </div>
    }

    const renderFullPlan = (mealPlanPrice) => {
        return <div className="inner">
            <div className="moreInfo">
                To generate your plan, we ask for a one off fee of <b className="price">£{mealPlanPrice}</b>. Once payment succeeds, we'll take you straight through to your account page where you can start exploring!
                <br /><br />
                The system will immediately generate you a 4 week plan based on the dietary preferences you've selected.
                <br /><br />
            </div>
            <div className="tsAndCs">
                <span>
                    Confirm you've read, and are happy with our
                    <a target="_blank" href="/static/media/Terms%20of%20use%20-%20Reset%20Your%20Health.a55cd562.pdf"> terms of use</a>
                <CheckboxField
                    change={(err, termsPayment) => {
                        setTermsPayment(termsPayment)
                    }}
                >
                    {termsPayment}
                </CheckboxField>
                </span>
            </div>
            <br />
            <FlipMove
                enterAnimation="fade"
                leaveAnimation="fade"
                duration={200}
            >
                {/* Payment field */}
                {termsPayment && priceSettings.amount !== 0 && <div key="paymentWidget" className="payment">
                    <PaymentField
                        disabled={false}
                        onMethod={(err, paymentMethod) => {
                            if (!err) {
                                const formatedDate = moment(form.startDate).format("YYYY-MM-DD 00:00:00");
                                const formatedConditions = {
                                    conditions: selectedConditions.map(c => c.id),
                                };
                                let chargerData = {
                                    uname,
                                    amount: mealPlanPrice,
                                    method_id: paymentMethod,
                                    partnerToken: partnerToken,
                                    user: id,
                                    started: formatedDate,
                                    discountCode: partnerToken,
                                    mealsEnabled: JSON.stringify(mealsEnabled),
                                    conditions: formatedConditions.conditions,
                                };
                                    charge(chargerData);
                            } else {
                                setLoginError(err.message);
                            }
                        }}
                        price={mealPlanPrice}
                    />
                </div>}
                {
                    paymentError && paymentError.success === false &&
                    <ErrorAlert message="Failed to charge payment" />
                }

            </FlipMove>
            <div className={"price-badge" + ((priceSettings !== 0) ? " specialDiscount" : "")}>
                <div className="badge-label">
                    <div className="price">
                        £{mealPlanPrice}
                    </div>
                    one off fee
                </div>
            </div>
        </div>
    }

    const renderPaymentSection = (freeTrial, mealPlanPrice) => {
        // Render out the actual section.
        return <div className="paymentSection registration-section col-12">
            <div className="row">
                <div className="stageTitle col-12">
                    <span className="stage-number">{6}</span>
                    <h1>Nearly there!</h1>
                </div>
            </div>
            <div className="row">
                <div className={"col-12 paymentOption full-plan" + (freeTrial ? " col-md-7" : "")}>
                    <div className="paymentOption-heading">
                        I'm ready for the 4 week plan.
                    </div>
                    {priceSettings.amount === 0 ? renderFullDiscountPlan() : renderFullPlan(mealPlanPrice)}
                </div>
            </div>
            {loginError ? <div className="loginError">
                {loginError.message || String(loginError)}
            </div> : null}
        </div>
    }


    return (
        <React.Fragment>
            <div className='restartPlan'>
                <Helmet>
                    <meta name="keywords" content="Improve gut health" />
                    <meta name="description" content="Your journey starts here -Improve gut health by joining our online gut health course and take control of your health. Sign up online!" />
                    <title>Improve gut health - Join our online Reset your health course</title>
                </Helmet>
                <div className="restartPlan-margins">
                    {discountCode && <div className="current-code">
                        <h1 className="title"> current code: <span className="code">{discountCode}</span></h1>
                    </div>}
                    <div className="container partnerToken">
                        <div className="row">
                            <h1 className="col-4 topTextRight partnerToken">
                                Discount code
                            </h1>
                            <div className="col-4">
                                <input style={{ textTransform: 'uppercase' }} type="text" name="partnerToken" onChange={e => setPartnerToken(e.target.value.toUpperCase())} />
                                {
                                    showMsgErrorDuplicated && 
                                    <ErrorAlert message="The discount code has already been used" />
                                }
                                {
                                    discountCode === partnerToken && conditions.length &&
                                    <ErrorAlert message="Enter a code different from the current code" />
                                }

                            </div>
                            <div className="col-4">
                                <button
                                    className="button"
                                    onClick={() => {
                                        getDiscountAmmount();
                                        getAllMealPlans();
                                    }}
                                >
                                    USE IT
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {!isDuplicated && priceResponse && <div className="topText">
                                    <div>
                                        <p>price £{(priceSettings.amount / 100).toFixed(2)}</p>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {/* Conditions and preferences */}
                    <div className="userConditions registration-section col-12">
                        <FlipMove className="conditionsList">
                            {renderConditions(conditions)}
                        </FlipMove>
                        <FlipMove className="selectedConditions">
                            {selectedConditions.length
                                ?
                                renderSelectedConditions(selectedConditions)
                                :
                                <div className="noConditionsSelected">
                                    You haven't selected any dietary requirements.
                                </div>
                            }
                        </FlipMove>
                    </div>
                    {/* Commitment levels. */}
                    <div className="commitmentLevels registration-section col-12">
                        <div className="row addPa">
                            <div className="col-12 stageTitle extraMargin">
                                <span className="stage-number closer">{4}</span>
                                <h1>Can't commit to 3 meals a day?</h1>
                            </div>
                            <div className="col-12 col-lg-4 col-xl-5 moreInfo">
                                <Content>register-page-text-3</Content>
                            </div>
                            <div >
                                <CommitmentSelector
                                    selection={mealsEnabled}
                                    onChange={mealsEnabled => {
                                        setMealsEnabled(mealsEnabled);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Start date */}
                    <div className="otherDetails registration-section col-12">
                        <div className="stageTitle extraMargin">
                            <span className="stage-number">{5}</span>
                            <h1>When do you want to start?</h1>
                        </div>
                        <div className="startDate">
                            <div className="moreInfo">
                                {showFreeTrial
                                    ?
                                    <Content>register-page-text-4</Content>
                                    :
                                    <Content>register-page-text-4-no-free-trial</Content>
                                }
                            </div>
                            <label>PICK YOUR START DATE</label>
                            <DayDropdown
                                days={14}
                                value={form.startDate.clone()}
                                start={moment().add(1, "d")}
                                onChange={startDate => {
                                    setForm({ ...form, startDate });
                                }}
                            />
                        </div>
                    </div>
                    {partnerToken.length > 0 && priceResponse && !isDuplicated &&
                        renderPaymentSection(showFreeTrial, (priceSettings.amount / 100).toFixed(2))
                    }
                </div>
            </div>
        </React.Fragment>
    );
}
