import $ from "jquery";
import React, { useState, useContext } from "react";
import Context from "../../context";
import Field from "./Field";
import { validateEmail } from "./helpers";

export default function ResetPassword() {

    const [values, setValues] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [loginError, setLoginError] = useState(null);
    const [success, setSuccess] = useState(false);
    const context = useContext(Context);

    function fieldChange({ name, value, error }) {
        setValues({
            ...values,
            [name]: value
        });
        setFieldErrors({
            ...fieldErrors,
            [name]: error
        });
    }

    return success ? <div className="text-center">
        Password updated successfully.
    <br /><br />
    </div> : <form onSubmit={e => {
        e.preventDefault();
        if (Object.values(fieldErrors).filter(e => e).length) return;
        $.ajax({
            type: "POST",
            url: `${context.apiRoot}/auth/password/reset`,
            data: JSON.stringify({ uname: values.uname, pwd: values.pwd, token: values.token }),
            contentType: "application/json"
        }).done(res => {
            setSuccess(true);
        }).catch(err => {
            let error = err.message || err.responseJSON.message;
            setLoginError(error);
        })
    }}>
            <div>
                We have sent you an email with reset password code, please enter it below along with your new password.
			<br /><br />
            </div>
            <Field
                onChange={fieldChange}
                name="uname"
                values={values}
                errors={fieldErrors}
                autoComplete="email"
                placeholder="Email"
                validate={validateEmail}
            />
            <Field
                onChange={fieldChange}
                name="token"
                type="text"
                values={values}
                errors={fieldErrors}
                placeholder="Code"
            />
            <Field
                onChange={fieldChange}
                name="pwd"
                type="password"
                values={values}
                errors={fieldErrors}
                placeholder="New Password"
                validate={value => {
                    let errors = [];
                    if (!/.{7,40}/.test(value))
                        errors.push("Between 7 and 40 characters.");
                    if (!/[a-z]/g.test(value))
                        errors.push("At least one lower case letter.");
                    if (!/[A-Z]/g.test(value))
                        errors.push("At least one upper case letter.");
                    if (!/[0-9]/g.test(value))
                        errors.push("At least one number.");
                    if (!errors.length) {
                        return true;
                    } else {
                        return errors.join(" ");
                    }
                }}
            />
            <Field
                onChange={fieldChange}
                name="confirmPassword"
                type="password"
                values={values}
                errors={fieldErrors}
                placeholder="Confirm Password"
                validate={value => {
                    if (value !== values.pwd) {
                        return "Must match the new password."
                    } else {
                        return true;
                    }
                }}
            />
            <button
                type="submit"
                disabled={Object.values(fieldErrors).filter(e => e).length}
            >Set password</button>
            {loginError && <>
                <br />
                <br />
                <div className="error text-center">
                    {loginError}
                </div>
            </>}
        </form>
}