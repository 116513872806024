import React from 'react';
import UsefulInfoTemplate from "./index";
import Content from "../../assets/content";

//pdf import
import pdf from "../../media/pdf/ryh-supplements.pdf";
import download from "../../media/icon-download-white.svg";

class SupplementAdvice extends UsefulInfoTemplate {

    content() {
        return (
            <React.Fragment>
                <Content>useful-info-supplement-heading-text</Content>
                <div className="contentBlock shadow2">
                    <Content>useful-info-supplement-content-block-1</Content>
                </div>
                <div className="contentBlock shadow2">
                    <Content>useful-info-supplement-content-block-2</Content>
                </div>
                <div className="contentBlock shadow2">
                    <Content>useful-info-supplement-content-block-3</Content>
                </div>
                <div className="downloadBlock">
                    <div className="text">
                        <h5>Download this guide to help you along the way...</h5>  
                        <p>You can even print it off to stick on the fridge!</p>
                    </div>
                    <a href={pdf} download className="button">
                        DOWNLOAD
                        <img src={download} alt="download icon"/>
                    </a>
                </div>
            </React.Fragment>
        )
    }

}

export default SupplementAdvice;