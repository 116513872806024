import $ from "jquery";
import React from 'react';
import moment from "moment";
import Content from '../../../assets/content';
import Context from "../../../context";

//Media imports
import print from "../../../media/icon-print.svg";
import DayDropdown from '../../../assets/dayDropdown';
import LogoForPrint from "../../../media/RYG-Logo-Orange.svg";
import Quantity from './quantity';

import CheckboxField from "../../../fields/checkboxField";
// import NumberField from "../../../fields/numberFieldNEW";

class ShoppingList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedEssentialRecipes: [],
            snacks: [],
            selectedSnacks: []
        }
    }

    componentDidMount() {
        this.getSnacks()
    }

    getSnacks() {
        $.get({
            url: `${this.context.apiRoot}/recipes/by-type/snack${Boolean(this.context.session.user) ? "?eligibleOnly=true" : ""}`
        }).done(res => {
            // console.log(res);
            this.setState({ snacks: res.results })
        }).catch(err => {
            console.error("Error getting recipes", err);
        })
    }

    renderIngredients() {
        // Splitting ingredients into 3 types
        let storeCupboard = [];
        let cupboard = [];
        let fresh = [];
        let frozen = [];
        // Get a subset of recipes with the allowed essential recipes.
        let recipeSet = this.props.recipes.filter(recipe => {
            if (!recipe.basic) {
                // Anything not a basic recipe can pass.
                return true;
            } else if (this.state.selectedEssentialRecipes.includes(recipe.id)) {
                // Otherwise if it is in the selected list, it can pass.
                return true;
            } else {
                // Otherwise no.
                return false;
            }
        });
        // Adding snack recipes
        if (this.state.selectedSnacks.length) recipeSet = recipeSet.concat(this.state.selectedSnacks); 
        // Total out the ingredients, and sort them.
        let ingredients = this.prepIngredientsForShoppingList(recipeSet)
            .map(i => Object.assign({}, i))
            .sort(i => i.name);
        // And split into categories.
        ingredients.forEach(item => {
            if (item.category === "cupboard") {
                cupboard.push(item)
            } else if (item.category === "fresh") {
                fresh.push(item)
            } else if (item.category === "frozen") {
                frozen.push(item)
            } else if (item.category === "storeCupboard") {
                storeCupboard.push(item)
            }
        });
        //Map over the arrays and render the shopping list
        return (
            <div className="ingredients row">
                {[
                    {
                        title: "Store Cupboard",
                        items: storeCupboard
                    },
                    {
                        title: "Cupboard",
                        items: cupboard
                    },
                    {
                        title: "Fresh",
                        items: fresh
                    },
                    {
                        title: "Frozen",
                        items: frozen
                    }
                ].map(category => {
                    if (!category.items.length) return null;
                    return (
                        <React.Fragment key={category.title}>
                            <div className="col-12">
                                <h1>{category.title}</h1>
                            </div>
                            {category.title === "Store Cupboard"
                                ?
                                // We have to render the store cupboard a little differently.
                                <React.Fragment>
                                    <div className="col-12 storeCupboard-items items">
                                        <div className="storeCupboard-info">
                                            <Content>shopping-list-page-text-2</Content>
                                        </div>
                                        {this.sortByName(category.items)
                                            .map((item, index) => {
                                                return this.renderScIngredient(item)
                                            })
                                        }
                                    </div>
                                </React.Fragment>
                                :
                                // But otherwise, just render the list of items.
                                <div className="col-12 items">
                                    {this.sortByName(category.items)
                                        .map((item, index) => {
                                            return this.renderIngredient(item, index)
                                        })
                                    }
                                </div>
                            }

                        </React.Fragment>
                    )

                })}
            </div>
        )
    }

    /**
     * 
     * @param {Array} recipes the array of full recipes to total
     * ingredients from.
     */
    totalIngredientsFromRecipes(recipes) {
        // Our final return array.
        let addStack = []
        // Create array for all ingredients from each recipe.
        let allIngredients = []
        // Concat all ingredient arrays from recipes together, excluding essentials.
        recipes.forEach(recipe => {
            allIngredients = allIngredients.concat(recipe.ingredients);
        });
        // Loop over all ingredients
        for (let i = 0; i < allIngredients.length; i++) {
            // Pull out one item.
            let ingredient = allIngredients[i];
            // See if a compatible item is already in the add stack.
            let match = null;
            for (let stackPosition = 0; stackPosition < addStack.length; stackPosition++) {
                if (
                    // If the ingredient is the same.
                    addStack[stackPosition].ingredient_id === ingredient.ingredient_id &&
                    // And it has a matching measurement. (or both have no measurement).
                    (addStack[stackPosition].measurement === ingredient.measurement || (!addStack[stackPosition].measurement && !ingredient.measurement))
                    // Then it's a match.
                ) {
                    match = stackPosition;
                    break;
                }
            }

            if (match === null) {
                // If there isn't a match, we can add it straight on the stack.
                addStack.push(ingredient);
            } else {
                // Otherwise combine the totals.
                addStack[match] = Object.assign({}, addStack[match], {
                    quantity: addStack[match].quantity + ingredient.quantity
                });
            }
        }

        // Return totals.
        return addStack;
    }

    prepIngredientsForShoppingList(recipes) {
        // get the recipes in the range specified.
        // Get all the ingredients.
        let allIngredients = this.totalIngredientsFromRecipes(recipes);
        // Flatten and convert measurements.
        let ingredientMap = {}
        allIngredients.forEach(ingredient => {
            ingredientMap[ingredient.ingredient_id] = ingredientMap[ingredient.ingredient_id] || [];
            ingredientMap[ingredient.ingredient_id].push(ingredient);
        });

        // GOOD TO HERE.

        // Try and combine values.
        for (let id in ingredientMap) {
            if (ingredientMap[id].length < 2) continue;
            ingredientMap[id] = this.attemptToCombine(ingredientMap[id]);
        }

        let result = [];
        Object.keys(ingredientMap).forEach(id => {
            result = result.concat(ingredientMap[id]);
        });

        // Return result, and coerce quantities into base measurements.
        return result.map(i => {
            let allowedMeasurements = [
                "g", "kg", "cm", "ml", "l"
            ]
            if ((i.baseMeasurement && i.measurement) || allowedMeasurements.includes(i.measurement)) {
                // If there's a base measurement, construct a quantity, and base it.
                let quant = new Quantity(i.quantity, i.measurement, i.baseMeasurement)
                    .base();
                return Object.assign({}, i, {
                    quantity: quant.value,
                    measurement: quant.measurement
                });
            } else {
                return i;
            }
        })
    }

    attemptToCombine(ingredients) {
        // Result to return.
        let result = []
        // Convert to quantity objects.
        let quantities = []
        // Loop ingredients.
        ingredients.forEach(i => {
            let allowedMeasurements = [
                "g", "kg", "cm", "ml", "l"
            ]
            if (i.baseMeasurement) {
                // If there's a base measurement, construct a quantity.
                let quant = new Quantity(i.quantity, i.measurement, i.baseMeasurement).base();
                // Check it worked.
                if (!isNaN(quant.value)) {
                    // if it did, add it on.
                    quantities.push({
                        quantity: quant,
                        ingredient: i
                    });
                } else {
                    // Otherwise, add to the result.
                    result.push(i);
                }
            } else if (allowedMeasurements.includes(i.measurement)) {
                // Or if it's one of the clear, standard measurements, do the same.
                quantities.push({
                    quantity: new Quantity(i.quantity, i.measurement)
                        .base(),
                    ingredient: i
                });
            } else {
                // Otherwise, add it straight to the result.
                result.push(i);
            }
        });
        let addStack = [];
        // Loop the quantities.
        for (let i = 0; i < quantities.length; i++) {
            if (!addStack.length) {
                // If nothing else is in the addStack, add this.
                addStack.push(quantities[i])
            } else {
                let added = false;
                // Otherwise loop the stack.
                for (let j = 0; j < addStack.length; j++) {
                    // If the types match, add the values.
                    if (addStack[j].quantity.type === quantities[i].quantity.type) {
                        addStack[j].quantity.value += quantities[i].quantity.value;
                        // And continue.
                        added = true;
                        break;
                    }
                }
                // If nothing was added, stick it on the stack too.
                if (!added) addStack.push(quantities[i]);
            }
        }
        // Standardize the add stack, and return stitched together with the result array.
        return addStack
            .map(i => {
                i.ingredient.quantity = i.quantity.value;
                i.ingredient.measurement = i.quantity.measurement;
                return i.ingredient;
            })
            .concat(result);
    }

    decimalsToFractions(value) {
        value = "" + value;
        switch (value) {
            case "0.5":
                return "1/2";
            case "0.25":
                return "1/4";
            case "0.75":
                return "3/4";
            default:
                return value;
        }
    }

    renderIngredientQuantityString(i) {
        // Switch through measurement types.
        let quantity = this.decimalsToFractions(i.quantity);
        switch (i.measurement) {
            case null:
                return `${quantity} x `;
            case "l":
            case "ml":
            case "g":
            case "kg":
            case "cm":
                return `${quantity}${i.measurement} `;
            case "clove":
            case "120g tin":
            case "120ml tin":
            case "160g tin":
            case "160ml tin":
            case "400g tin":
            case "400ml tin":
                return `${quantity} x ${i.measurement} `;
            default:
                return `${quantity} ${i.measurement} `;
        }
    }

    renderScIngredient(item) {
        return (
            <div className="ingredient" key={`${item.ingredient_id}_${item.measurement}_${Math.random()}`}>
                <img src={item.icon ? item.icon : `/static/media/ingredient_icons/${item.ingredient_id}.svg`}
                    alt={item.name}
                />
                <span> <b>{item.name}</b> ({this.renderIngredientQuantityString(item)})</span>
                <div className="borderDiv"></div>
            </div>
        )
    }

    renderIngredient(item) {
        return (
            <div className="ingredient" key={`${item.ingredient_id}_${item.measurement}_${Math.random()}`}>
                <img src={item.icon ? item.icon : `/static/media/ingredient_icons/${item.ingredient_id}.svg`}
                    alt={item.name}
                />
                <span>{this.renderIngredientQuantityString(item)}{item.name}</span>
                <div className="borderDiv"></div>
            </div>
        )
    }

    sortByName(arr) {
        return arr.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            } else if (a.name > b.name) {
                return 1
            } else {
                return 0
            }
        })
    }

    render() {
        // console.log("PROPS:", this.props);
        return (
            <div className="shoppingListView">
                <h1 className="title">Shopping List</h1>
                <div className="logoForPrint">
                    <img src={LogoForPrint} alt="ryg logo" />
                </div>
                <div className="beforeStatement">
                    <Content>shopping-list-page-text-1</Content>
                    <div className="essentialRecipeSwitches">
                        {this.props.recipes.filter(r => r.basic).map(recipe => (
                            <CheckboxField
                                key={recipe.id}
                                label={recipe.name}
                                change={(err, val) => {
                                    if (val) {
                                        this.setState({
                                            selectedEssentialRecipes: [...this.state.selectedEssentialRecipes, recipe.id]
                                        });
                                    } else {
                                        this.setState({
                                            selectedEssentialRecipes: this.state.selectedEssentialRecipes
                                                .filter(id => id !== recipe.id)
                                        });
                                    }
                                }}
                            >
                                {this.state.selectedEssentialRecipes.includes(recipe.id)}
                            </CheckboxField>
                        ))}
                    </div>
                    {/* <Content>shopping-list-page-text-3</Content>
                    <div className="snackRecipeSwitches">
                        {this.state.snacks.map(snack => (
                            <NumberField
                                label={snack.name}
                                key={snack.id}
                                change={(err,val) => {
                                    let snacksToAdd = [];
                                    for (let i = 0; i < val; i++) snacksToAdd.push(snack);
                                    let selectedSnacks = [...this.state.selectedSnacks].filter(s => s.id !== snack.id);
                                    this.setState({ selectedSnacks: selectedSnacks.concat(snacksToAdd) })
                                }}
                            >
                                {this.state.selectedSnacks.filter(s => s.id === snack.id).length}
                            </NumberField>
                        ))}
                    </div> */}
                </div>
                <br />
                <h2 className="subTitle">DATE RANGE</h2>
                <div className="fromTo">
                    <div className="fromWrapper">
                        <span>From</span>
                        <DayDropdown
                            max={this.props.planEnd}
                            days={27}
                            value={this.props.start}
                            start={this.props.planStart}
                            onChange={start => {
                                this.props.onDateRangeChange({
                                    start,
                                    end: (() => {
                                        let end = start.clone().add(2, "d");
                                        if (end.isSameOrBefore(this.props.planEnd, "day")) {
                                            return end;
                                        } else {
                                            return this.props.planEnd.clone();
                                        }
                                    })()
                                })
                            }}
                        />
                    </div>
                    <div className="toWrapper">
                        <span>to</span>
                        <DayDropdown
                            max={this.props.planEnd}
                            days={6}
                            value={this.props.end}
                            start={this.props.start}
                            onChange={end => {
                                this.props.onDateRangeChange({
                                    start: this.props.start.clone(),
                                    end
                                })
                            }}
                        />
                        <span className="inclusive">inclusive</span>
                    </div>
                </div>
                <div className="mobileIcons">
                    <img
                        className="downloadBtn iconBtn"
                        src={print}
                        alt="print button"
                        onClick={() => {
                            window.print()
                        }}
                    />
                </div>
                <div className="list shadow2 container">
                    <div className="desktopIcons">
                        <img
                            className="downloadBtn iconBtn"
                            src={print}
                            alt="print button"
                            onClick={() => {
                                window.print()
                            }}
                        />
                    </div>
                    <div className="row">
                        <div className="rangeTitle col-12">
                            {`${this.props.start.format("D MMMM")} to ${this.props.end.format("D MMMM")}`}
                        </div>
                    </div>
                    {this.props.planEnd.isBefore(moment())
                        ?
                        <h1 className="outside">Current day selection is outside of your meal plan</h1>
                        :
                        this.renderIngredients()
                    }
                </div>
            </div>
        )
    }
}

ShoppingList.contextType = Context;

export default ShoppingList;

