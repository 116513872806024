import React from "react";
import Field from "./field";
import Papa from "papaparse";

class nutritionCsvField extends Field {
    constructor(props) {
        super(props)
        this.settings = {
            type: "csvField"
        }
        this.state.file = null;
    }


    removeBefore(marker, rows) {
        let result = [...rows];
        // Now iterate over and remove anything before the macro stats.
        let index;
        for (index = 0; index < result.length; index++) {
            if (result[index][0] === marker)
                break;
        }
        return result.slice(index + 1);
    }

    filterNullRows(rows) {
        // First get rid of empty values.
        return rows.filter(row => {
            if (!row[0]) return false;
            if (!row[1] && !row[2]) return false;
            return true;
        }).map(row => {
            row[0] = row[0]
                .replace(/:/g, "")
                .trim()
                .toLowerCase()
                .replace(/\s/g, "_")
                .replace(/_{2,}/g, "_");
            return row;
        });
    }

    extractNutrition(rows) {
        return rows.map(row => {
            return {
                name: row[0],
                value: Number(row[1] ? row[1] : 0),
                measurement: row[2]
            }
        })
    }

    parse(csvData) {
        let rows = Papa.parse(csvData).data;
        rows = this.filterNullRows(rows);
        rows = this.removeBefore("macro_stats", rows);
        return this.extractNutrition(this.removeBefore("alcohol", rows));
    }

    renderField() {
        return (
            <label
                htmlFor="csvUpload"
                className="button"
            >
                {this.state.file ? this.state.file.name + " - Click to change" : "Upload Gutritics CSV data"}
                <input
                    style={{ display: "none" }}
                    type="file"
                    id="csvUpload"
                    accept=".csv"
                    onChange={e => {
                        let file = e.target.files[0];
                        this.setState({ file });
                        let fr = new FileReader();
                        fr.onload = event => {
                            let data = this.parse(event.target.result);
                            if (this.change)
                                this.change(data);
                        }
                        fr.readAsText(file);
                    }}
                />
            </label>
        )
    }

}

export default nutritionCsvField;
