import $ from "jquery";
import React from 'react';
import Page from '../page';
import HeroImage from '../../assets/hero';
import Content from '../../assets/content';
import { NavLink, Redirect } from "react-router-dom";
import closeIcon from "../../media/close.svg";

//Media import
import heroText from "../../media/ryg-bys-usefulinfo.svg";

class UsefulInfoTemplate extends Page {

	constructor(props) {
		super(props)
		this.state = {
			modal: false,
			modalLocked: false,
		}
	}

	componentDidUpdate() {
		if (this.state.modal) {
			$(".customModal").fadeIn(200)
		} else {
			$(".customModal").fadeOut(200)
		}
	}

	renderHeadContent() {
		return null
	}

	content() {
		return null
	}

	modalContent(option, closeModal) {
		switch (option) {
			case "contact nutri":
				return (
					<React.Fragment>
						<h2 className="heading">
							Contact a Nutritionist or Dietitian
						</h2>
						<Content>useful-info-contact-nutri</Content>
					</React.Fragment>
				)
			case "faecal":
				return (
					<React.Fragment>
						<h2 className="heading">
							Consider a faecal test
						</h2>
						<Content>useful-info-contact-nutri</Content>
					</React.Fragment>
				)
			default:
				return null
		}
	}

	pageContent() {
		const noCurrentLogin = !Boolean(this.context.session.user);

		if (noCurrentLogin) return <Redirect to="/" />

		const links = [
			{ text: "The RYH Questionnaire", url: "/account/meal-plan/questionnaire" },
			{ text: "Weekly Guidelines", url: "/useful-information/weekly-guidelines" },
			{ text: "Top Tips", url: "/useful-information/top-tips" },
			{ text: "Healthy Snacks", url: "/useful-information/healthy-snacks" },
			{ text: "The RYH Programme", url: "/useful-information/how-does-reset-your-gut-programme-work" },
			{ text: "Contact a Nutritionist or Dietitian", url: "https://resetyourhealth.com/about/team/", download: true, modal: "contact nutri" },
			{ text: "Supplement advice", url: "/useful-information/supplement-advice" },
			{ text: "Consider a faecal test", url: "https://resetyourhealth.com/about/team/", download: true, modal: "faecal" },
			{ text: "Dos and don'ts", url: "/useful-information/do-and-donts" },
			{ text: "Clean 15 and the dirty dozen", url: "/useful-information/clean-15-and-the-dirty-dozen" },
			{ text: "Overview", url: "/useful-information/overview" },
			{ text: "Feeding your gut bacteria", url: "/useful-information/feeding-your-gut-bacteria" },
			{ text: "Guidelines for rebalancing your gut bacteria", url: "/useful-information/rebalancing-your-gut-bacteria" }
		];
		return (
			<React.Fragment>
				{this.renderHeadContent()}
				<HeroImage
					content="useful-info-hero-img"
				>
					<div className="hero-content">
						<div className="textImg">
							<img src={heroText} alt="useful information" />
						</div>
					</div>
				</HeroImage>
				<div>
					<div className="beforeView">
						<div className="customModal">
							<div className="inner shadow1">
								<div className="content">
									{!this.state.modalLocked ? <img
										className="closeModal"
										src={closeIcon}
										alt="close modal icon"
										onClick={() => {
											this.setState({ modal: false, modalLocked: false })
										}}
									/> : null}
									{this.modalContent(this.state.modal, () => {
										this.setState({ modal: false, modalLocked: false })
									})}
								</div>
							</div>
						</div>
					</div>
					<div className="container content-container">
						<div className="row">
							<div className="col-12 col-lg-9">
								{this.content()}
							</div>
						</div>
					</div>
					<div className="sideNav">
						{links.map((link, index) => (
							link.download
								?
								<div key={index} className="sideLink" onClick={() => this.setState({ modal: link.modal }) }>
									{link.text}
								</div>
								:
								<NavLink key={index} exact to={link.url} className="sideLink" activeClassName="selected">
									{link.text}
								</NavLink>
						))}
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default UsefulInfoTemplate;