import React from "react";
import Tab from "./tab";
import TagCreator from "../creators/tagCreator";

class Tags extends Tab {

    itemCreator(item) {
        return (
            <TagCreator
                key="ingredientCreator"
                item={item}
                displayName={this.displayName}
                itemUpdate={(changes, id) => {
                    this.editItem(changes, id)
                }}
                itemCreate={item => {
                    this.createItem(item)
                }}
                itemDelete={id => {
                    this.deleteItem(id)
                }}
                close={() => {
                    this.setState({ adding: false, selected: null })
                }}
            />    
        )
    }

}

export default Tags;