import $ from "jquery";
import React from "react";
import Context from "../../../context";
import PageDropdown from "../../../assets/pageDropdown";
import FlipMove from "react-flip-move";
// Media import
import gear from "../../../media/gear.svg";
import cLeft from "../../../media/icon-chevron-left.svg";
import cRight from "../../../media/icon-chevron-right.svg"

class Tab extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.displayName = this.displayName || "name";
        this.state = {
            items: [],
            selected: null,
            adding: false,
            page: 0,
            query: context.query.name ? context.query.name : ""
        }
        this.delay = null;
        this.itemsRequested = false;
    }

    componentDidMount() {
        // Getting items
        if (!this.itemsRequested && this.context.session.ready && this.context.session.user) {
            this.getItems();
            this.itemsRequested = true;
        }
    }

    componentDidUpdate() {
        if (!this.itemsRequested && this.context.session.ready && this.context.session.user && !this.state.items.length) {
            this.getItems();
            this.itemsRequested = true;
        }
    }

    getItems() {
        $.get(`${this.context.apiRoot}/${this.props.route}`).done(res => {
            // console.log("ITEMS", res.results); 
            this.setState({ items: res.results.sort(this.sortByName.bind(this)) })
        }).catch(err => {
            console.error(`Error getting ${this.props.route}`, err)
        })
    }

    sortByName(a, b) {
        let aName = a[this.displayName] + "";
        let bName = b[this.displayName] + "";
        if (aName.toLowerCase() < bName.toLowerCase()) {
            return -1;
        } else if (bName.toLowerCase() < aName.toLowerCase()) {
            return 1;
        } else {
            return 0;
        }
    }

    createItem(item, cb) {
        $.post({
            url: `${this.context.apiRoot}/${this.props.route}`,
            data: JSON.stringify(item),
            contentType: "application/json"
        }).then(res => {
            // Getting updated list of items
            this.getItems()
            // Closing the creator on success
            this.setState({ selected: null, adding: false })
        }).catch(err => {
            console.error(err);
        })
    }

    editItem(changes, id) {
        $.ajax({
            type: "PUT",
            url: `${this.context.apiRoot}/${this.props.route}/${id}`,
            data: JSON.stringify(changes),
            contentType: "application/json"
        }).then(res => {
            // Getting updated list of items
            this.getItems()
            // Closing the editor on success
            this.setState({ selected: null, adding: false })
        }).catch(err => {
            console.error(err)
        })
    }

    deleteItem(id) {
        $.ajax({
            type: "DELETE",
            url: `${this.context.apiRoot}/${this.props.route}/${id}`
        }).then(res => {
            // Getting updated list of items
            this.getItems()
        }).catch(err => {
            console.error(err)
        })
    }

    renderContent() {
        let query = this.state.query.toLowerCase();
        let items = this.state.items.filter(item => {
            if (query) {
                return (item[this.displayName] + "").toLowerCase().includes(query);
            } else {
                return true;
            }
        });
        //Limit results to 20 items per page (if more than 20 items)
        if (items.length > 15) {
            let start = this.state.page * 15;
            let end = start + 15;
            items = items.slice(start, end);
        }
        return items.map(item => {
            if (item.id === this.state.selected) {
                //If you want to edit this item
                return this.itemCreator(item);
            } else {
                return (
                    <div
                        className="item"
                        key={item.id}
                        onClick={e => {
                            if (e.target.className === "optionsImg") return null;
                            this.setState({ selected: item.id, adding: false })
                        }}
                    >
                        {this.renderRowHeader(item)}
                        {this.renderItemOptions(item)}
                    </div>
                )
            }
        })
    }

    renderRowHeader(item) {
        return `${(item[this.displayName])} #${item.id}`.toUpperCase();
    }

    renderItemOptions(item) {
        return <div className="options">
            <img className="optionsImg" src={gear} alt="edit item" />
        </div>
    }

    renderPageChanger() {
        let total = Math.floor(this.state.items.length / 15);
        let currentPage = this.state.page;
        return (
            <div className="pageChanger" key="pageChanger">
                <img
                    alt="left chevron"
                    src={cLeft}
                    className={currentPage > 0 ? "left" : "left disabled"}
                    onClick={() => {
                        if (currentPage > 0) {
                            this.setState({ page: currentPage - 1 })
                        }
                    }}
                />
                <PageDropdown
                    pages={total + 1}
                    value={currentPage}
                    onChange={page => {
                        this.setState({ page })
                    }}
                />
                <img
                    alt="right chevron"
                    src={cRight}
                    className={currentPage < total ? "right" : "right disabled"}
                    onClick={() => {
                        if (currentPage < total) {
                            this.setState({ page: currentPage + 1 })
                        }
                    }}
                />
            </div>
        )
    }

    itemCreator(item) {
        console.warn("Overwrite me!");
    }

    render() {
        return (
            <div className={`adminTab ${this.props.name}Tab`}>
                <h2 className="title">{this.props.name}</h2>
                {this.renderAbove ?
                    <div className="above">
                        {this.renderAbove()}
                    </div>
                    : null
                }
                <FlipMove
                    className="itemList"
                    enterAnimation="fade"
                    leaveAnimation="fade"
                    duration={200}
                >
                    <input
                        type="text"
                        placeholder="Filter items.."
                        defaultValue={this.context.query.name ? this.context.query.name : ""}
                        onChange={e => {
                            let query = e.target.value;
                            if (this.delay) clearTimeout(this.delay);
                            this.delay = setTimeout(() => {
                                this.setState({ query });
                            }, 250);
                        }}
                    />
                    {this.renderContent()}
                    {this.state.adding
                        ?
                        this.itemCreator(null)
                        :
                        !this.addingDisabled ? <div
                            key="addButton"
                            className="addItem"
                            onClick={() => {
                                this.setState({
                                    adding: true,
                                    selected: null
                                })
                            }}
                        >
                            {`NEW ${this.props.name.substring(0, this.props.name.length - 1).toUpperCase()}`}
                            <span>+</span>
                        </div> : null
                    }
                    {this.state.items.length > 15
                        ?
                        this.renderPageChanger()
                        :
                        null
                    }
                </FlipMove>
                {this.renderBelow ?
                    <div className="below">
                        {this.renderBelow()}
                    </div>
                    : null
                }
            </div>
        )
    }
}

Tab.contextType = Context;

export default Tab;