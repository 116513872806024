import $ from "jquery";
import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from "react-router-dom"
import Context from "../context";
import Page from "./page";
import HeroImage from "../assets/hero";
import SimpleForm from "../assets/simpleForm";
import CheckboxField from "../fields/checkboxField";
import Content from "../assets/content";

//Media import
// import heroBg from "../media/hero-register.jpg";
import startsHere from "../media/header-title-contact-white.svg";
import facebook from "../media/facebook.svg";
import instagram from "../media/instagram.svg";
import linkedin from "../media/linkedin.svg";
import twitter from "../media/twitter.svg";
import closeIcon from "../media/icon-close-white.svg";

class ContactPage extends Page {

    constructor(props) {
        super(props);
        this.state = {
            modalContent: null,
            tsAndCs: false,
            guidePls: false
        }
    }

    componentDidUpdate() {
        if (this.state.modalContent) {
            $(this.refs.modal).fadeIn(200)
        } else {
            $(this.refs.modal).fadeOut(200)
        }
    }

    submit(formData) {
        let data = Object.assign({}, formData);
        delete data.tsAndCs;
        $.post({
            url: `${this.context.apiRoot}/contact`,
            data: JSON.stringify(data),
            contentType: "application/json"
        }).done(res => {
            //Facebook tracking
            if (window.fbq) window.fbq('track', 'Contact');
            //Displaying success msg
            $(".contact-form-explainer").slideUp(300);
            $(".form").slideUp(300, () => {
                $(".response")
                    .text("Thanks for getting in touch!")
                    .removeClass("bad")
                    .addClass("good")
                    .slideDown(300);
            })
        }).catch(err => {
            $(".response")
                .text(`Error submitting the form, please try again.`)
                .removeClass("good")
                .addClass("bad")
                .slideDown(300);
        })
    }

    pageContent() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta name="keywords" content="Reset your health team" />
                    <meta name="description" content="Interested in receiving the free 'Guidelines on re-balancing your gut bacteria' and 'How does the RYH programme work?' Contact us for a copy." />
                    <title>Contact the Reset your health team with any questions</title>
                </Helmet>
                <HeroImage>
                    <div className="hero-content">
                        <div className="textImg">
                            <img src={startsHere} alt="week by week" />
                        </div>
                    </div>
                </HeroImage>
                <div className="customModal" ref="modal">
                    <div className="inner shadow1">
                        <div className="content">
                            <img
                                className="closeModal"
                                src={closeIcon}
                                alt="close modal icon"
                                onClick={() => {
                                    this.setState({ modalContent: null })
                                }}
                            />
                            {this.state.modalContent}
                        </div>
                    </div>
                </div>
                <div className="backgroundTransition"></div>
                <div className="container">
                    <div className="row row justify-content-center">
                        <div className="col-12 col-lg-10 contact-form-explainer">
                            <Content>contact-page-text-top</Content>
                        </div>
                        <div className="col-12">
                            <SimpleForm
                                recaptchaSiteKey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                                onSubmit={data => {
                                    // return console.log(data);
                                    if (data.guidePls) {
                                        this.submit(data)
                                    } else {
                                        this.setState({
                                            modalContent: <div className="container">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h1>Hey now!</h1>
                                                    </div>
                                                    <div className="col-12">
                                                        You've opted not to receive the free guides on resetting your gut bacteria.
                                                        <br />
                                                        Are you sure you don't want to be sent the guides?
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <button
                                                            className="button fullWidth"
                                                            style={{
                                                                marginTop: "20px"
                                                            }}
                                                            onClick={() => {
                                                                let newData = Object.assign({}, data, { guidePls: true });
                                                                this.submit(newData);
                                                                this.setState({ modalContent: null });
                                                            }}
                                                        >
                                                            Oh, go on then!
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <button
                                                            className="button fullWidth"
                                                            style={{
                                                                marginTop: "20px",
                                                                backgroundColor: "#25313e"
                                                            }}
                                                            onClick={() => {
                                                                this.submit(data);
                                                                this.setState({ modalContent: null });
                                                            }}
                                                        >
                                                            No thanks, just a message.
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        });
                                    }
                                }}
                                fields={[
                                    {
                                        type: "custom",
                                        varname: "guidePls",
                                        render: ({ handler, fieldData }) => {
                                            return <div className="freeGuideSelector">
                                                {/* <div className="images">
                                                    <img src={guidelines} alt="free-guide" />
                                                    <img src={guidelines} alt="free-guide2" />
                                                </div> */}
                                                <div className="inner">
                                                    <Content>contact-page-guidelines</Content>
                                                    <CheckboxField
                                                        change={(err, guidePls) => {
                                                            this.setState({
                                                                guidePls
                                                            })
                                                            handler(fieldData, guidePls);
                                                        }}
                                                    >
                                                        {this.state.guidePls}
                                                    </CheckboxField>
                                                </div>
                                            </div>
                                        }
                                    },
                                    {
                                        overLabel: "FIRST NAME *",
                                        label: "First name",
                                        varname: "FirstName",
                                        required: true,
                                        validator: (val = "") => {
                                            if (val.length < 2)
                                                return "Names must be at least 2 characters.";
                                            if (/["$%^!£@'"_+=]/g.test(val))
                                                return "Names shouldn't contain special characters.";
                                            return true;
                                        }
                                    },
                                    {
                                        overLabel: "SURNAME *",
                                        label: "Last name",
                                        varname: "LastName",
                                        required: true,
                                        validator: (val = "") => {
                                            if (val.length < 2)
                                                return "Names must be at least 2 characters.";
                                            if (/["$%^!£@'"_+=]/g.test(val))
                                                return "Names shouldn't contain special characters.";
                                            return true;
                                        }
                                    },
                                    {
                                        overLabel: "EMAIL ADDRESS *",
                                        label: "someone@someaddress.com",
                                        varname: "Email",
                                        postProcess: value => (
                                            value.toLowerCase()
                                        ),
                                        required: true,
                                        validator: (val = "") => {
                                            if (!/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(val))
                                                return "Must be a valid email.";
                                            return true;
                                        }
                                    },
                                    {
                                        overLabel: "PHONE NUMBER - OPTIONAL",
                                        label: "01234 567891",
                                        varname: "Phone",
                                        validator: (val = "") => {
                                            let phoneTest = /^[\d+-\s()]{4,18}$/g;
                                            if (!val.length) return true;
                                            if (!phoneTest.test(val)) {
                                                return "Must be a valid telephone number";
                                            } else {
                                                return true;
                                            }
                                        }
                                    },
                                    {
                                        overLabel: "YOUR MESSAGE - OPTIONAL",
                                        label: "If there's anything you'd like to ask us, enter it here!",
                                        type: "textarea",
                                        varname: "message",
                                        required: false,
                                        validator: (val = "") => {
                                            if (val.length > 1000) {
                                                return "Message must be shorter than 1000 characters.";
                                            }
                                            return true;
                                        }
                                    },
                                    {
                                        type: "recaptcha",
                                        varname: "recaptcha",
                                        required: true
                                    },
                                    {
                                        type: "custom",
                                        varname: "tsAndCs",
                                        required: true,
                                        render: ({ handler, fieldData }) => {
                                            return <div className="tsAndCs">
                                                <span>
                                                    To send your message through, you'll have to read and agree to our terms and conditions by ticking the checkbox. To have a read, <a href="/static/media/Privacy%20Notice.95f9ec59.pdf" target="_blank">click here</a>.
                                                </span>
                                                <CheckboxField
                                                    change={(err, tsAndCs) => {
                                                        this.setState({
                                                            tsAndCs
                                                        })
                                                        handler(fieldData, tsAndCs);
                                                    }}
                                                >
                                                    {this.state.tsAndCs}
                                                </CheckboxField>
                                            </div>
                                        },
                                        validator: (val = "") => {
                                            if (!val) {
                                                return "You must accept the terms and conditions.";
                                            }
                                            return true;
                                        }
                                    }
                                ]}
                            />
                            <div className="response"></div>
                        </div>
                        <div className="col-12 sm-col">
                            <p>Visit our social media pages</p>
                            <div className="smicons">
                                {[
                                    {
                                        alt: "facebook icon",
                                        icon: facebook,
                                        link: "https://www.facebook.com/resetyourhealthplan"
                                    },
                                    {
                                        alt: "linkedin icon",
                                        icon: linkedin,
                                        link: "https://www.linkedin.com/company/reset-your-health"
                                    },
                                    {
                                        alt: "instagram icon",
                                        icon: instagram,
                                        link: "https://www.instagram.com/resetyourhealthplan"
                                    },
                                    {
                                        alt: "twitter icon",
                                        icon: twitter,
                                        link: "https://twitter.com/RYHPlan"
                                    }
                                ].map(item => (
                                    <a
                                        className="smLink"
                                        href={item.link}
                                        key={item.alt + "_" + item.link}
                                        target="_blank"
                                    >
                                        <img src={item.icon} alt={item.alt} />
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className="col-12 team-col">
                            <p>
                                If you'd like to get in touch with an RYH nutritionist, head on over to the <NavLink to="/about/team">team</NavLink> page.
                            </p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

ContactPage.contextType = Context;

export default ContactPage