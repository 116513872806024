// import $ from "jquery";
import React from 'react';
// import { Helmet } from 'react-helmet';
import Page from "./page";
import HeroImage from "../assets/hero";
import UpgradeBox from "../assets/upgradeBox";
import Content from "../assets/content";

// Media import
import logo from "../media/RYG-Logo-Blue.svg";
import heroBg from "../media/hero-home.jpg";
import heroText from "../media/ryg-splash-takecontrol.svg"; //Placeholder

class Upgrade extends Page {

    pageContent() {
        return (
            <React.Fragment>
                {/* Helmet goes here */}
                <HeroImage
                    img={heroBg}
                >
                    <div className="hero-content">
                        <div className="textImg">
                            <img src={heroText} alt="take control of your gut health" />
                        </div>
                        <h1 className="inlineLogo">
                            <span>Sign up to</span>
                            <img src={logo} alt="RYG logo" />
                            <span>today</span>
                        </h1>
                    </div>
                </HeroImage>
                <div className="textSection">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <Content>upgrade-page-heading-text</Content>
                            </div>
                            <div className="col-12 col-md-10 col-lg-8">
                                <UpgradeBox
                                    msgTheme="light"
                                    uname={this.context.query.uname}
                                />
                            </div>
                            <div className="col-12">
                                <Content>upgrade-page-top-section-text</Content>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="darkSection">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <Content>upgrade-page-bottom-section-text</Content>
                            </div>
                            <div className="col-12 col-md-10 col-lg-8">
                                <UpgradeBox
                                    msgTheme="dark"
                                    uname={this.context.query.uname}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Upgrade;