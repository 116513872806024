import $ from "jquery";
import React from 'react';
import Context from "../../context";
import CheckboxField from "../../fields/checkboxField";

class Settings extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            settings: []
        }
    }

    componentDidMount() {
        // Getting list of settings
        this.getSettings()   
    }

    getSettings() {
        $.ajax({
            type: "GET",
            url: `${this.context.apiRoot}/settings`
        }).done(res => {
            // console.log("got settings:", res);
            this.setState({ settings: res.settings })
        }).catch(err => {
            console.error("Error getting settings: ", err)         
        })
    }

    changeSettings(changes, id) {
        if (window.confirm("Are you sure you want to change this setting?")) {
            $.ajax({
                type: "PUT",
                url: `${this.context.apiRoot}/settings/${id}`,
                data: JSON.stringify(changes),
                contentType: "application/json"
            }).done(res => {
                let settings = [...this.state.settings].map(s => {
                    if (s.id === id) return Object.assign({}, s, changes);
                    return s
                })
                this.setState({ settings });
            }).catch(err => {
                console.error("Couldn't update setting with: ", err)
            })
        }
    }

    render() {
        return (
            <div className="adminTab settingTab">
                <div className="title">Settings</div>
                <div className="settings">
                    <div className="sInner">
                        {this.state.settings.map(setting => {
                            switch(setting.type) {
                                case "bool":
                                    return (
                                        <CheckboxField
                                            key={setting.id}
                                            label={setting.label}
                                            change={(err, val) => {
                                                let value = val ? "true" : "false";
                                                this.changeSettings({ value }, setting.id)
                                            }}
                                        >
                                            {setting.value === "true" ? true : false}
                                        </CheckboxField>
                                    );
                                default:
                                    console.log(`Unsupported type for ${setting.name}`);
                                    return (
                                        <div class="unsupported" />
                                    )
                            }
                        })}
                    </div>
                </div>
            </div>
        )
    }

}

Settings.contextType = Context;

export default Settings;