import $ from "jquery";
import React from 'react';
import { Link } from "react-router-dom";
import Context from "../context";
import PaymentField from "../fields/paymentField";
import FlipMove from "react-flip-move";

//Media import
import userIcon from "../media/user.svg";
import closeIcon from "../media/close.svg";

class UpgradeBox extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.checkTimer = null;
        this.state = {
            makingPayment: false,
            type: null,
            uname: (() => {
                if (props.uname) return props.uname;
                if (Boolean(context.session.user))
                    return context.session.user.uname;
                return "";
            })()
        }
    }

    componentDidMount() {
        this.checkUser(this.state.uname);
    }

    componentWillUnmount() {
        // Clearing the check query timeout if exists.
        if (this.checkTimer) clearTimeout(this.checkTimer);
    }

    checkUser(uname) {
        // Api call checking if user has registered and has a meal plan
        if (this.checkTimer) clearTimeout(this.checkTimer);
        if (
            /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(uname)
        ) {
            this.checkTimer = setTimeout(() => {
                $.ajax({
                    type: "GET",
                    url: `${this.context.apiRoot}/check-user?uname=${uname}`
                }).then(res => {
                    // console.log("Checked user: ", res);
                    let type = res.user ? res.user.type : "noUser"
                    this.setState({ type });
                }).catch(err => {
                    console.error("Couldn't check user: ", err);
                })
            }, 500);
        } else {
            this.setState({ type: null });
        }
    }

    upgrade(stripe_id, uname) {
        $.post({
            url: `${this.context.apiRoot}/accounts/upgrade`,
            data: JSON.stringify({ stripe_id, uname }),
            contentType: "application/json"
        }).then(response => {
            // Successful upgrade, so re-get plan.
            this.setState({ type: "paid" })
        }).catch(err => {
            //If there was an error enable the payment field
            this.makingPayment = false;
            this.setState({ makingPayment: false })
            console.error("error paying: ", err);
        });
    }

    renderBottomField(type) {
        switch (type) {
            case "noUser":
                return (
                    <div className={"message " + this.props.msgTheme} key="no-user">
                        You haven't yet signed up for your RYG trial. Head over and <Link to="/register">
                            register
                    </Link> for free!
                </div>
                );
            case "trial":
                return (
                    <PaymentField
                        key="payment"
                        price={process.env.REACT_APP_MEAL_PLAN_PRICE}
                        disabled={this.state.makingPayment}
                        onToken={(err, token) => {
                            if (this.makingPayment) return;
                            if (!err) {
                                //Disable the payment field after clicking the pay btn.
                                this.makingPayment = true;
                                this.setState({ makingPayment: true }, () => {
                                    this.upgrade(token.id, this.state.uname)
                                })
                            }
                        }}
                    />
                );
            case "full":
                return (
                    <div className={"message " + this.props.msgTheme} key="got-plan">
                        You've got a plan already! {
                            Boolean(this.context.session.user) 
                                ?
                                <span>Head over to <Link to="/account">your account</Link> now.</span>
                                :
                                <span>Log in at the top-right of the page!</span>
                        }
                    </div>
                );
            case "paid":
                return (
                    <div className={"message " + this.props.msgTheme} key="plan-paid">
                        You have successfully upgraded to the full version! Log in at the top-right of the page!
                    </div>
                )
            default:
                return null;
        }
    }

    render() {
        let type = this.state.type;
        let colorClasses = [];
        let message;
        if (["full", "noUser", null].includes(type)) {
            colorClasses.push("orange");
            message = "HEY NOW!";
        } else {
            colorClasses.push("green");
            message = "GOOD TO GO!";
        }
        if (!type) colorClasses.push("grayed");

        return (
            <FlipMove className="upgradeBox">
                <div className="emailField shadow2" key="emailField">
                    <div className={colorClasses.join(" ")}>
                        {message}
                    </div>
                    {type !== "paid"
                        ?
                        <div key="input" className="inner">
                            <img src={userIcon} alt="user icon" className="userImg" />
                            <input
                                type="text"
                                placeholder="your email..."
                                value={this.state.uname}
                                onChange={e => {
                                    let uname = e.target.value;
                                    this.setState({ uname }, () => {
                                        this.checkUser(uname);
                                    });
                                }}
                            />
                            <img
                                className="closeImg"
                                src={closeIcon}
                                alt="close icon"
                                style={{
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    this.setState({ uname: "", type: null })
                                }}
                            />
                        </div>
                        :
                        null
                    }
                </div>
                {this.renderBottomField(this.state.type)}
            </FlipMove>

        )
    }

}

UpgradeBox.contextType = Context;

export default UpgradeBox;