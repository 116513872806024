/**
 *  PROPS
 * 
 *      recipe: OBJECT 
 */

import React from "react";
import Context from "../../../context";
import { NavLink } from "react-router-dom";

//Media import
import ingredientsIcon from "../../../media/icons/misc/ingredients.svg";
import method from "../../../media/icons/misc/method.svg";
import print from "../../../media/icon-print.svg";
import LogoForPrint from "../../../media/RYG-Logo-Orange.svg";
import $ from "jquery";

class Recipe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: "method", // "method" || "ingredients" user selects this only on mobile view, both tabs visible on desktop
            bloglets: [],
            hoveredBloglet: null
        }
        this.gotBloglets = false;
    }

    componentDidMount() {
        if (Boolean(this.context.session.user) && !this.gotBloglets) {
            this.gotBloglets = true;
            this.getBloglets();
        }
    }

    componentDidUpdate() {
        if (Boolean(this.context.session.user) && !this.gotBloglets) {
            this.gotBloglets = true;
            this.getBloglets();
        }
    }

    getBloglets() {
        $.get({
            url: `${this.context.apiRoot}/bloglets/recipe/${this.props.recipe.id}`
        }).done(result => {
            // Group bloglets by ingredient.
            let groups = {}
            result.bloglets.forEach(bloglet => {
                groups[bloglet.ingredient_id] = groups[bloglet.ingredient_id] || [];
                groups[bloglet.ingredient_id].push(bloglet);
            });
            groups = Object.keys(groups)
                .map(g => result.bloglets.filter(b => b.ingredient_id === Number(g)));
            this.setState({
                bloglets: groups
                    .sort(() => Math.random() - Math.random())
                    .slice(0, 3)
                    .map(g => {
                        // Randomise the group.
                        g.sort(() => Math.random() - Math.random());
                        // Return the first condition tag found, or the first item.
                        return g.find(b => b.bloglet_type === "condition_tag") || g[0];
                    })
            });
        }).catch(err => {
            console.error("Error getting bloglets: ", err);
        });
    }

    renderIngredientQuantityString(i) {
        // Pull out quantities, and account for fractional measures.
        let quantity = i.quantity;
        if (quantity === 0.25) {
            quantity = "1/4"
        } else if (quantity === 0.5) {
            quantity = "1/2"
        } else if (quantity === 0.75) {
            quantity = "3/4"
        } else if (quantity === 0.33) {
            quantity = "1/3"
        }
        // Switch through measurement types.
        switch (i.measurement) {
            case null:
                return `${quantity}${i.quantity < 1 ? " " : " x "}${i.name}`;
            case "l":
            case "ml":
            case "g":
            case "kg":
            case "cm":
                return `${quantity}${i.measurement} ${i.name}`;
            case "clove":
            case "120g tin":
            case "120ml tin":
            case "160g tin":
            case "160ml tin":
            case "400g tin":
            case "400ml tin":
                return `${quantity}${i.quantity < 1 ? " " : " x "}${i.measurement} ${i.name}`;
            default:
                return `${quantity} ${i.measurement} ${i.name}`;
        }
    }

    renderIngredient(ing) {
        let matchedBloglet = this.state.bloglets
            .filter(b => b.ingredient_id === ing.ingredient_id)[0];
        // console.log(matchedBloglet)
        return (
            <React.Fragment key={ing.quantity_id} >
                {
                    ing.ingredient_recipe
                        ?
                        <NavLink
                            to={`/account/recipe/${ing.ingredient_recipe}`}
                            style={{ display: "block" }}
                            className={"ingredient" + (matchedBloglet ? " blogletHandle" : "")}
                        >
                            <img
                                src={ing.icon ? ing.icon : `/static/media/ingredient_icons/${ing.ingredient_id}.svg`}
                                alt={ing.name}
                            />
                            <span
                                onMouseEnter={e => {
                                    if (matchedBloglet) this.setState({
                                        hoveredBloglet: matchedBloglet
                                    });
                                }}
                                onMouseLeave={e => {
                                    if (this.state.hoveredBloglet === matchedBloglet) this.setState({
                                        hoveredBloglet: null
                                    });
                                }}
                            >{this.renderIngredientQuantityString(ing)}</span>
                            {matchedBloglet
                                ?
                                <div className={"bloglet" + (this.state.hoveredBloglet === matchedBloglet ? " active" : "")}>
                                    <div className="blogletType">
                                        {matchedBloglet.bloglet_type}
                                    </div>
                                    {matchedBloglet.bloglet}
                                </div>
                                :
                                null
                            }
                        </NavLink>
                        :
                        <div
                            className={"ingredient" + (matchedBloglet ? " blogletHandle" : "")}
                        >
                            <img
                                src={ing.icon ? ing.icon : `/static/media/ingredient_icons/${ing.ingredient_id}.svg`}
                                alt={ing.name}
                            />
                            <span
                                onMouseEnter={e => {
                                    if (matchedBloglet) this.setState({
                                        hoveredBloglet: matchedBloglet
                                    });
                                }}
                                onMouseLeave={e => {
                                    if (this.state.hoveredBloglet === matchedBloglet) this.setState({
                                        hoveredBloglet: null
                                    });
                                }}
                            >{this.renderIngredientQuantityString(ing)}</span>
                            {matchedBloglet
                                ?
                                <div className={"bloglet" + (this.state.hoveredBloglet === matchedBloglet ? " active" : "")}>
                                    <div className="blogletType">
                                        {matchedBloglet.bloglet_type}
                                    </div>
                                    {matchedBloglet.bloglet}
                                </div>
                                :
                                null
                            }
                            <div className="ingDescription">{ing.quantity_description}</div>
                        </div>
                }
            </React.Fragment>
        )
    }

    renderDesktopIngredients(ingredients) {
        //Splitting the ingredients into two columns for desktop view
        let leftCol = [];
        let rightCol = [];
        let left = true;
        ingredients
            .sort((a, b) => Number(a.order) - Number(b.order))
            .forEach(ing => {
                if (left) {
                    leftCol.push(ing)
                    left = false
                } else {
                    rightCol.push(ing)
                    left = true
                }
            })
        //Returning ingredientsList split into left and right columns.
        return (
            <div className="ingredientsList">
                <div className="colLeft">
                    {leftCol.map(ing => {
                        return this.renderIngredient(ing)
                    })}
                </div>
                <div className="colRight">
                    {rightCol.map(ing => {
                        return this.renderIngredient(ing)
                    })}
                </div>
            </div>
        )
    }

    renderMethod(method) {
        return (
            <div className="method">
                {method.map((methodText, i) => {
                    return (
                        <p className="step" key={i}>
                            <span>{i + 1}.</span>
                            {methodText}
                        </p>
                    )
                })}
            </div>
        )
    }

    desktopView() {
        let recipe = this.props.recipe;
        return (
            <div className="recipeContent desktop" ref="recipe">
                <div className="logoForPrint">
                    <img src={LogoForPrint} alt="ryg logo" />
                </div>
                <div className="left">
                    <div
                        className="mealImage"
                        style={{
                            backgroundImage: (recipe.images && recipe.images[0]) ?
                                `url("${recipe.images[0]}")` :
                                `url("/static/media/meals/${recipe.name}.jpg")`
                        }}
                    />
                    <p className="sectionName">
                        INGREDIENTS
                        <img className="icon" src={ingredientsIcon} alt="ingredients icon" />
                    </p>
                    {this.renderDesktopIngredients(recipe.ingredients)}
                </div>
                <div className="right">
                    <h1 className="recipeName">{recipe.name}</h1>
                    {recipe.description
                        ?
                        <p className="description">{recipe.description}</p>
                        :
                        null
                    }
                    {recipe.preptime && recipe.cooktime
                        ?
                        <div className="prepCook">
                            <p>PREP: <span>{recipe.preptime}</span></p>
                            <p>COOK: <span>{recipe.cooktime}</span></p>
                        </div>
                        :
                        null
                    }
                    <p className="sectionName">
                        METHOD
                        <img className="icon" src={method} alt="ingredients icon" />
                    </p>
                    {this.renderMethod(recipe.method)}
                </div>
                <div className="buttonIcons">
                    <img
                        className="downloadBtn iconBtn"
                        src={print}
                        alt="print button"
                        onClick={() => {
                            window.print()
                        }}
                    />
                    {/* <img
                        className="printBtn iconBtn"
                        src={download}
                        alt="download button"
                        onClick={() => {
                            console.log("DOWNLOADING")
                        }}
                    /> */}
                </div>
            </div>
        )
    }

    mobileView() {
        let recipe = this.props.recipe;
        let mode = this.state.mode;
        return (
            <div className="recipeContent mobile" ref="recipe">
                <div
                    className="mealImage"
                    style={{
                        backgroundImage: (recipe.images && recipe.images[0]) ?
                            `url("${recipe.images[0]}")` :
                            `url("/static/media/meals/${recipe.name}.jpg")`
                    }}
                />
                <h1 className="recipeName">{recipe.name}</h1>
                {recipe.description
                    ?
                    <p className="description">{recipe.description}</p>
                    :
                    null
                }
                {recipe.preptime && recipe.cooktime
                    ?
                    <div className="prepCook">
                        <p>PREP: <span>{recipe.preptime}</span></p>
                        <p>COOK: <span>{recipe.cooktime}</span></p>
                    </div>
                    :
                    null
                }
                <div className="modeSelector">
                    <div
                        className={mode === "ingredients" ? "ingredientsMode active" : "ingredientsMode"}
                        onClick={() => {
                            if (mode !== "ingredients") {
                                this.setState({ mode: "ingredients" })
                            }
                        }}
                    >
                        <span>INGREDIENTS</span>
                        <img src={ingredientsIcon} alt="ingredients icon" />
                    </div>
                    <div
                        className={mode === "method" ? "methodMode active" : "methodMode"}
                        onClick={() => {
                            if (mode !== "method") {
                                this.setState({ mode: "method" })
                            }
                        }}
                    >
                        <span>METHOD</span>
                        <img src={method} alt="method icon" />
                    </div>
                </div>
                {mode === "method"
                    ?
                    this.renderMethod(recipe.method)
                    :
                    <div className="ingredientsList">
                        {recipe.ingredients
                            .sort((a, b) => Number(a.order) - Number(b.order))
                            .map(ing => {
                                return this.renderIngredient(ing)
                            })}
                    </div>
                }
                <div className="buttonIcons">
                    <img
                        className="downloadBtn iconBtn"
                        src={print}
                        alt="print button"
                        onClick={() => {
                            window.print()
                        }}
                    />
                    {/* <img
                        className="printBtn iconBtn"
                        src={download}
                        alt="download button"
                        onClick={() => {
                            console.log("DOWNLOADING")
                        }}
                    /> */}
                </div>
            </div>
        )
    }

    render() {
        return (
            this.context.mode === "mobile"
                ?
                this.mobileView()
                :
                this.desktopView()
        )
    }
}

Recipe.contextType = Context;

export default Recipe;