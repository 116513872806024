/**
 *  Condition item structure
 * 
 *      {
            description: "string"
            id: 1
            name: "string"
 *      }       
 */

import React from "react";
import ItemCreator from "../itemCreator";
import DemographicList from "./demographicList";
import TextField from "../../../../fields/textField";
import TextareaField from "../../../../fields/textareaField";
import DropdownField from "../../../../fields/dropdownField";
import RichTextField from "../../../../fields/richTextField";
import CheckBoxField from "../../../../fields/checkboxField";
import TagManager from "../../tagManager";
import $ from "jquery";
import Context from "../../../../context";

class ConditionCreator extends ItemCreator {

    canSubmit() {
        if (this.props.item) {
            let canSubmit = false;
            Object.keys(this.state.changes).forEach(key => {
                if ((key && this.state.changes[key]) || (this.state.item[key] !== this.props.item[key])) {
                    canSubmit = true;
                }
            })
            if (canSubmit) return true;
        } else {
            if (this.state.item.name) {
                return true;
            }
        }
        //Return false by default.
        return false;
    }

    getTags(id) {
        $.get(`${this.context.apiRoot}/conditions/${id}/tags`).done(res => {
            this.setState({ tags: res.tags });
        }).catch(err => {
            console.error(err);
        });
    }

    renderTagManager(item) {
        // Only showing tags when editing an item
        return (
            <TagManager
                onChange={() => {
                    if (this.getTags) this.getTags(item.id);
                }}
                additionalFields={[
                    "is_positive"
                ]}
                tagType="conditions"
                item={item}
                tags={this.state.tags}
            />
        )
    }

    renderCreator() {
        return (
            <div className="creatorFields">
                <TextField
                    label="Name"
                    change={(err, val) => {
                        this.itemChanged("name", val)
                    }}
                >
                    {this.state.item.name}
                </TextField>
                <TextareaField
                    label="Description"
                    change={(err, val) => {
                        this.itemChanged("description", val)
                    }}
                >
                    {this.state.item.description}
                </TextareaField>
                <RichTextField
                    label="How RYG Helps"
                    change={(err, val) => {
                        this.itemChanged("howRygHelps", val)
                    }}
                >
                    {this.state.item.howRygHelps}
                </RichTextField>
                <br />
                {this.state.item.category === "advanced"
                    ?
                    <>
                        <DemographicList
                            items={this.state.item.demographics || []}
                            showing={this.props.item ? true : false}
                            change={val => {
                                this.itemChanged("demographics", val)
                            }}
                        />
                        <br />
                    </>
                    :
                    null
                }
                <DropdownField
                    label="Category"
                    options={[
                        "advanced",
                        "diet",
                        "health",
                        "exclusion"
                    ]}
                    change={(err, val) => {
                        this.itemChanged("category", val)
                    }}
                >
                    {this.state.item.category}
                </DropdownField>
                <div className="checkBoxWrapper">
                    <CheckBoxField
                        label="Suspended"
                        change={(err, val) => {
                            this.itemChanged("suspended", val)
                        }}
                    >
                        {this.state.item.suspended}
                    </CheckBoxField>
                </div>
            </div>
        )
    }
}

ConditionCreator.contextType = Context;

export default ConditionCreator;