import React from "react";
import Field from "./field";
import Context from "../context";
import $ from "jquery";

class ItemFinderField extends Field {

    constructor(props) {
        super(props)
        this.settings = {
            type: "itemFinderField"
        }

        this.state.items = [];
        this.state.query = "";
        this.timer = null;
    }

    getItems(query) {
        if (!query) {
            this.setState({
                items: []
            });
        } else {
            $.get(`${this.context.apiRoot}/${this.props.itemName}?name=${query}`).then(res => {
                this.setState({
                    items: res.results
                })
            }).catch(err => {
                console.error("Couldn't get items: ", err);
            });
        }
    }

    renderField() {
        return (
            <React.Fragment>
                <input
                    placeholder={this.props.placeholder || "Search for "+this.props.itemName+"..."}
                    type="text"
                    value={this.state.query}
                    onChange={e => {
                        // Extract value.
                        let query = e.target.value;
                        this.setState({ query });
                        // If there is a timer, clear it.
                        if (this.timer) clearTimeout(this.timer);
                        // Set a timer to query for items.
                        this.timer = setTimeout(() => {
                            this.getItems(query);
                        }, 250);
                    }}
                />
                <div className="itemOptions">
                    {this.state.items
                        .filter(item => this.props.filter ? this.props.filter(item) : true)
                        .map(item => (
                        <div 
                            className="option" 
                            key={item.id}
                            onClick={e => {
                                this.setState({
                                    query: "",
                                    items: []
                                }, () => {
                                    this.change(item);
                                })
                            }}
                        >
                            {item.name}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }

}

ItemFinderField.contextType = Context;

export default ItemFinderField;

