import $ from "jquery";
import React from "react";
import ItemCreator from "./itemCreator";
import CheckboxField from "../../../fields/checkboxField";
import FlipMove from "react-flip-move";
import Context from "../../../context";

class UserCreator extends ItemCreator {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            conditions: [],
            recipes: [],
            mealPlan: [],
            paid: 0
        }
    }


    componentDidMount() {
        super.componentDidMount();
        this.getConditions();
        this.getMealPlan();
    }

    getConditions() {
        $.get(`${this.context.apiRoot}/conditions`).done(res => {
            this.setState({ conditions: res.results })
        }).catch(err => {
            console.error("Error getting conditions:", err)
        })
    }

    getRecipesForMealPlan(ids) {
        $.post({
            url: `${this.context.apiRoot}/recipes/retrieve`,
            data: JSON.stringify(ids),
            contentType: "application/json"
        }).done(response => {
            this.setState({ recipes: response.recipes });
        }).catch(err => {
            console.error("Error getting recipes for a meal plan")
        });
    }

    getRecipeWithId(id) {
        // console.log(this.state.recipes)
        return this.state.recipes
            .filter(r => r.id === id)[0] || null;
    }

    getMealPlan() {
        $.get(`${this.context.apiRoot}/meal-plans/${this.props.item.uname}`).then(res => {
            // if meal plan exists get its recipes
            if (res.mealPlan && res.mealPlan.plan.length) {
                this.setState({ mealPlan: res.mealPlan.plan, paid: res.mealPlan.paid });
                // Flattening the mealPlan recipes array and removing duplicate id's
                let recipes = [...new Set([].concat.apply([], res.mealPlan.plan))].filter(id => id);
                this.getRecipesForMealPlan(recipes)
            }
        }).catch(err => {
            console.error("Error getting meal plan", err);
        })
    }

    renderMealPlan() {
        //Splitting meal plan into weeks
        let week1 = this.state.mealPlan.slice(0, 7);
        let week2 = this.state.mealPlan.slice(7, 14);
        let week3 = this.state.mealPlan.slice(14, 21);
        let week4 = this.state.mealPlan.slice(21, 28);
        return (
            <div className="mealPlan">
                {/* Iterate weeks */}
                {[week1, week2, week3, week4].map((weekDays, weekIndex) => (
                    <div className={"container week" + weekIndex + 1} key={weekIndex}>
                        <h2>Week {weekIndex + 1}</h2>
                        {weekDays.map((day, weekDayIndex) => {
                            return <div className="day row shadow2" key={`${weekIndex}_${weekDayIndex}`}>
                                <div className="dayName">{weekDayIndex + (weekIndex * 7) + 1}</div>
                                {day.map((recipe_id, mealIndex) => {
                                    let recipe = this.getRecipeWithId(recipe_id);
                                    return recipe
                                        ?
                                        <div className="meal-plan-recipe col-12 col-md-4" key={`${mealIndex}_${weekDayIndex}_${weekIndex}`}>
                                            <div className="recipe-inner">
                                                <div
                                                    className="mealImage"
                                                    style={{
                                                        backgroundImage: (recipe.images && recipe.images[0]) ?
                                                            `url("${recipe.images[0]}")` :
                                                            `url("/static/media/meals/${recipe.name}.jpg")`
                                                    }}
                                                />
                                                <div className="name">
                                                    {recipe.name}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="meal-plan-recipe col-12 col-md-4" key={`${mealIndex}_${weekDayIndex}_${weekIndex}`}>
                                            <div className="recipe-inner">
                                                <div
                                                    className="mealImage"
                                                    style={{
                                                        backgroundImage: 'url("/static/media/meals/Vegan Curried Vegetables.jpg")',
                                                        opacity: "0.3"
                                                    }}
                                                />
                                                <div className="name">
                                                    NO RECIPE
                                                </div>
                                            </div>
                                        </div>

                                })}
                            </div>
                        })}
                    </div>
                ))}
            </div>
        )
    }

    renderCreator() {
        return (
            <div className="creatorFields" style={{ textAlign: "left" }}>
                <CheckboxField
                    label="User paid"
                    change={(err, val) => {
                        if (window.confirm(`Are you sure you want to mark this plan as ${val ? "paid" : "unpaid"}?`)) {
                            let paid = val ? 1 : 0;
                            this.setState({ paid })
                            $.ajax({
                                type: "PUT",
                                url: `${this.context.apiRoot}/meal-plans/${this.props.item.uname}`,
                                contentType: "application/json",
                                data: JSON.stringify({ paid })
                            }).catch(err => {
                                console.error(err)
                            })
                        }
                    }}
                >
                    {this.state.paid}
                </CheckboxField>
                <FlipMove
                    className="conditions"
                    enterAnimation="fade"
                    leaveAnimation="fade"
                >
                    <label>User conditions.</label>
                    {/* {this.renderConditions()} */}
                    {this.state.conditions.map(condition => {

                        let alreadySelected = this.state.item.conditions.includes(condition.id);

                        return <div
                            key={condition.id}
                            className={alreadySelected ? "condition selected" : "condition"}
                            onClick={e => {

                                let item = {
                                    ...this.state.item,
                                    conditions: alreadySelected
                                        ?
                                        this.state.item.conditions.filter(c => c !== condition.id)
                                        :
                                        [...this.state.item.conditions, condition.id]
                                }

                                this.setState({ item });
                            }}
                        >
                            {condition.name}
                        </div>
                    })}
                </FlipMove>
                <br />
                <div
                    style={{
                        display: "block",
                        textAlign: "center"
                    }}
                    className="button"
                    onClick={e => {
                        // Ask for confirmation before generating a new plan
                        if (window.confirm("Are you sure you want to generate new meal plan? This will permanently replace the existing one.")) {
                            $.ajax({
                                type: "POST",
                                url: `${this.context.apiRoot}/meal-plans/${this.props.item.uname}/regenerate`,
                                data: JSON.stringify({ conditions: this.state.item.conditions }),
                                contentType: "application/json"
                            }).then(res => {
                                this.setState({ mealPlan: res.plan });
                                // Getting new recipes
                                let recipes = [...new Set([].concat.apply([], res.plan))].filter(id => id);
                                this.getRecipesForMealPlan(recipes)
                                // Sending updates to the parent
                                this.props.updateUser(this.state.item)
                            }).catch(err => {
                                console.error(err)
                            })
                        }
                    }}
                >
                    Generate New Meal Plan
                </div>
                {this.state.recipes.length
                    ?
                    <div className="mealPlan">
                        {this.renderMealPlan()}
                    </div>
                    :
                    null
                }
            </div>
        )
    }

}

UserCreator.contextType = Context;

export default UserCreator;