import React, { useState, useEffect, useContext } from 'react';
import Context from '../context';

const SuccessAlert = ({ message }) => {
  const [show, setShow] = useState(true);
  const { setResNewMealPlan} = useContext(Context);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setResNewMealPlan({resNewMealPlan: false})
      setShow(false)
    }, 4000); // Change the value of 4000 to the time in milliseconds you want the alert to last
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={`alert alert-success ${show ? 'd-block' : 'd-none'}`} role="alert" style={{ marginTop: '1em' }}>
      {message}
    </div>
  );
};

export default SuccessAlert;
