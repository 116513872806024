import React from "react";
import Field from "./field";

class TextareaField extends Field {
    constructor(props) {
        super(props)
        this.settings = {
            type: "textareaField"    
        }
    }

    renderField() {
        return (
            <textarea
                placeholder={this.props.placeholder}
                value={this.props.children || ""}
                onChange={e => {
                    this.change(e.target.value);
                }}
            >
            </textarea>
        )
    }
}

export default TextareaField;