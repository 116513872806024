import React from "react";

class Field extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null
        }
        this.settings = { type: "noType" }
    }

    renderField() {
        return "Overwrite me!"
    }

    change(value) {
        let error = null;
        if (this.props.validator) {
            error = this.props.validator(value);
            this.setState({ error });
        }
        if (this.props.change) {
            this.props.change(
                error,
                this.props.preProcess ? this.props.preProcess(value) : value
            )
        }
    }

    render() {
        let settings = this.settings;
        let classes = [
            "field",
            settings.type,
            typeof this.state.error === "string" ? "bad" : "good"
        ];
        return (
            <div className={classes.join(" ")}>
                {this.props.label
                    ?
                    <label>
                        {this.props.label}
                    </label>
                    :
                    null
                }
                <div className="inner">
                    {this.renderField()}
                </div>
                <div className="field-errors">
                    <div key="err" style={{
                        height: typeof this.state.error === "string" ? 25 : 0
                    }}>
                        {this.state.error}
                    </div>
                </div>
            </div>
        )
    }

}

export default Field;