import React from "react";
import TextareaField from "../../fields/textareaField";
import ItemFinderField from "../../fields/itemFinderField";
import CheckboxField from "../../fields/checkboxField";
import Context from "../../context";
import FlipMove from "react-flip-move";
import $ from "jquery";

//Media import
import closeIcon from "../../media/icon-close.svg";

class TagManager extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            editing: null,
            // Temporarily holds onto a tag object, so that any description etc.
            // can be added before sending.
            adding: null,
            linkDescription: "",
            is_positive: 0
        }
    }

    removeTag(tagId) {
        $.ajax({
            method: "DELETE",
            url: `${this.context.apiRoot}/${this.props.tagType}/${this.props.item.id}/tags/${tagId}`
        }).done(res => {
            this.props.onChange();
        }).catch(err => {
            console.error(err)
        })
    }

    updateTag(tagId, data) {
        $.ajax({
            method: "PUT",
            url: `${this.context.apiRoot}/${this.props.tagType}/${this.props.item.id}/tags/${tagId}`,
            data: JSON.stringify(data),
            contentType: "application/json"
        }).done(res => {
            this.props.onChange();
            this.setState({ editing: null })
        }).catch(err => {
            console.error(err);
        });
    }

    render() {
        return (
            <div className="tagManager">
                <div className="currentTags">
                    {this.props.tags.length
                        ?
                        <label>Current tags</label>
                        :
                        null
                    }
                    {this.props.tags.map((item, index) => (
                        (this.state.editing && this.state.editing.tag_id === item.tag_id)
                            ?
                            <TagEditor
                                key={index}
                                item={item}
                                onSave={values => {
                                    this.updateTag(item.tag_id, values);
                                }}
                                onCancel={() => {
                                    this.setState({
                                        editing: null
                                    });
                                }}
                            />
                            :
                            <div
                                className="item tagItem"
                                key={index}
                                onClick={e => {
                                    if (e.target.className.includes("removeTag"))
                                        return null;
                                    this.setState({ editing: item, adding: null })
                                }}
                            >
                                {item.tag_name.toUpperCase()} {
                                    item.is_positive !== undefined ?
                                        <i>
                                            - {item.is_positive ? "Desired" : "Excluded"}
                                        </i>
                                        :
                                        null
                                }
                                <div className="options">
                                    <img
                                        className="removeTag"
                                        src={closeIcon}
                                        alt="remove icon"
                                        onClick={() => {
                                            if (window.confirm("Are you sure you want to remove this tag?"))
                                                this.removeTag(item.tag_id);
                                        }}
                                    />
                                </div>
                            </div>
                    ))}
                </div>
                <div className="addTag">
                    <label>Add a new tag.</label>
                    {this.state.adding
                        ?
                        <div className="tagEditing" key={this.state.adding.id}>
                            <div>Adding a <b>{this.state.adding.name}</b> tag.</div>
                            <div>Provide an optional bloglet description:</div>
                            <br />
                            <div className="fields">
                                <TextareaField
                                    placeholder="description..."
                                    change={(err, linkDescription) => {
                                        this.setState({ linkDescription });
                                    }}
                                >
                                    {this.state.linkDescription}
                                </TextareaField>
                                {this.props.additionalFields && this.props.additionalFields.includes("is_positive") ?
                                    <CheckboxField
                                        label="Is positive"
                                        change={(err, val) => {
                                            this.setState({ is_positive: Number(val) })
                                        }}
                                    >
                                        {Boolean(this.state.is_positive)}
                                    </CheckboxField>
                                    :
                                    null
                                }
                            </div>
                            <div className="controls">
                                <div
                                    className="button"
                                    onClick={e => {
                                        let data = {
                                            tag: this.state.adding.id,
                                            description: this.state.linkDescription
                                        };
                                        if (this.props.additionalFields && this.props.additionalFields.includes("is_positive")) data.is_positive = this.state.is_positive;
                                        $.post({
                                            url: `${this.context.apiRoot}/${this.props.tagType}/${this.props.item.id}/tags`,
                                            data: JSON.stringify(data),
                                            contentType: "application/json"
                                        }).done(res => {
                                            if (this.props.onChange) this.props.onChange();
                                            this.setState({
                                                adding: null,
                                                linkDescription: "",
                                                is_positive: 0
                                            });
                                        }).catch(err => {
                                            console.error("Error adding tag: ", err);
                                        });
                                    }}
                                >Save</div>
                                <div
                                    className="button cancelEdit"
                                    onClick={e => {
                                        this.setState({
                                            adding: null
                                        })
                                    }}
                                >Cancel</div>
                            </div>
                        </div>
                        :
                        <ItemFinderField
                            itemName="tags"
                            change={(err, tag) => {
                                // tagId is the new tag to add.
                                this.setState({
                                    adding: tag,
                                    editing: null
                                })
                            }}
                        ></ItemFinderField>}
                </div>
            </div>
        )
    }
}

class TagEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description: this.props.item.link_description,
            is_positive: this.props.item.is_positive,
        }
    }
    canSubmit() {
        if (
            this.state.description !== this.props.item.link_description ||
            this.state.is_positive !== this.props.item.is_positive
        ) return true;
        return false;
    }
    render() {
        let item = this.props.item;
        return (
            <div className="tagEditor" key={"tagEditor" + item.name + item.id}>
                <div className="heading">{item.tag_name}</div>
                <TextareaField
                    label="Description"
                    change={(err, val) => {
                        this.setState({ description: val })
                    }}
                >
                    {this.state.description}
                </TextareaField>
                {item.is_positive !== undefined
                    ?
                    <CheckboxField
                        label="Is positive"
                        change={(err, val) => {
                            this.setState({ is_positive: Number(val) })
                        }}
                    >
                        {Boolean(this.state.is_positive)}
                    </CheckboxField>
                    : null
                }
                <FlipMove
                    className="controls"
                    duration={200}
                    enterAnimation="fade"
                    leaveAnimation="fade"
                >
                    {this.canSubmit() ? <div
                        key={"edit"}
                        className="button editTagBtn"
                        onClick={() => {
                            let data = { description: this.state.description };
                            if (item.is_positive !== undefined)
                                data.is_positive = this.state.is_positive
                            this.props.onSave(data);
                        }}
                    >
                        Save
                    </div> : null}
                    <div
                        key={"cancel"}
                        className="button cancelEdit"
                        onClick={() => {
                            this.props.onCancel();
                        }}
                    >
                        Cancel
                    </div>
                </FlipMove>
            </div>
        )
    }
}

TagManager.contextType = Context;

export default TagManager;