import React from "react";
import Tab from "./tab";
import PartnerCreator from "../creators/partnerCreator";

class Partner extends Tab {
    constructor(props, context) {
        super(props, context);
        this.displayName = "name"
    }
    
    itemCreator(item) {
        return (
            <PartnerCreator
                key={"partnerCreator" + (item ? item.id : "new")}
                displayName={this.displayName}
                item={item}
                itemUpdate={(changes, id) => {
                    this.editItem(changes, id)
                }}
                itemCreate={item => {
                    this.createItem(item)
                }}
                itemDelete={id => {
                    this.deleteItem(id)
                }}
                close={() => {
                    this.setState({ adding: false, selected: null })
                }}
            /> 
        ) 
    }

}

export default Partner;