import React from "react";
import FlipMove from "react-flip-move";
import Field from "./field";

import tickIcon from "../media/tick-white.svg";

class CheckboxField extends Field {
    
    constructor(props) {
        super(props)
        this.settings = {
            type: "checkboxField"
        }
    }

    renderField() {
        return (
            <div 
                className={this.props.children ? "box on" : "box"}
                onClick={() => {
                    this.change(!this.props.children);     
                }}
            >
                <img
                    alt="tick icon"
                    className={this.props.children ? "tick show" : "tick"} 
                    src={tickIcon} 
                />
            </div>
        )
    }

    render() {
        let settings = this.settings;
        return (
            <div className={`field ${settings.type}`}>
                {this.props.label
                    ?
                    <label
                        onClick={() => {
                            this.change(!this.props.children);     
                        }}
                    >
                        {this.props.label}
                    </label>   
                    :
                    null
                }
                <div className="inner">
                    {this.renderField()}
                </div>
                <FlipMove>
                    {this.state.error
                        ?
                        <div key="err">
                            {this.state.error}
                        </div>
                        :
                        null
                    }
                </FlipMove>
            </div>
        )
    }

}

export default CheckboxField;