import React from "react";
import Field from "./field";

class TextField extends Field {
    constructor(props) {
        super(props)
        this.settings = {
            type: "textField"
        }
    }

    renderField() {
        return (
            <div>
                <input
                    placeholder={this.props.placeholder}
                    type="text"
                    value={this.props.children || ""}
                    onChange={e => {
                        this.change(e.target.value)
                    }}
                    style={this.props.icon ? {
                        paddingRight: 50
                    } : {}}
                />
                {this.props.icon ?
                    <div className="icon" style={{
                        backgroundImage: `url("${this.props.icon}")`,
                        position: "absolute",
                        right: 20,
                        top: 10,
                        height: 25,
                        width: 25,
                        backgroundSize: "contain",
                    }} />
                    : null
                }
            </div>
        )
    }

}

export default TextField;

