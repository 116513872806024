import React from "react";
import Field from "./field";
import CodeMirror from "codemirror";
require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');
require("codemirror/mode/htmlmixed/htmlmixed");

class HtmlField extends Field {
    constructor(props) {
        super(props);
        this.cm = null;
        this.settings = {
            type: "htmlField"
        }
    }

    componentDidMount() {
        this.createCodeMirror()
    }

    componentDidUpdate(pp) {            
        if (this.props.children && this.props.children !== this.cm.getValue())
            this.cm.setValue(this.props.children);
    }

    createCodeMirror() {
        this.cm = CodeMirror(this.refs.codeArea, {
            mode: "text/html",
            lineNumbers: true,
            // lineWrapping: this.props.lineWrapping !== undefined ? this.props.lineWrapping : false
        });
        this.cm.setValue(this.props.children);
        this.cm.on("change", () => {
            this.change(this.cm.getValue());
        });
    }

    renderField() {
        return (
            <div ref="codeArea" />
        )
    }
}

export default HtmlField;