import React from "react";
import Field from "./field";
import Context from "../context";

class PaymentField extends Field {
  constructor(props, context) {
    super(props, context);
    this.settings = {
      type: "paymentField"
    };
    this.state = {
      canSubmit: false,
      recentlyPressed: false
    };
    this.id = "card-field-" + Math.floor(Math.random() * 10000000);
    this.elements = context.stripe.elements();
    this.cardElement = this.elements.create("card");
  }

  componentDidMount() {
    this.cardElement.mount(`#${this.id}`);
    this.cardElement.addEventListener("change", this.cardChange.bind(this));
  }

  componentDidUpdate() {
    if (this.state.recentlyPressed) {
      if (this.clearRecentlyPressed) clearTimeout(this.clearRecentlyPressed);
      this.clearRecentlyPressed = setTimeout(() => {
        this.setState({
          recentlyPressed: false
        });
      }, 1500);
    }
  }

  componentWillUnmount() {
    if (this.clearRecentlyPressed) clearTimeout(this.clearRecentlyPressed);
  }

  cardChange = (e) => {
    this.setState({
      canSubmit: e.complete
    });
  };

  submit = async () => {
    const result = await this.context.stripe.createPaymentMethod("card", this.cardElement);
    if (!this.props.onMethod) return;
    if (result.error) {
      this.props.onMethod(result.error, null);
    } else {
      this.props.onMethod(null, result.paymentMethod.id);
    }
  };

  renderField() {
    const { disabled, price } = this.props;
    const { canSubmit, recentlyPressed } = this.state;
    const disabledClass = disabled || !canSubmit || recentlyPressed ? "disabled" : "enabled";

    return (
      <>
        <div id={this.id} />
        <button
          className={disabledClass}
          disabled={disabled || !canSubmit || recentlyPressed}
          onClick={(e) => {
            if (recentlyPressed) return;
            this.setState({ recentlyPressed: true });
            if (!disabled && canSubmit) this.submit();
          }}
        >
          {price ? "£" + price + " - " : null}GO!
        </button>
      </>
    );
  }
}

PaymentField.contextType = Context;

export default PaymentField;


