import $ from "jquery";
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie9';
import 'core-js';
import "./polyfills";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import Context from "./context";
import { Helmet } from 'react-helmet';
import moment from "moment";
import { setCookie, getCookies } from "./helpers";
// Css imports
import "./styles/bootstrap.min.css";
import "./styles/style.scss";
// Media
import ogImage from "./media/generic-og.jpg";
import logoutIcon from "./media/icon-logout.svg";
// Component imports
import Navigation from "./assets/navigation.js";
import Footer from './assets/footer';
// Page imports
import ContactPage from "./pages/contact";
import RegisterPage from "./pages/register";
import LoginPage from "./pages/login";
import AccountPage from './pages/account';
import RecipePage from './pages/recipe';
import AdminPage from "./pages/admin/admin";
import UpgradePage from "./pages/upgrade";
import RestartPlan from "./pages/restartPlan";
//Useful Information pages
import SupplementAdvice from "./pages/usefulInfo/supplementAdvice";
import Overview from "./pages/usefulInfo/overview";
import DirtyDozen from "./pages/usefulInfo/dirtyDozen";
import DoDont from "./pages/usefulInfo/doDont";
import GutBacteria from "./pages/usefulInfo/gutBacteria";
import WeeklyGuidelines from "./pages/usefulInfo/weeklyGuidelines";
import Programme from "./pages/usefulInfo/programme";
import TopTips from "./pages/usefulInfo/topTips";
import Snacks from "./pages/usefulInfo/snacks";
import Rebalancing from "./pages/usefulInfo/rebalancing";

// console.log("ENV VER: ", process.env.REACT_APP_ENV_VER);

// Media imports
class App extends React.Component {
    constructor(props) {
        super(props);
        this.query = this.parseQuery(window.location.search);
        this.cookie_name = "ryh_token";
        this.state = {
            mode: (() => {
                let width = $(window).outerWidth();
                let mode = "mobile";
                if (width >= 992) {
                    mode = "desktop"
                } else if (width >= 768) {
                    mode = "tablet"
                }
                return mode
            })(),
            apiRoot: process.env.REACT_APP_API_ROOT,
            settings: [],
            session: {
                user: null,
                token: getCookies()[this.cookie_name] || null, // initialise from cookie?
                ready: Boolean(getCookies()[this.cookie_name]) ? false : true
            },
            resNewMealPlan: null,
        };
        this.gotSettings = false;
        this.stripe = window.Stripe ? window.Stripe(process.env.REACT_APP_STRIPE_PK) : {};
        this.resizeHandler = null;
        // Ajax setup with headers.
        $.ajaxSetup({
            beforeSend: xhr => {
                if (this.state.session.token) xhr.setRequestHeader(
                    "authorization", this.state.session.token
                );
            }
        });
    }

    parseQuery(qs) {
        let result = {}
        qs.replace("?", "").split("&").forEach(pair => {
            let items = pair.split("=");
            if (items[0] && items[1])
                result[items[0]] = items[1];
        });
        return result;
    }

    generateNavLinks() {
        let userLogged = Boolean(this.state.session.user);
        let links = [
            {
                name: "HOME",
                path: "https://resetyourhealth.com/",
                type: "external"
            },
            {
                name: "HOW RYH WORKS",
                path: "https://resetyourhealth.com/how-ryh-works/",
                type: "external"
            },
            {
                name: "RYH FOR...",
                type: "dropdown",
                position: "center",
                links: [
                    {
                        name: "GENERAL HEALTH",
                        path: "https://resetyourhealth.com/ryh-for/general-health/",
                        type: "external",
                        width: "350px"
                    },
                    {
                        name: "GUT HEALTH",
                        path: "https://resetyourhealth.com/ryh-for/gut-health/",
                        type: "external",
                        width: "350px"
                    },
                    {
                        name: "HEART DISEASE, HIGH CHOLESTEROL, HIGH BLOOD PRESSURE",
                        path: "https://resetyourhealth.com/ryh-for/high-cholesterol/",
                        type: "external",
                        width: "350px"
                    },
                    {
                        name: "MENOPAUSE AND HORMONAL SYMPTOMS",
                        path: "https://resetyourhealth.com/ryh-for/menopause/",
                        type: "external",
                        width: "350px"
                    },
                    {
                        name: "MENTAL HEALTH",
                        path: "https://resetyourhealth.com/ryh-for/mental-health/",
                        type: "external",
                        width: "350px"
                    },
                    {
                        name: "TYPE 2 DIABETES",
                        path: "https://resetyourhealth.com/ryh-for/type-2-diabetes/",
                        type: "external",
                        width: "350px"
                    },
                    {
                        name: "WEIGHT CONCERNS",
                        path: "https://resetyourhealth.com/ryh-for/weight-concerns/",
                        type: "external",
                        width: "350px"
                    },
                    {
                        name: "SOMETHING ELSE?",
                        path: "https://resetyourhealth.com/ryh-for/something-else/",
                        type: "external",
                        width: "350px"
                    }
                ]
            },
            {
                name: "FAQ",
                path: "https://resetyourhealth.com/faq/",
                type: "external"
            },
            {
                name: "BLOG",
                path: "https://resetyourhealth.com/blog/",
                type: "external"
            },
            {
                name: "ABOUT",
                type: "dropdown",
                position: "center",
                links: [
                    // {
                    //     name: "THE BOOK",
                    //     path: "/book",
                    //     type: "link"
                    // },
                    {
                        name: "PRESS",
                        path: "https://resetyourhealth.com/about/press/",
                        type: "external"
                    },
                    {
                        name: "ABOUT RYH",
                        path: "https://resetyourhealth.com/about/ryh/",
                        type: "external"
                    },
                    {
                        name: "THE TEAM",
                        path: "https://resetyourhealth.com/about/team/",
                        type: "external"
                    }
                ]
            },
            {
                name: "CONTACT",
                path: "/contact",
                type: "link"
            },
            userLogged
                ?
                {
                    name: "MY PLAN",
                    path: "/account",
                    type: "link"
                }
                :
                {
                    name: "LOG IN",
                    path: "/login",
                    type: "link"
                },
            userLogged
                ?
                {
                    name: "LOG OUT",
                    type: "link",
                    path: "/",
                    icon: logoutIcon,
                    handler: e => {
                        $.ajax({
                            type: "POST",
                            url: `${this.state.apiRoot}/auth/logout`
                        }).done(res => {
                            this.setSession({ user: null, token: null });
                        }).catch(err => {
                            console.error("Error logging out: ", err);   
                        })
                    }
                }
                :
                {
                    name: "REGISTER",
                    path: "/register",
                    type: "link"
                }

        ].filter(link => link);

        // Adding admin link for users with correct permissions
        if (Boolean(this.state.session.user) && this.state.session.user.isAdmin) {
            links.unshift({
                name: "ADMIN",
                path: "/admin",
                type: "link"
            });
        }
        
        return links;
    }
    
    setSession(sessionData) {
        // If a new token is passed, also add it to the cookie.
        if (sessionData.token) {
            // set in cookie.
            setCookie({
                key: this.cookie_name,
                value: sessionData.token,
				path: "/",
				expires: moment().add(1, "month")
            })
        } else if (sessionData.token === null) {
            // Clean out cookie.
            setCookie({
                key: this.cookie_name,
                path: "/",
                value: ""    
            });
        }
        this.setState({
            session: {
                ...this.state.session,
                ...sessionData,
                ready: true
            }
        }, () => {
        });
    }

    setResNewMealPlan(res) {
        this.setState(res);
    }

    getSettings() {
        this.gotSettings = true;
        $.ajax({
            type: "GET",
            url: `${this.state.apiRoot}/settings`
        }).done(res => {
            // console.log("SETTINGS:", res.settings);
            this.setState({ settings: res.settings });
        }).catch(err => {
            console.error("Error getting settings: ", err);
        });
    }

    requestCurrentUser() {
        $.ajax({
            type: "GET",
            url: `${this.state.apiRoot}/auth/me`
        }).done(res => {
            this.setSession({ user: res.user });
        }).catch(err => {
			console.error("Error getting current user: ", err);
			this.setSession({ user: null });
			setCookie({
				key: this.cookie_name,
				value: "",
				path: "/"
			});
        });
    }

    componentDidMount() {
        if (!this.gotSettings) this.getSettings();

        if (this.state.session.token) this.requestCurrentUser();

        //Resize handler which check window width and changes the state.mode accordingly.
        this.resizeHandler = $(window).on("resize", () => {
            let width = $(window).outerWidth();
            if (width >= 992) {
                if (this.state.mode !== "desktop")
                    this.setState({ mode: "desktop" });
            } else if (width >= 768 && this.state.mode !== "tablet") {
                this.setState({ mode: "tablet" });
            } else if (width < 768 && this.state.mode !== "mobile") {
                this.setState({ mode: "mobile" });
            }
        });
    }

    componentDidUpdate() {
        // Getting settings
        if (!this.gotSettings) this.getSettings();
    }

    componentWillUnmount() {
        // Clearing any bound events.
        $(window).off("resize", this.resizeHandler);
    }

    render() {

        if (!this.state.session.ready) return null

        return (
            <Context.Provider
                value={{
                    cookie_name: this.cookie_name,
                    mode: this.state.mode,
                    apiRoot: this.state.apiRoot,
                    query: this.query,
                    stripe: this.stripe,
                    settings: this.state.settings,
                    session: this.state.session,
                    setSession: this.setSession.bind(this),
                    resNewMealPlan: this.state.resNewMealPlan,
                    setResNewMealPlan: this.setResNewMealPlan.bind(this),
                }}
            >
                <BrowserRouter>
                    <React.Fragment>
                        <Helmet>
                            <meta name="keywords" content={"Reset Your Gut"} />

                            <meta name="description" content={"A four week meal plan to improve your physical and mental health."} />
                            <meta name="twitter:description" content="A four week meal plan to improve your physical and mental health." />

                            <meta property="og:type" content="website" />

                            <meta property="og:title" content="Reset Your Health" />
                            <meta name="twitter:title" content="Reset Your Health" />

                            <meta name="twitter:card" content="summary_large_image" />

                            <meta name="twitter:image" content={ogImage} />
                            <meta property="og:image" content={ogImage} />
                        </Helmet>
                        <Navigation
                            mode={this.state.mode}
                            links={this.generateNavLinks()}
                        />
                        {!Boolean(this.state.session.user)
                            ? (
                                <Switch>
                                    <Route path="/login" render={router => (
                                        <LoginPage
                                            router={router}
                                            pageName="loginPage"
                                        />
                                    )} />
                                    <Route
                                        path="/contact"
                                        render={router => {
                                            return <ContactPage
                                                router={router}
                                                pageName="contactPage"
                                            />
                                        }}
                                    />
                                    <Route
                                        path="/register"
                                        render={router => {
                                            return <RegisterPage
                                                router={router}
                                                pageName="registerPage"
                                            />
                                        }}
                                    />
                                    <Route path="/" render={router => (
                                        <Redirect to="/login" />
                                    )} />
                                </Switch>
                            ) : (
                                <Switch>
                                    <Route
                                        path="/account"
                                        render={router => (
                                            <AccountPage
                                                router={router}
                                                pageName="accountPage"
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/account/recipe/:recipeId"
                                        render={router => (
                                            <RecipePage router={router} />
                                        )}
                                    />

                                    <Route
                                        path="/admin"
                                        render={router => {
                                            return <AdminPage
                                                router={router}
                                                pageName="adminPage"
                                            />
                                        }}
                                    />
                                    <Route
                                        path="/restart"
                                        render={router => {
                                            return <RestartPlan
                                                router={router}
                                                pageName="restartPage"
                                            />
                                        }}
                                    />
                                    <Route
                                        path="/upgrade"
                                        render={router => {
                                            return <UpgradePage
                                                router={router}
                                                pageName="upgradePage"
                                            />
                                        }}
                                    />
                                    <Route
                                        path="/useful-information/supplement-advice"
                                        render={router => {
                                            return <SupplementAdvice
                                                router={router}
                                                pageName="usefulInfo"
                                            />
                                        }}
                                    />
                                    <Route
                                        path="/useful-information/overview"
                                        render={router => {
                                            return <Overview
                                                router={router}
                                                pageName="usefulInfo"
                                            />
                                        }}
                                    />
                                    <Route
                                        path="/useful-information/clean-15-and-the-dirty-dozen"
                                        render={router => {
                                            return <DirtyDozen
                                                router={router}
                                                pageName="usefulInfo"
                                            />
                                        }}
                                    />
                                    <Route
                                        path="/useful-information/do-and-donts"
                                        render={router => {
                                            return <DoDont
                                                router={router}
                                                pageName="usefulInfo"
                                            />
                                        }}
                                    />
                                    <Route
                                        path="/useful-information/feeding-your-gut-bacteria"
                                        render={router => {
                                            return <GutBacteria
                                                router={router}
                                                pageName="usefulInfo"
                                            />
                                        }}
                                    />
                                    <Route
                                        path="/useful-information/weekly-guidelines"
                                        render={router => {
                                            return <WeeklyGuidelines
                                                router={router}
                                                pageName="usefulInfo"
                                            />
                                        }}
                                    />
                                    <Route
                                        path="/useful-information/how-does-reset-your-gut-programme-work"
                                        render={router => {
                                            return <Programme
                                                router={router}
                                                pageName="usefulInfo"
                                            />
                                        }}
                                    />
                                    <Route
                                        path="/useful-information/top-tips"
                                        render={router => {
                                            return <TopTips
                                                router={router}
                                                pageName="usefulInfo"
                                            />
                                        }}
                                    />
                                    <Route
                                        path="/useful-information/healthy-snacks"
                                        render={router => {
                                            return <Snacks
                                                router={router}
                                                pageName="usefulInfo"
                                            />
                                        }}
                                    />
                                    <Route
                                        path="/useful-information/rebalancing-your-gut-bacteria"
                                        render={router => {
                                            return <Rebalancing
                                                router={router}
                                                pageName="usefulInfo"
                                            />
                                        }}
                                    />
                                    <Route path="/" render={router => (
                                        <Redirect to="/account" />
                                    )} />
                                </Switch>
                            )}
                        <Footer />
                    </React.Fragment>
                </BrowserRouter>
            </Context.Provider>
        )
    }
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
