import React from "react";
import Tab from "./tab";
import ConditionCreator from "../creators/condition/conditionCreator";

class Conditions extends Tab {

    itemCreator(item) {
        return (
            <ConditionCreator
                key={"ingredientCreator" + (item ? item.id : "new")}
                item={item}
                displayName={this.displayName}
                itemUpdate={(changes, id) => {
                    this.editItem(changes, id);
                }}
                itemCreate={item => {
                    this.createItem(item)
                }}
                itemDelete={id => {
                    this.deleteItem(id)
                }}
                close={() => {
                    this.setState({ adding: false, selected: null })
                }}
            />
        )
    }

}

export default Conditions;