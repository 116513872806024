import React from 'react';
import $ from "jquery";
import UsefulInfoTemplate from "./index";
import Content from "../../assets/content";
import Context from "../../context";
import { NavLink } from "react-router-dom";

class Snacks extends UsefulInfoTemplate {
    constructor(props) {
        super(props);
        this.state = {
            snacks: []
        }
        this.gotSnacks = false;
    }

    componentDidMount() {
        if (this.context.session.user && this.context.session.ready && !this.gotSnacks) {
            this.gotSnacks = true;
            this.getSnacks();
        }
    }

    componentDidUpdate() {
        if (this.state.modal) {
			$(".customModal").fadeIn(200)
		} else {
			$(".customModal").fadeOut(200)
		}
        if (this.context.session.user && this.context.session.ready && !this.gotSnacks) {
            this.gotSnacks = true;
            this.getSnacks();
        }
    }

    renderRecipes() {
        return this.state.snacks.map(recipe => {
            return (
                <div className="col-12 col-md-6 recipe-col" key={recipe.id}>
                    <div className="inner shadow1">
                        <div
                            className="mealImage"
                            style={{
                                backgroundImage: (recipe.images && recipe.images[0]) ?
                                    `url("${recipe.images[0]}")` :
                                    `url("/static/media/meals/${recipe.name}.jpg")`
                            }}
                        />
                        <div className="name">{recipe.name}</div>
                        <div className="description">{`${recipe.description ? recipe.description.substr(0, 175) : null}...`}</div>
                        <NavLink
                            exact
                            to={`/account/recipe/${recipe.id}`}
                            className="button viewRecipe"
                        >
                            View
                        </NavLink>
                    </div>
                </div>
            )
        })
    }

    getSnacks() {
        $.get({
            url: `${this.context.apiRoot}/recipes/by-type/snack${Boolean(this.context.session.user) === "user" ? "?eligibleOnly=true" : ""}`
        }).done(res => {
            // console.log(res);
            this.setState({ snacks: res.results })
        }).catch(err => {
            console.error("Error getting recipes", err);
        })
    }

    content() {
        return (
            <React.Fragment>
                <Content>useful-info-snacks-heading-text</Content>
                <div className="row snacks-row">
                    {this.renderRecipes()}
                </div>
            </React.Fragment>
        )
    }
}

Snacks.contextType = Context;

export default Snacks;