class Quantity {
    constructor(value, measurement, type) {
        this.value = value;
        this.measurement = measurement || "null";
        this.quantityCategories = {
            length: {
                BASE: "cm",
                cm: 1,
                m: 100,
                mm: 0.1
            },
            fluid: {
                BASE: "ml",
                ml: 1,
                l: 1000,
                tsp: 5,
                tbsp: 15
            },
            weight: {
                BASE: "g",
                pinch: 1,
                g: 1,
                kg: 1000,
                tsp: 5,
                tbsp: 15
            },
            misc: {
                BASE: "null"
            }
        }
        this.type = type || this.getType();
    }

    getType() {
        for (let type in this.quantityCategories) {
            if (this.quantityCategories[type][this.measurement]) {
                return type;
            }
        }
        return null;
    }

    compatibleWith(quantity) {
        if (this.type === quantity.type) {
            return true;
        } else {
            return false;
        }
    }

    base() {
        return this.to("BASE");
    }

    to(newMeasurement = "BASE") {
        // Convert base to standard if passed.
        if (newMeasurement === "BASE")
            newMeasurement = this.quantityCategories[this.type].BASE;

        if (!this.quantityCategories[this.type][newMeasurement]) {
            return console.error(`The measurement ${newMeasurement} is not currently valid as a '${this.type}'.`);
        }
        // Get the conversion rate.
        let baseConverter = this.quantityCategories[this.type][this.measurement];
        // Get the value in the base measurement.
        let baseValue = this.value * baseConverter;
        // console.log(baseConverter, "Val in g: ", baseValue);

        let newConverter = this.quantityCategories[this.type][newMeasurement];
        let newValue = baseValue / newConverter;

        // console.log(newConverter);
        this.value = Math.round(newValue * 1000) / 1000;
        this.measurement = newMeasurement;
        return this;
    }

    print() {
        return `${this.value}${this.measurement === "null" ? "" : this.measurement}`;
    }
}

export default Quantity;