import $ from "jquery";
import React, { useState, useContext } from "react";
import Context from "../../context";
import { validateEmail } from "./helpers";
import Field from "./Field";

export default function EmailLogin() {

	const [values, setValues] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [loginError, setLoginError] = useState(null);
    const context = useContext(Context);

	function fieldChange({ name, value, error }) {
		setValues({
			...values,
			[name]: value
		});
		setFieldErrors({
			...fieldErrors,
			[name]: error
		});
    }
    
	return <form key="email-login" onSubmit={e => {
        e.preventDefault();
        if (Object.values(fieldErrors).filter(e => e).length) return;
        // Login request
        $.ajax({
            type: "POST",
            url: `${context.apiRoot}/auth/login`,
            data: JSON.stringify(values),
            contentType: "application/json"
        }).done(res => {
            context.setSession({ token: res.token, user: res.user });
        }).catch(err => {
            let error = err.message || err.responseJSON.message;
            setLoginError(error);    
        })
	}}>
		{/* {forEmailConfirmation ? <h4>Great! Now you just have to sign in.</h4> : <h4>Log in with email</h4>}
		<br /> */}
		<Field
			onChange={fieldChange}
			name="uname"
			values={values}
			errors={fieldErrors}
			autoComplete="email"
			placeholder="Email"
			validate={validateEmail}
		/>
		<Field
			onChange={fieldChange}
			name="pwd"
			type="password"
			values={values}
			errors={fieldErrors}
			autoComplete="current-password"
			placeholder="Password"
		/>
		<button
            type="submit"
			disabled={Object.values(fieldErrors).filter(e => e).length}
		>Log In</button>
		{loginError && <>
			<br />
            <br />
			<div className="error text-center">
				{loginError}
			</div>
		</>}
	</form>
}