import React from "react";
import Field from "./field";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class RichTextField extends Field {

    constructor(props) {
        super(props);
        this.settings = {
            type: "richTextField"
        }
        const contentBlock = htmlToDraft(this.props.children || "");
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            this.state.editorState = EditorState.createWithContent(contentState);
        } else {
            this.state.editorState = EditorState.createEmpty();
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.children && this.props.children) {
            const contentBlock = htmlToDraft(this.props.children || "");
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    editorState: EditorState.createWithContent(contentState)
                });
            } else {
                this.setState({
                    editorState: EditorState.createEmpty()
                });
            }
        }

    }

    renderField() {
        return (
            <React.Fragment>
                <Editor
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                        colorPicker: {
                            // icon: color,
                            // className: undefined,
                            // component: undefined,
                            // popupClassName: undefined,
                            colors: ['rgb(97,189,109)', 'rgb(193, 215, 45)', 'rgb(176, 198, 48)','rgb(252, 179, 22)', 'rgb(26, 194, 251)', 'rgb(89, 98, 104)', 'rgb(123, 134, 140)', 'rgb(54, 67, 76)', 'rgb(245, 244, 240)', 'rgb(75, 75, 87)'],
                        }
                    }}
                    editorState={this.state.editorState}
                    // toolbarClassName="toolbarClassName"
                    // wrapperClassName="wrapperClassName"
                    // editorClassName="editorClassName"
                    onEditorStateChange={editorState => {
                        this.setState({ editorState }, () => {
                            this.change(draftToHtml(convertToRaw(editorState.getCurrentContent())));
                        });
                    }}
                />
            </React.Fragment>
        )
    }

}

export default RichTextField;