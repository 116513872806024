/**
 *  Recipe item structure  
 */

import React from "react";
import ItemCreator from "./itemCreator";
import TextField from "../../../fields/textField";
import TextareaField from "../../../fields/textareaField";
import MethodField from "../../../fields/textBuilder";
import CheckboxField from "../../../fields/checkboxField";
import ItemFinderField from "../../../fields/itemFinderField";
import Context from "../../../context";
import $ from "jquery";
import { NavLink } from "react-router-dom";

import IngredientManager from "../ingredientManager";
import ImageField from "../../../fields/imageField";
import NumberField from "../../../fields/numberField";
import DropdownField from "../../../fields/dropdownField";
//Media import
import redCloseIcon from "../../../media/icon-close-red.svg"

const defaultActiveMonths = [
    true, true, true, true, true, true, true, true, true, true, true, true
];

class RecipeCreator extends ItemCreator {

    constructor(props) {
        super(props);
        this.state.mode = "method"; // or ingredients
        this.state.links = []; // array of linked recipes { id, name }
        this.state.ingredients = [];
    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({ item: this.props.item })
            // Getting links
            this.getLinks()
        }
    }

    canSubmit() {
        if (this.props.item) {
            //If updating an existing recipe
            let canSubmit = false;
            Object.keys(this.state.changes).forEach(key => {
                if (key && this.state.changes[key] !== undefined) {
                    canSubmit = true;
                }
            })
            if (canSubmit) return true;
        } else {
            if (this.state.item.name && this.state.item.method) {
                return true;
            }
        }
        //Return false by default.
        return false;
    }

    getIngredients() {
        $.get(`${this.context.apiRoot}/recipes/${this.props.item.id}/ingredients`).then(res => {
            this.setState({
                ingredients: res.ingredients
            });
        }).catch(err => {
            console.error("Error getting ingredients: ", err);
        });
    }

    getLinks() {
        $.get(`${this.context.apiRoot}/recipes/${this.props.item.id}/links`).then(res => {
            this.setState({
                links: res.links
            });
        }).catch(err => {
            console.error("Error getting links: ", err);
        });
    }

    createLink(item) {
        $.post(`${this.context.apiRoot}/recipes/${this.props.item.id}/links/${item.id}`).then(res => {
            let links = [...this.state.links, item];
            // console.log("links: ", links);
            this.setState({ links });
        }).catch(err => {
            console.error("Error creating link: ", err);
        })
    }

    removeLink(linkId) {
        $.ajax({
            type: "DELETE",
            url: `${this.context.apiRoot}/recipes/${linkId}/links`
        }).then(res => {
            let links = [...this.state.links].filter(link => link.id !== linkId);
            // console.log("links: ", links);
            this.setState({ links });
        }).catch(err => {
            console.error("Error deleting link: ", err);
        })
    }

    renderRowHeader(item) {
        if (!item) return "New Item";
        let months = [
            "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
        ];
        let activeMonths = (item.activeMonths || [])
            .map((v, i) => v ? months[i] : null).filter(i => i);
        return <span>
            <span>
                {item.name.toUpperCase()} #{item.id}
            </span> | <span className="c-featureColor2">
                {activeMonths.length === 12 ? "ALL YEAR" : activeMonths.join(" ")}
            </span>
        </span>
    }

    renderCreator() {
        return (
            <div className="creatorFields">
                {this.props.item && this.props.item.id
                    ?
                    <div className="recipeLink">
                        <NavLink
                        exact
                        to={`/account/recipe/${this.props.item.id}`}
                        >
                            Go to recipe.
                        </NavLink>
                    </div>
                    :
                    null
                }
                <ImageField
                    label="Image"
                    filePrefix="media/meals"
                    change={(err, val) => {
                        // console.log(val)
                        this.itemChanged("images", [val])
                    }}
                >
                    {this.state.item.images ? (this.state.item.images[0] || null) : (
                        this.props.item ?
                            `https://static.resetyourhealth.com/static/media/meals/${this.state.item.name}.jpg`
                            : null
                    )}
                </ImageField>
                <TextField
                    label="Name"
                    change={(err, val) => {
                        this.itemChanged("name", val)
                    }}
                >
                    {this.state.item.name}
                </TextField>
                <DropdownField
                    label="Priority (Higher is better)"
                    fullWidth={true}
                    options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                    change={(err, val) => {
                        this.itemChanged("priority", val);
                    }}
                >
                    {this.state.item.priority}
                </DropdownField>
                <TextField
                    label="Hidden note"
                    change={(err, val) => {
                        this.itemChanged("note", val)
                    }}
                >
                    {this.state.item.note}
                </TextField>
                <NumberField
                    label="Produced Weight (g)"
                    change={(err, val) => {
                        console.log(val);
                        this.itemChanged("producedWeight", val);
                    }}
                >
                    {this.state.item.producedWeight || 0}
                </NumberField>
                <TextareaField
                    label="Description"
                    change={(err, val) => {
                        this.itemChanged("description", val)
                    }}
                >
                    {this.state.item.description}
                </TextareaField>

                {/* Generate CSV export of nutritional data */}
                {this.props.item && this.props.item.id ? <div className="nutritionalDataExport">
                    <hr />
                    <button
                        className="button"
                        style={{
                            width: "100%",
                            borderRadius: "0px"
                        }}
                        onClick={e => {
                            $.ajax({
                                method: "GET",
                                url: `${this.context.apiRoot}/meal-plans/test/nutrition/by-recipe/${this.props.item.id}?type=csv`
                            }).done(response => {
                                function download(fileName, text) {
                                    if (window.navigator.msSaveBlob) { // IE
                                        let blob = new Blob([text], {type:  "text/plain;charset=utf-8;"});
                                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                                    } else { // Reasonable browsers. 
                                        let element = document.createElement('a');
                                        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
                                        element.setAttribute('download', fileName);
                                        element.style.display = 'none';
                                        document.body.appendChild(element);
                                        element.click();
                                        document.body.removeChild(element);
                                    }
                                }
                                download(`${this.props.item.id}_${this.props.item.name.trim()}.csv`, response);
                            }).catch(err => {
                                console.error("Error getting nutritional export: ", err);
                            });
                        }}
                    >Export nutritional data.</button>
                    <hr />
                </div> : null}

                {/* Controls to swap between method and ingredients */}
                <div className="mode-controls">
                    {this.props.item ? <div
                        className={"button" + (this.state.mode === "ingredients" ? " current" : "")}
                        onClick={e => {
                            if (this.state.mode === "ingredients") return null;
                            this.setState({
                                mode: "ingredients",
                                ingredients: this.props.item ? [] : this.state.ingredients
                            });
                            if (this.props.item) this.getIngredients();
                        }}
                    >Ingredients</div> : null}
                    <div
                        className={"button" + (this.state.mode === "method" ? " current" : "")}
                        onClick={e => {
                            if (this.state.mode === "method") return null;
                            this.setState({
                                mode: "method"
                            })
                        }}
                    >Method</div>
                </div>
                {this.state.mode === "method" ?
                    <MethodField
                        change={(err, val) => {
                            this.itemChanged("method", val);
                        }}
                    >
                        {this.state.item.method}
                    </MethodField>
                    : null
                }
                {this.state.mode === "ingredients" ?
                    <IngredientManager
                        item={this.props.item}
                        onChange={() => {
                            this.getIngredients();
                        }}
                        ingredients={this.state.ingredients}
                    />
                    : null
                }
                <div className="group checkboxGroup">
                    <label>Meal type</label>
                    <CheckboxField
                        label="Breakfast"
                        change={(err, val) => {
                            this.itemChanged("breakfast", Number(val))
                        }}
                    >
                        {Boolean(this.state.item.breakfast)}
                    </CheckboxField>
                    <CheckboxField
                        label="Lunch"
                        change={(err, val) => {
                            this.itemChanged("lunch", Number(val))
                        }}
                    >
                        {Boolean(this.state.item.lunch)}
                    </CheckboxField>
                    <CheckboxField
                        label="Dinner"
                        change={(err, val) => {
                            this.itemChanged("dinner", Number(val))
                        }}
                    >
                        {Boolean(this.state.item.dinner)}
                    </CheckboxField>
                    <CheckboxField
                        label="Snack"
                        change={(err, val) => {
                            this.itemChanged("snack", Number(val))
                        }}
                    >
                        {Boolean(this.state.item.snack)}
                    </CheckboxField>
                    <CheckboxField
                        label="Essential"
                        change={(err, val) => {
                            this.itemChanged("basic", Number(val))
                        }}
                    >
                        {Boolean(this.state.item.basic)}
                    </CheckboxField>
                </div>
                <div className="group checkboxGroup">
                    <label>Active Months</label>
                    {[
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December"
                    ].map((label, month) => {
                        return <CheckboxField
                            key={month}
                            label={label}
                            change={(err, newValue) => {
                                let activeMonths = (this.state.item.activeMonths || defaultActiveMonths).map((oldValue, index) => {
                                    if (index === month) {
                                        return newValue;
                                    } else {
                                        return oldValue;
                                    }
                                });
                                this.itemChanged("activeMonths", activeMonths);
                            }}
                        >
                            {Boolean((this.state.item.activeMonths || defaultActiveMonths)[month])}
                        </CheckboxField>
                    })}
                </div>
                {this.props.item && this.props.item.id
                    ?
                    <div className="recipeLinks">
                        <hr
                            style={{
                                borderTop: "2px dashed #dedede",
                                margin: "20px 0px 20px 0px"
                            }}
                        />
                        <label>{this.state.links.length ? "Grouped with:" : "Group with:"}</label>
                        {this.state.links.length
                            ?
                            this.state.links.map(link => {
                                return (
                                    <div className="linkedRecipe item" key={link.id}>
                                        {link.name}
                                        <img
                                            alt="close icon"
                                            src={redCloseIcon}
                                            onClick={() => {
                                                this.removeLink(link.id)
                                            }}
                                        />
                                    </div>
                                )
                            })
                            :
                            <p className="noLinks" style={{
                                textAlign: "center",
                                fontStyle: "italic",
                                color: "#5e5d5b"
                            }}>No current links.</p>
                        }
                        <ItemFinderField
                            label={null}
                            placeholder={"add link..."}
                            itemName="recipes"
                            filter={item => {
                                return item.id !== this.props.item.id && !this.state.links.filter(link => link.id === item.id).length;
                            }}
                            change={(err, val) => {
                                //Checking if recipe is already linked
                                let hasLink = this.state.links.filter(link => link.id === val.id).length;
                                if (hasLink) return;
                                //Creating new link
                                let link = { id: val.id, name: val.name };
                                this.createLink(link);
                            }}
                        />
                        <hr
                            style={{
                                borderTop: "2px dashed #dedede",
                                margin: "20px 0px 20px 0px"
                            }}
                        />
                    </div>
                    :
                    null
                }
                <div className="group checkboxGroup">
                    <label>Suspended</label>
                    <CheckboxField
                        label="Suspended"
                        change={(err, val) => {
                            this.itemChanged("suspended", Number(val))
                        }}
                    >
                        {Boolean(this.state.item.suspended)}
                    </CheckboxField>
                </div>
                <br />
                {this.props.item && this.props.item.id
                    ?
                    <div className="recipeLink">
                        <NavLink
                        exact
                        to={`/account/recipe/${this.props.item.id}`}
                        >
                            Go to recipe.
                        </NavLink>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

RecipeCreator.contextType = Context;

export default RecipeCreator;