import React from "react";
import Content from "./content";

class DivWithBackground extends Content {

    render() {
        return <div
            className={this.props.className}
            style={this.state.content ? {
                backgroundImage: `url("${this.state.content.value}")`
            } : {}}
        >
            {this.props.children}
        </div>
    }

}

export default DivWithBackground;