import Field from "./field";
import React from "react";
import TextField from "./textField";
import RichTextField from "./richTextField";
import VideoField from "./videoField";
import ImageField from "./imageField";

class BlogContentField extends Field {

    constructor(props) {
        super(props)
        this.settings = {
            type: "blogContentField"
        }
    }

    renderBlockEditor(block) {

        let changeHandler = (err, val) => {
            this.change(this.props.children.map(s => {
                if (s === block) {
                    return Object.assign({}, s, {
                        body: val
                    })
                } else {
                    return s;
                }
            }));
        }

        // Switch through the types, and select an editor.
        let Editor;
        switch (block.type) {
            case "richText":
                Editor = RichTextField;
                break;
            case "video":
                Editor = VideoField;
                break;
            case "image":
                Editor = ImageField;
                break;
            case "h1":
            case "h2":
            case "h3":
            case "h4":
            default:
                Editor = TextField;
        }

        return <div className="blog-content-editor">
            <Editor
                change={changeHandler}
            >
                {block.body}
            </Editor>
        </div>

    }

    renderField() {
        let contentStack = this.props.children || [];
        return (
            <div className="container">
                <div className="row justify-content-center">
                    {contentStack.map((block, index) => (
                        <div
                            className={`stackItem col-12 col-md-${block.width}`}
                            key={index}
                        >
                            <span>{block.type}</span>
                            {this.renderBlockEditor(block)}
                            <div className="controls">
                                <select
                                    value={block.width}
                                    onChange={e => {
                                        // Width selector for each block.
                                        this.change(contentStack.map(s => {
                                            if (s === block) {
                                                return Object.assign({}, s, {
                                                    width: Number(e.target.value)
                                                })
                                            } else {
                                                return s;
                                            }
                                        }));
                                    }}
                                >
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(w => (
                                        <option value={w} key={w}>{w}</option>
                                    ))}
                                </select>
                                <div
                                    className="remove miniBtn"
                                    onClick={e => {
                                        this.change(contentStack.filter(b => b !== block))
                                    }}
                                >x</div>
                                {index !== 0 ? <div
                                    className="reOrder-up miniBtn"
                                    onClick={e => {
                                        let newStack = [...contentStack];
                                        [newStack[index], newStack[index - 1]] = [newStack[index - 1], newStack[index]];
                                        this.change(newStack);
                                    }}
                                >UP</div> : null}
                                {index !== contentStack.length - 1 ? <div
                                    className="reOrder-down miniBtn"
                                    onClick={e => {
                                        let newStack = [...contentStack];
                                        [newStack[index], newStack[index + 1]] = [newStack[index + 1], newStack[index]];
                                        this.change(newStack);
                                    }}
                                >DOWN</div> : null}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="row">
                    <div className="col-12 add-section">
                        <select
                            ref="typeSelector"
                        >
                            {["h1", "h2", "h3", "h4", "richText", "image", "video"].map(opt => (
                                <option value={opt} key={opt}>{opt}</option>
                            ))}
                        </select>
                        <div
                            className="button"
                            onClick={e => {
                                this.change([...contentStack, {
                                    type: this.refs.typeSelector.value,
                                    body: "",
                                    width: 12
                                }]);
                            }}
                        >Add Section</div>
                    </div>
                </div>
            </div>
        )
    }

}

export default BlogContentField;