import React from 'react';
import Page from "../page";
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';
import HeroImage from "../../assets/hero";
import Ingredients from "./tabs/ingredients";
import Tags from "./tabs/tags";
import Conditions from "./tabs/conditions";
import Recipes from "./tabs/recipes";
// import Blog from './tabs/blog';
// import Faq from "./tabs/faqs";
// import Team from "./tabs/team";
import MealPlanTester from "./mealPlanTester";
import RecipeCombinations from './tabs/recipeCombinations';
import ContentTab from './tabs/contentTab';
import QuestionnairesTab from './tabs/questionnaires';
// import PressReleasesTab from "./tabs/pressReleases";
import UsersTab from "./tabs/users";
import PartnersTab from "./tabs/partners";
import Settings from "./settings"
//Media import
import heroBg from "../../media/hero-admin.jpg";
import ingredients from "../../media/icons/misc/icon-admin-ingredients.svg";
import recipes from "../../media/icons/misc/icon-admin-recipes.svg";
import tags from "../../media/icons/misc/icon-admin-tags.svg";
import conditions from "../../media/icons/misc/icon-admin-conditions.svg";
import users from "../../media/icons/misc/icon-admin-users.svg";
import partners from "../../media/icons/misc/icon-admin-partners.svg";
import settings from "../../media/icons/misc/icon-admin-settings.svg";

class AdminPage extends Page {
    pageContent() {
        if (!Boolean(this.context.session.user) || !this.context.session.user.isAdmin)
            return <Redirect to={"/"} />
        return this.context.session.ready ?
            <React.Fragment>
                <HeroImage
                    img={heroBg}
                >
                </HeroImage>
                <div className="adminNavigation">
                    <NavLink
                        to="/admin/ingredients"
                        activeClassName="selected"
                    >
                        <img src={ingredients} alt="ingredients icon" />
                        <span>INGREDIENTS</span>
                    </NavLink>
                    <NavLink
                        to="/admin/recipes"
                        activeClassName="selected"
                    >
                        <img src={recipes} alt="recipes icon" />
                        <span>RECIPES</span>
                    </NavLink>
                    <NavLink
                        to="/admin/recipe-combinations"
                        activeClassName="selected"
                    >
                        <img src={recipes} alt="recipes icon" />
                        <span>RECIPE COMBOS</span>
                    </NavLink>
                    <NavLink
                        to="/admin/tags"
                        activeClassName="selected"
                    >
                        <img src={tags} alt="tag icon" />
                        <span>TAGS</span>
                    </NavLink>
                    <NavLink
                        to="/admin/conditions"
                        activeClassName="selected"
                    >
                        <img src={conditions} alt="conditions icon" />
                        <span>CONDITIONS</span>
                    </NavLink>
                    {/* <NavLink
                        to="/admin/blog"
                        activeClassName="selected"
                    >
                        <img src={recipes} alt="recipes icon" />
                        <span>BLOG POSTS</span>
                    </NavLink> */}
                    <NavLink
                        to="/admin/meal-plan"
                        activeClassName="selected"
                    >
                        <img src={recipes} alt="recipes icon" />
                        <span>MEAL PLAN</span>
                    </NavLink>
                    <NavLink
                        to="/admin/content"
                        activeClassName="selected"
                    >
                        <img src={recipes} alt="recipes icon" />
                        <span>CONTENT</span>
                    </NavLink>
                    {/* <NavLink
                        to="/admin/faqs"
                        activeClassName="selected"
                    >
                        <img src={recipes} alt="recipes icon" />
                        <span>FAQS</span>
                    </NavLink> */}
                    {/* <NavLink
                        to="/admin/team"
                        activeClassName="selected"
                    >
                        <img src={recipes} alt="recipes icon" />
                        <span>TEAM</span>
                    </NavLink> */}
                    <NavLink
                        to="/admin/questionnaires"
                        activeClassName="selected"
                    >
                        <img src={recipes} alt="recipes icon" />
                        <span>QUESTIONNAIRES</span>
                    </NavLink>
                    {/* <NavLink
                        to="/admin/pressReleases"
                        activeClassName="selected"
                    >
                        <img src={recipes} alt="recipes icon" />
                        <span>PRESS RELEASES</span>
                    </NavLink> */}
                    <NavLink
                        to="/admin/users"
                        activeClassName="selected"
                    >
                        <img src={users} alt="users icon" />
                        <span>USERS</span>
                    </NavLink>
                    <NavLink
                        to="/admin/partners"
                        activeClassName="selected"
                    >
                        <img src={partners} alt="partners icon" />
                        <span>PARTNERS</span>
                    </NavLink>
                    <NavLink
                        to="/admin/settings"
                        activeClassName="selected"
                    >
                        <img src={settings} alt="settings icon" />
                        <span>SETTINGS</span>
                    </NavLink>
                </div>
                <div className="bg-transition"></div>
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-12 col-lg-10">
                            <Switch>
                                <Route
                                    path="/admin/ingredients"
                                    exact
                                    render={router => {
                                        return <Ingredients
                                            name="Ingredients"
                                            route="ingredients"
                                        />
                                    }}
                                />
                                <Route
                                    path="/admin/recipes"
                                    exact
                                    render={router => {
                                        return <Recipes
                                            name="Recipes"
                                            route="recipes"
                                        />
                                    }}
                                />
                                <Route
                                    path="/admin/tags"
                                    exact
                                    render={router => {
                                        return <Tags
                                            name="Tags"
                                            route="tags"
                                        />
                                    }}
                                />
                                <Route
                                    path="/admin/conditions"
                                    exact
                                    render={router => {
                                        return <Conditions
                                            name="Conditions"
                                            route="conditions"
                                        />
                                    }}
                                />
                                <Route
                                    path="/admin/meal-plan"
                                    exact
                                    render={router => {
                                        return <MealPlanTester
                                            router={router}
                                        />
                                    }}
                                />
                                {/* <Route
                                    path="/admin/blog"
                                    exact
                                    render={router => {
                                        return <Blog
                                            name="Blog Posts"
                                            route="blog"
                                            router={router}
                                        />
                                    }}
                                />
                                <Route
                                    path="/admin/faqs"
                                    exact
                                    render={router => {
                                        return <Faq
                                            name="FAQs"
                                            route="faq"
                                            router={router}
                                        />
                                    }}
                                />
                                <Route
                                    path="/admin/team"
                                    exact
                                    render={router => {
                                        return <Team
                                            name="Teams"
                                            route="team"
                                            router={router}
                                        />
                                    }}
                                /> */}
                                <Route
                                    path="/admin/recipe-combinations"
                                    exact
                                    render={router => {
                                        return <RecipeCombinations
                                            name="Recipe Combinations"
                                            route="recipe-combinations"
                                            router={router}
                                        />
                                    }}
                                />
                                <Route
                                    path="/admin/content"
                                    exact
                                    render={router => {
                                        return <ContentTab
                                            name="Content Items"
                                            route="content"
                                            router={router}
                                        />
                                    }}
                                />
                                <Route
                                    path="/admin/questionnaires"
                                    exact
                                    render={router => {
                                        return <QuestionnairesTab
                                            name="questionnaires"
                                            route="questionnaires"
                                            router={router}
                                        />
                                    }}
                                />
                                {/* <Route
                                    path="/admin/pressReleases"
                                    exact
                                    render={router => {
                                        return <PressReleasesTab
                                            name="Press Releases"
                                            route="pressReleases"
                                            router={router}
                                        />
                                    }}
                                /> */}
                                <Route
                                    path="/admin/users"
                                    exact
                                    render={router => {
                                        return <UsersTab
                                            name="Users"
                                            route="users"
                                            router={router}
                                        />
                                    }}
                                />
                                <Route
                                    path="/admin/partners"
                                    exact
                                    render={router => {
                                        return <PartnersTab
                                            name="Partners"
                                            route="partners"
                                            router={router}
                                        />
                                    }}
                                />
                                <Route
                                    path="/admin/settings"
                                    exact
                                    render={router => {
                                        return <Settings
                                            router={router}
                                        />
                                    }}
                                />
                                <Route
                                    path="/admin"
                                    render={router => {
                                        return <Redirect to="/admin/ingredients" />
                                    }}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </React.Fragment>
            : null

    }

}

export default AdminPage;