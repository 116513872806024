import React from "react";
import DropdownField from "../../../../fields/dropdownField";
import NumberField from "../../../../fields/numberField";
import { randomId } from "../../../../helpers";

// Media import
import binIcon from "../../../../media/icon-bin.svg";
import closeIcon from "../../../../media/icon-close.svg";
import arrowIcon from "../../../../media/icon-arrow.svg";
import nutrientsList from "./nutrients.json";

class DemographicList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			items: props.items.map(item => ({
				...item,
				id: randomId(10)
			}))
		}
	}

	componentDidUpdate(pp) {
		if (pp.items.length !== this.props.items.length)
			this.setState({
				items: this.props.items.map(item => ({
					...item,
					id: randomId(10)
				}))
			})
	}

	render() {
		// Getting values from props
		const { showing, items, change } = this.props;

		// Checking if component should be visible.
		if (!showing) return null;
		return (
			<div className="demographic-list">
				{this.state.items.map((item, index) => {
					return (
						<Demographic
							key={item.id}
							item={item}
							removeHandler={() => {
								let newItems = [...items];
								newItems.splice(index, 1);
								change(newItems
									.map(item => ({
										...item,
										id: undefined,
										rules: item.rules.map(rule => ({
											...rule,
											id: undefined
										}))
									}))
								);
							}}
							changeHandler={newItem => {
								change(items
									.map((d, i) => i === index ? newItem : d)
									.map(item => ({
										...item,
										id: undefined,
										rules: item.rules.map(rule => ({
											...rule,
											id: undefined
										}))
									}))
								);
							}}
						/>
					)
				})}
				<div
					className="demographic-add"
					onClick={() => {
						change([...items, { gender: null, maxAge: null, minAge: null, rules: [] }])
					}}
				>
					Add demographic
                </div>
			</div>
		)
	}
}

class Demographic extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			item: {
				...props.item,
				rules: props.item.rules.map(rule => ({
					...rule,
					id: randomId(10)
				}))
			},
			age: props.item.minAge === null && props.item.maxAge === null ?
				"any" : "range"
		};
		this.genderOptions = [
			{
				value: null,
				label: "Any"
			},
			{
				value: "male",
				label: "Male"
			},
			{
				value: "female",
				label: "Female"
			}
		]
	}

	componentDidUpdate(pp) {
		if (pp.item.rules.length !== this.props.item.rules.length)
			this.setState({
				item: {
					...this.state.item,
					rules: this.props.item.rules.map(rule => ({
						...rule,
						id: randomId(10)
					}))
				}
			})
	}

	itemChanged(obj) {
		this.props.changeHandler(Object.assign(this.state.item, obj));
	}

	ruleChanged(obj, id) {
		let newRules = this.state.item.rules.map(r => {
			if (r.id === id) return Object.assign(r, obj);
			return r;
		})
		this.itemChanged({ rules: newRules });
	}

	render() {
		// Getting values from state and props.
		const { age, item } = this.state;
		const { removeHandler } = this.props;
		return (
			<div className="demographic">
				<img
					className="remove-btn"
					src={closeIcon}
					alt="bin icon"
					onClick={() => {
						removeHandler()
					}}
				/>
				<div className="flex-container">
					<DropdownField
						label="Gender"
						options={this.genderOptions}
						change={(err, val) => {
							this.itemChanged({ gender: val });
						}}
					>
						{/* {item.gender} */}
						{(item.gender || item.gender === null)
							? this.genderOptions.filter(g => g.value === item.gender)[0].label
							: ""
						}
					</DropdownField>
					<DropdownField
						label="Age"
						options={["any", "range"]}
						change={(err, val) => {
							if (val === "any") {
								this.itemChanged({ minAge: null, maxAge: null });
							}
							this.setState({ age: val })
						}}
					>
						{age}
					</DropdownField>
					{this.state.age === "range"
						?
						<div className="range" key="range">
							<NumberField
								label="From"
								change={(err, val) => {
									this.itemChanged({ minAge: val })
								}}
							>
								{item.minAge}
							</NumberField>
							<NumberField
								label="To"
								change={(err, val) => {
									this.itemChanged({ maxAge: val })
								}}
							>
								{item.maxAge}
							</NumberField>
						</div>
						:
						null
					}
				</div>
				<div className="rules">
					<label>Rules:</label>
					{item.rules.map(rule => {
						let nutrientData = nutrientsList.find(n => n.name === rule.nutrient);
						if (!nutrientData) {
							console.log("No match for:", rule);
							// return null;
						}
						let measurement = rule.nutrient
							? (nutrientData ? nutrientData.measurement : "") : "";
						return (
							<div className="rule" key={rule.id}>
								<img
									className="remove-rule"
									src={binIcon}
									alt="bin-icon"
									onClick={() => {
										this.itemChanged({ rules: item.rules.filter(r => r.id !== rule.id) });
									}}
								/>
								<DropdownField
									options={nutrientsList.map(nutrient => nutrient.name)}
									change={(err, val) => {
										this.ruleChanged({ nutrient: val }, rule.id);
									}}
								>
									{rule.nutrient || ""}
								</DropdownField>
								<div className="range">
									<NumberField
										change={(err, val) => {
											this.ruleChanged({ min: val }, rule.id);
										}}
									>
										{rule.min}
									</NumberField>
									<img className="arrow-icon" src={arrowIcon} alt="arrow icon" />
									<NumberField
										change={(err, val) => {
											this.ruleChanged({ max: val }, rule.id);
										}}
									>
										{rule.max}
									</NumberField>
									<div style={{
										display: "inline-block",
										width: 80,
										marginLeft: 10
									}}>
										<DropdownField
											fullWidth={true}
											options={[
												measurement,
												"%"
											].filter(i => i)}
											change={(err, val) => {
												this.ruleChanged({ measurement: val }, rule.id);
											}}
										>
											{rule.measurement || measurement}
										</DropdownField>
									</div>
								</div>
							</div>
						)
					})}
					<div
						className="add-rule"
						onClick={() => {
							this.itemChanged({ rules: [...item.rules, { id: randomId(10) }] })
						}}
					>
						Add rule
                    </div>
				</div>
			</div>
		)
	}

}

export default DemographicList;