import React from "react";

const BarChart = (props) => {
    return (
        <div className="barChart">
            <div className="scale">
                <div 
                    className="value"
                    style={{
                        top: "0px"
                    }}
                >
                    {props.max}
                    <div className="line"></div>
                </div>
                <div 
                    className="value"
                    style={{
                        top: "50%"
                    }}
                >
                    {Math.ceil(props.max / 2)}
                    <div className="line"></div>
                </div>
                <div 
                    className="value"
                    style={{
                        top: "100%"
                    }}
                >
                    {props.min}
                    <div className="line"></div>
                </div>
            </div>
            <div className="graph">
                {props.values.map(bar => {
                    return (
                        <div
                            key={bar.val + bar.label} 
                            className="bar"
                            style={{
                                width: `calc(${100 / props.values.length}% - 7.5px)`,
                                backgroundColor: bar.color,
                                height: bar.val ? `${(bar.val * 100) / props.max}%` : "2px"
                            }}
                        >
                            <div className="number">{bar.val}</div>
                            <div className="label">{bar.label}</div>    
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

BarChart.defaultProps = {
    min: 0,
    max: 10
}

export default BarChart;