import $ from "jquery";
import React, { useState, useContext } from "react";
import Context from "../../context";
import Field from "./Field";
import { validateEmail } from "./helpers";
import { RESET_PASSWORD } from ".";

export default function ResetPasswordRequest({ onRequestFormChange }) {

	const [values, setValues] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [loginError, setLoginError] = useState(null);
    const context = useContext(Context);

	function fieldChange({ name, value, error }) {
		setValues({
			...values,
			[name]: value
		});
		setFieldErrors({
			...fieldErrors,
			[name]: error
		});
	}

	return <form onSubmit={e => {
		e.preventDefault();
		if (Object.values(fieldErrors).filter(e => e).length) return;
        onRequestFormChange(RESET_PASSWORD);
        $.ajax({
            type: "POST",
            url: `${context.apiRoot}/auth/password/request`,
            data: JSON.stringify(values),
            contentType: "application/json"
        }).done(res => {
            return;
        }).catch(err => {
            let error = err.message || err.responseJSON.message;
            setLoginError(error);
        })
	}}>

		<Field
			onChange={fieldChange}
			name="uname"
			values={values}
			errors={fieldErrors}
			autoComplete="email"
			placeholder="Email"
			validate={validateEmail}
		/>
		<button
			type="submit"
			disabled={Object.values(fieldErrors).filter(e => e).length}
		>Request reset</button>
		{loginError && <>
			<br />
            <br />
			<div className="error text-center">
				{loginError}
			</div>
		</>}
	</form>
}