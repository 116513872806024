/**
 * NAVIGATION
 * 
 *      PROPS:
 *          links: ARRAY of OBJECTS { name: "", path: "" } 
 */

import $ from "jquery";
import React from 'react';
import { NavLink } from "react-router-dom";

//Media import
import logo from "../media/RYG-Logo-Nav.svg";
import email from "../media/email.svg";
import userIcon from "../media/user.svg";
import facebook from "../media/facebook.svg";
import instagram from "../media/instagram.svg";
import linkedin from "../media/linkedin.svg";
import twitter from "../media/twitter.svg";
import Context from "../context";


class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileNav: false,
            openDropdown: null
        }
    }

    componentDidMount() {
        if (this.props.mode === "mobile" || this.props.mode === "tablet") {
            if (this.state.mobileNav) {
                $(".mobile-links").slideDown()
            } else {
                $(".mobile-links").slideUp()
            }
        }
    }

    componentDidUpdate() {
        //Mobile nav animation
        if (this.props.mode === "mobile" || this.props.mode === "tablet") {
            if (this.state.mobileNav) {
                $(".mobile-links").slideDown(200)
            } else {
                $(".mobile-links").slideUp(200)
            }
        }
    }

    renderLink(link) {
        // Ref to open link
        let open = this.state.openDropdown === link.name;
        // console.log(link)
        switch (link.type) {
            case "external":
                return <li key={`${link.name}`}>
                    <a href={link.path}>{link.name}</a>
                </li>
            case "link":
                return <li key={`${link.name}`}>
                    <NavLink
                        exact
                        activeClassName="current"
                        to={link.path || ""}
                        onClick={e => {
                            // If there is no path, stop it from navigating.
                            if (!link.path) e.preventDefault();
                            // Collapsing the mobile nav after clicking a link.
                            this.setState({ mobileNav: false });
                            // Run handler if there is one.
                            if (link.handler) {
                                link.handler(e);
                            }
                        }}
                    >
                        {link.name}
                    </NavLink>
                </li>
            case "dropdown":
                return (
                    <li
                        key={`${link.name}`}
                        className={"has-dropdown" + (open ? " isOpen" : "")}
                        onClick={() => {
                            let openDropdown = open ? null : link.name;
                            this.setState({ openDropdown });
                        }}>
                        <span>
                            {link.name}
                        </span>
                        <ul className={"subLinks " + link.position}>
                            {link.links.map(subLink => {
                                return this.renderSubLink(subLink)
                            })}
                        </ul>
                    </li>
                )
            case "component":
                return (
                    <li
                        key={`${link.name}`}
                        className={"has-dropdown" + (open ? " isOpen" : "")}
                        onClick={e => {
                            // Prevent it from closing on clicking component
                            if (!e.target.className.includes("component")) {
                                console.log(e.target.className);
                                let openDropdown = open ? null : link.name;
                                this.setState({ openDropdown });
                            }
                        }}
                    >
                        <span>
                            {link.name}
                        </span>
                        <ul className={"subLinks " + link.position} onClick={e => e.stopPropagation()}>
                            <li className={"subLink " + link.type + " " + link.class || ""} key={link.name}>
                                {link.component}
                            </li>
                        </ul>
                    </li>
                )
            default:
                return (
                    <div className="unsupported" />
                )
        }
    }

    renderSubLink(subLink) {
        switch (subLink.type) {
            case "external":
                return (
                    <li className="subLink" key={subLink.name} style={
                        subLink.width ? { width: subLink.width } : {}
                    }>
                        <a
                            href={subLink.path || ""}
                        >
                            {subLink.name}
                            {subLink.icon
                                ?
                                <img className="sublinkIcon" src={subLink.icon} alt="icon" />
                                :
                                null
                            }
                        </a>
                    </li>
                )
            case "link":
                return (
                    <li className="subLink" key={subLink.name} style={(subLink.display && subLink.display === "block") ? {
                        display: "block",
                        width: "initial"
                    } : {}}>
                        <NavLink
                            exact
                            activeClassName="current"
                            to={subLink.path || ""}
                            onClick={e => {
                                // If there is no path, stop it from navigating.
                                if (!subLink.path) e.preventDefault();
                                //Collapsing sub nav after clicking a subLink
                                this.setState(
                                    {
                                        mobileNav: false
                                    }
                                )
                                // Run handler if there is one.
                                if (subLink.handler) {
                                    subLink.handler(e);
                                }
                            }}
                        >
                            {subLink.name}
                            {subLink.icon
                                ?
                                <img className="sublinkIcon" src={subLink.icon} alt="icon" />
                                :
                                null
                            }
                        </NavLink>
                    </li>
                )
            case "component":
                return (
                    <li className={"subLink" + subLink.type + " " + subLink.class || ""} key={subLink.name}>
                        {subLink.name}
                        {subLink.component}
                    </li>
                )
            case "buttonLink":
                return (
                    <li className={"subLink " + subLink.type} key={subLink.buttonName}>
                        {subLink.name
                            ?
                            subLink.name
                            :
                            null
                        }
                        <NavLink
                            exact
                            className="linkButton shadow2"
                            style={{
                                marginTop: subLink.name ? "10px" : "0px",
                                padding: subLink.icon ? "13px 40px 13px 15px" : "13px 10px",
                                textAlign: subLink.icon ? "left" : "center"
                            }}
                            to={subLink.path || ""}
                        >
                            {subLink.buttonName}
                            {subLink.icon
                                ?
                                <img src={subLink.icon} alt="icon" />
                                :
                                null
                            }
                        </NavLink>
                    </li>
                )
            default:
                return (
                    <div className="unsupported" />
                )
        }
    }

    render() {
        // Generate links.
        let links = this.props.links.map(link => {
            return this.renderLink(link);
        })
        // mode controls which type of navigation is being rendered.
        let mode = this.props.mode;
        return (
            <header>
                {mode === "desktop"
                    ?
                    <div className="top-line">
                        <div className="left">
                            {Boolean(this.context.session.user) ? <div className="userSummary">
                                <img src={userIcon} alt="user icon" />
                                <span>{this.context.session.user.uname}</span>
                            </div> : null}
                        </div>
                        <div className="right">
                            <div className="email">
                                <a href="mailto:info@ryghealth.com">
                                    <img src={email} alt="email" />
                                    <span>INFO@RYGHEALTH.COM</span>
                                </a>
                            </div>
                            <div className="smicons">
                                <a href="https://www.facebook.com/resetyourhealthplan" target="_blank" rel="noopener noreferrer">
                                    <img src={facebook} alt="facebook" />
                                </a>
                                <a href="https://www.linkedin.com/company/reset-your-health" target="_blank" rel="noopener noreferrer">
                                    <img src={linkedin} alt="linkedin" />
                                </a>
                                <a href="https://www.instagram.com/resetyourhealthplan" target="_blank" rel="noopener noreferrer">
                                    <img src={instagram} alt="instagram" />
                                </a>
                                <a href="https://twitter.com/RYHPlan" target="_blank" rel="noopener noreferrer">
                                    <img src={twitter} alt="twitter" />
                                </a>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <div className="nav-links">
                    <div className="logo">
                        <a href="/" >
                            <img src={logo} alt="RYG logo" />
                        </a>
                    </div>
                    {mode === "mobile" || mode === "tablet"
                        ?
                        <div className="mobile-btn">
                            <div
                                className="inner"
                                onClick={() => {
                                    //Opening and hiding mobile nav
                                    this.setState({ mobileNav: !this.state.mobileNav })
                                }}
                            >
                                <span>MENU</span>
                                <div className={this.state.mobileNav ? "dots opened" : "dots"}>
                                    <div className="dot top"></div>
                                    <div className="dot mid"></div>
                                    <div className="dot bot"></div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {mode === "desktop"
                        ?
                        <ul key="desktop" className="desktop-links">
                            {/* {map over links prop} */}
                            {links}
                        </ul>
                        :
                        <ul key="mobile" className="mobile-links shadow1">
                            {/* {map over links prop} */}
                            {links}
                        </ul>
                    }
                </div>
            </header>
        )
    }
}

Navigation.contextType = Context;

export default Navigation