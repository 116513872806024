import $ from "jquery";
import React from "react";
import Tab from "./tab";
import RecipeCreator from "../creators/recipeCreator";
import Context from "../../../context";

//Media import
import gear from "../../../media/gear.svg";
import copy from "../../../media/copy.svg";

class Recipes extends Tab {

    renderItemOptions(item) {
        return <div className="options">
            <img
                src={copy}
                alt="copy recipe icon"
                className="option"
                onClick={() => {
                    $.post(`${this.context.apiRoot}/recipes/${item.id}/copy`).then(res => {
                        this.getItems();
                    }).catch(err => {
                        console.error(err);
                    })
                }}
            />
            <img src={gear} alt="edit item" />
        </div>
    }

    renderRowHeader(item) {
        if (!item) return "New Item";
        let months = [
            "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
        ];
        let activeMonths = (item.activeMonths || [])
            .map((v, i) => v ? months[i] : null).filter(i => i);
        return <span>
            <span>
                {item.name.toUpperCase()} #{item.id}
            </span> | {item.note ? <React.Fragment>
                <span className="c-featureColor4" >{item.note}</span> |
            </React.Fragment> : null} <span className="c-featureColor2">
                {activeMonths.length === 12 ? "ALL YEAR" : activeMonths.join(" ")}
            </span>
        </span>
    }

    itemCreator(item) {
        return (
            <RecipeCreator
                key={"recipeCreator" + (item ? item.id : "new")}
                item={item}
                displayName={this.displayName}
                itemUpdate={(changes, id) => {
                    this.editItem(changes, id)
                }}
                itemCreate={item => {
                    this.createItem(item)
                }}
                itemDelete={id => {
                    this.deleteItem(id)
                }}
                close={() => {
                    this.setState({ adding: false, selected: null })
                }}
            />
        )
    }
}

Recipes.contextType = Context;

export default Recipes;