import React, { useState, useEffect } from "react";

export default function Field({
	name,
	validate = false,
	onChange,
	values,
	errors,
	required = true,
	style = {},
    preProcess,
    label = false,
	...props
}) {

	const [focused, setFocused] = useState(false);

	const value = values[name] || "";
	const error = errors[name] || null;

	// Handle empty input fields.
	useEffect(() => {
		if (required && !value && !error) {
			onChange({ name, value, error: "This field is required." });
		}
	}, [value, required, onChange, name, error]);

	return <div className="field-wrapper" style={style} >
        {label && <label>{label}</label>}
		<input
			onFocus={e => {
				setFocused(true);
				if (props.onFocus) props.onFocus(e);
			}}
			onBlur={e => {
				setFocused(false);
				if (props.onBlur) props.onBlur(e);
			}}
			className={(required && !value) || error ? "bad" : "good"}
			name={name}
			value={values[name] || ""}
			onChange={e => {
				const value = preProcess ? preProcess(e.target.value) : e.target.value;
				let result = true;
				if (required && !value) {
					result = "This field is required.";
				} else if (validate) {
					result = validate(value);
				}
				onChange({
					value,
					error: result === true ? null : result,
					name
				});
			}}
			{...props}
		/>
		{(focused && error) && <div className="fieldTooltip">
			{error}
		</div>}
	</div>
}