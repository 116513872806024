const categories = [
    {
        id: 1,
        label: "Digestive Issues."
    },
    {
        id: 2,
        label: "Energy."
    },
    {
        id: 3,
        label: "Blood Sugar Control."
    },
    {
        id: 4,
        label: "Cravings."
    },
    {
        id: 5,
        label: "Mood."
    },
    {
        id: 6,
        label: "Brain Fog."
    },
    {
        id: 7,
        label: "Weight."
    },
    {
        id: 8,
        label: "Diet and Lifestyle."
    },
    {
        id: 9,
        label: "Stress."
    },
    {
        id: 10,
        label: "General."
    }
];
const questions = [
    {
        id: 1,
        category: 1,
        question: "Indigestion, heart burn, or acid reflux."
    },
    {
        id: 2,
        category: 1,
        question: "Uncomfortable bloating or pain after eating food."
    },
    {
        id: 3,
        category: 1,
        question: "Suffered from constipation or diarrhoea or general irregular bowel movements."
    },
    {
        id: 4,
        category: 1,
        question: "Troublesome flatulence (passage of wind)."
    },
    {
        id: 5,
        category: 1,
        question: "Reliant on regular medication to mitigate digestive symptoms."
    },
    {
        id: 6,
        category: 2,
        question: "Felt low in energy most of the time."
    },
    {
        id: 7,
        category: 2,
        question: "Reliant on caffeine or sugar to pick me up."
    },
    {
        id: 8,
        category: 2,
        question: "Felt too tired to do physical activity."
    },
    {
        id: 9,
        category: 2,
        question: "Felt tired on waking."
    },
    {
        id: 10,
        category: 3,
        question: "Extreme fluctuations in blood sugar."
    },
    {
        id: 11,
        category: 3,
        question: "Energy slumped after meals."
    },
    {
        id: 12,
        category: 4,
        question: "Craved sweet foods or drinks e.g. chocolate, biscuits, cakes, candy, fizzy drinks, juices."
    },
    {
        id: 13,
        category: 4,
        question: "Craved fast food, processed or fatty foods."
    },
    {
        id: 14,
        category: 4,
        question: "Craved bread, cereal or pasta."
    },
    {
        id: 15,
        category: 5,
        question: "Felt low/depressed or sad/upset."
    },
    {
        id: 16,
        category: 5,
        question: "Experienced apathy or lack of interest / no enthusiasm."
    },
    {
        id: 17,
        category: 5,
        question: "Found it difficult to make the best of difficult situations."
    },
    {
        id: 18,
        category: 5,
        question: "Felt anxious or unable to relax."
    },
    {
        id: 19,
        category: 5,
        question: "Felt irritable or agitated."
    },
    {
        id: 20,
        category: 5,
        question: "Felt worthless or that life was meaningless."
    },
    {
        id: 21,
        category: 5,
        question: "Experienced breathing difficulty."
    },
    {
        id: 22,
        category: 6,
        question: "Unable to think clearly or concentrate."
    },
    {
        id: 23,
        category: 6,
        question: "Found it difficult to make decisions and/or clarify and achieve my goals."
    },
    {
        id: 24,
        category: 7,
        question: "Overweight or underweight."
    },
    {
        id: 25,
        category: 7,
        question: "Gained weight or found it difficult to lose weight."
    },
    {
        id: 26,
        category: 8,
        question: "Consumed processed or convenience foods."
    },
    {
        id: 27,
        category: 8,
        question: "Consumed sweet/sugary foods/drinks e.g. chocolate, biscuits, cakes, candy, fizzy drinks, juices."
    },
    {
        id: 28,
        category: 8,
        question: "Consumed artificial sweeteners e.g. NutraSweet, Sweetex Aspartame, Splenda."
    },
    {
        id: 29,
        category: 8,
        question: "Consumed red and processed meats."
    },
    {
        id: 30,
        category: 8,
        question: "Consumed deep fat fried foods."
    },
    {
        id: 31,
        category: 8,
        question: "Consumed less than 5 servings of ‘whole foods’ a day."
    },
    {
        id: 32,
        category: 8,
        question: "Consumed less than 8 large glasses of water a day."
    },
    {
        id: 33,
        category: 8,
        question: "Consumed alcoholic beverages."
    },
    {
        id: 34,
        category: 8,
        question: "Smoked cigarettes or other substances."
    },
    {
        id: 35,
        category: 9,
        question: "Didn’t cope well with stress or over-reacted to situations."
    },
    {
        id: 36,
        category: 9,
        question: "Felt a physical impact of stress."
    },
    {
        id: 37,
        category: 9,
        question: "Went to bed feeling wired and tired at the same time."
    },
    {
        id: 38,
        category: 10,
        question: "Had sleep problems - either too much or too little."
    },
    {
        id: 39,
        category: 10,
        question: "Frequent ill health (more than once/twice a year)."
    },
    {
        id: 40,
        category: 10,
        question: "Sinus problems or nasal congestion."
    },
    {
        id: 41,
        category: 10,
        question: "Asthma, hay fever or airborne allergies."
    },
    {
        id: 42,
        category: 10,
        question: "Joint pain or swelling."
    }
];

export {
    categories,
    questions
}