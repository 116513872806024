import FlipMove from "react-flip-move";
import closeIcon from "../../media/icon-close.svg";
import ItemFinderField from "../../fields/itemFinderField";
import NumberField from "../../fields/numberField";
import React from "react";
import Context from "../../context";
import TextareaField from "../../fields/textareaField";
import $ from "jquery";
import DropdownField from "../../fields/dropdownField";

const allowedMeasurements = [
    "120g tin",
    "160ml tin",
    "400g tin",
    "400ml tin",
    "bulb",
    "clove",
    "dashes",
    "g",
    "handful",
    "kg",
    "ml",
    "pinch",
    "slice",
    "sprig",
    "stick",
    "tbsp",
    "tsp"
];

class IngredientManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            adding: null,
            editing: null,
            linkDescription: "",
            quantity: "",
            measurement: "",
            dragging: null,
            hoveringOn: null
        }
    }

    removeIngredient(id) {
        $.ajax({
            method: "DELETE",
            url: `${this.context.apiRoot}/recipes/${this.props.item.id}/ingredients/${id}`
        }).done(res => {
            this.props.onChange();
        }).catch(err => {
            console.error(err);
        });
    }

    updateIngredient(id, data) {
        $.ajax({
            method: "PUT",
            url: `${this.context.apiRoot}/recipes/${this.props.item.id}/ingredients/${id}`,
            data: JSON.stringify(data),
            contentType: "application/json"
        }).done(res => {
            this.props.onChange();
            this.setState({ editing: null })
        }).catch(err => {
            console.error(err);
        });
    }

    renderControls() {
        return <FlipMove
            className="controls"
            duration={200}
            enterAnimation="fade"
            leaveAnimation="fade"
        >
            {Number(this.state.quantity) ? <div
                className="button"
                onClick={e => {
                    let data = {
                        ingredient: this.state.adding.id,
                        description: this.state.linkDescription,
                        measurement: this.state.measurement || "",
                        quantity: Number(this.state.quantity)
                    };
                    $.post({
                        url: `${this.context.apiRoot}/recipes/${this.props.item.id}/ingredients`,
                        data: JSON.stringify(data),
                        contentType: "application/json"
                    }).done(res => {
                        if (this.props.onChange) this.props.onChange();
                        this.setState({
                            adding: null,
                            linkDescription: "",
                            quantity: "",
                            measurement: ""
                        });
                    }).catch(err => {
                        console.error("Error adding ingredient: ", err);
                    });
                }}
            >Save</div> : null}
            <div
                className="button cancelEdit"
                onClick={e => {
                    this.setState({
                        adding: null,
                        linkDescription: "",
                        quantity: "",
                        measurement: ""
                    });
                }}
            >Cancel</div>
        </FlipMove>
    }

    changeOrder(newOrder) {
        $.post({
            url: `${this.context.apiRoot}/recipes/${this.props.item.id}/ingredients/order`,
            data: JSON.stringify(newOrder),
            contentType: "application/json"
        }).done(res => {
            if (this.props.onChange) this.props.onChange();
        }).catch(err => {
            console.error("Error re-ordering ingredients: ", err);
        });
    }

    render() {
        return (
            <div className="ingredientManager">
                <FlipMove
                    className="ingredients"
                    enterAnimation="fade"
                    leaveAnimation="fade"
                    duration={200}
                >
                    {this.props.ingredients
                        .sort((a, b) => (
                            Number(a.order) - Number(b.order)
                        ))
                        .map((ingredient, index) => (
                            (this.state.editing && this.state.editing.id === ingredient.id)
                                ?
                                <IngredientEditor
                                    key={ingredient.id}
                                    item={ingredient}
                                    onSave={values => {
                                        this.updateIngredient(ingredient.id, values);
                                    }}
                                    onCancel={() => {
                                        this.setState({
                                            editing: null
                                        });
                                    }}
                                />
                                :
                                <div
                                    key={ingredient.id}
                                    className={"ingredient" + ((index === this.state.hoveringOn && index !== this.state.dragging) ? " isDragTarget" : "")}
                                    onDragOver={e => {
                                        if (this.state.dragging !== null) {
                                            this.setState({ hoveringOn: index });
                                        }
                                    }}
                                    onClick={e => {
                                        if (
                                            e.target.className.includes("removeIngredient") ||
                                            e.target.className.includes("reorderArrow") ||
                                            e.target.className.includes("reorderDrag")
                                        )
                                            return null;
                                        this.setState({ editing: ingredient, adding: null })
                                    }}
                                >
                                    {ingredient.name} - <i>{ingredient.quantity} {ingredient.measurement}</i>
                                    {!this.state.editing ? <img
                                        className="removeIngredient"
                                        title="Delete."
                                        src={closeIcon}
                                        alt="remove icon"
                                        onClick={() => {
                                            if (window.confirm("Are you sure you want to remove this ingredient?"))
                                                this.removeIngredient(ingredient.id);
                                        }}
                                    /> : null}
                                    <div className="reorder">
                                        {!this.state.editing ? <span
                                            className="reorderDrag"
                                            title="Click and drag to move."
                                            draggable
                                            onDragEnd={e => {
                                                // If we drop somewhere different, move the item.
                                                if (
                                                    this.state.hoveringOn !== null &&
                                                    this.state.hoveringOn !== index
                                                ) {
                                                    let newOrder = this.props.ingredients.map(i => i.id);
                                                    let moving = newOrder.splice(this.state.dragging, 1)[0];
                                                    newOrder.splice(this.state.hoveringOn, 0, moving);
                                                    this.changeOrder(newOrder);
                                                }
                                                this.setState({
                                                    dragging: null, hoveringOn: null
                                                })
                                            }}
                                            onDragStart={e => {
                                                this.setState({
                                                    dragging: index
                                                })
                                            }}
                                        >{"="}</span> : null}
                                    </div>
                                </div>
                        ))}
                </FlipMove>
                <br />
                {this.state.adding
                    ?
                    <div className="tagEditing">
                        <div>Adding <b>{this.state.adding.name}</b>.</div>
                        <div>Provide an optional ingredient description:</div>
                        <br />
                        <TextareaField
                            label="Description"
                            change={(err, linkDescription) => {
                                this.setState({ linkDescription });
                            }}
                        >
                            {this.state.linkDescription}
                        </TextareaField>
                        <NumberField
                            label="Quantity"
                            change={(err, quantity) => {
                                this.setState({ quantity });
                            }}
                        >
                            {this.state.quantity}
                        </NumberField>
                        <DropdownField
                            label="Measurement"
                            options={allowedMeasurements}
                            change={(err, measurement) => {
                                this.setState({ measurement });
                            }}
                        >
                            {this.state.measurement}
                        </DropdownField>
                        {this.renderControls()}
                    </div>
                    :
                    <ItemFinderField
                        label="Add a new ingredient"
                        itemName="ingredients"
                        change={(err, ingredient) => {
                            this.setState({
                                adding: ingredient
                            })
                        }}
                    />
                }
            </div>
        )
    }
}

class IngredientEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description: this.props.item.quantity_description,
            quantity: this.props.item.quantity,
            measurement: this.props.item.measurement,
            canSubmit: false
        }
    }

    render() {
        let item = this.props.item;
        return (
            <div className="tagEditor" key={"tagEditor" + item.name + item.id}>
                <div className="heading">{item.name}</div>
                <TextareaField
                    label="Description"
                    change={(err, description) => {
                        this.setState({ description, canSubmit: true });
                    }}
                >
                    {this.state.description}
                </TextareaField>
                <NumberField
                    label="Quantity"
                    change={(err, quantity) => {
                        this.setState({ quantity, canSubmit: true });
                    }}
                >
                    {this.state.quantity}
                </NumberField>
                <DropdownField
                    label={<span>
                        Measurement
                        {
                            this.state.measurement &&
                                !allowedMeasurements.includes(this.state.measurement) ?
                                <span style={{ color: "red" }}> - NEEDS RESETTING</span> : null
                        }
                    </span>}
                    options={allowedMeasurements}
                    change={(err, measurement) => {
                        this.setState({ measurement, canSubmit: true });
                    }}
                >
                    {this.state.measurement}
                </DropdownField>
                <FlipMove
                    className="controls"
                    duration={200}
                    enterAnimation="fade"
                    leaveAnimation="fade"
                >
                    {this.state.canSubmit ? <div
                        key={"edit"}
                        className="button editTagBtn"
                        onClick={() => {
                            this.setState({
                                canSubmit: false
                            });
                            this.props.onSave({
                                description: this.state.description,
                                quantity: Number(this.state.quantity),
                                measurement: this.state.measurement || ""
                            });
                        }}
                    >
                        Save
                    </div> : null}
                    <div
                        key={"cancel"}
                        className="button cancelEdit"
                        onClick={() => {
                            this.props.onCancel();
                        }}
                    >
                        Cancel
                    </div>
                </FlipMove>
            </div>
        )
    }
}

IngredientManager.contextType = Context;

export default IngredientManager;