/**
 *  Ingredient item structure: 
 *  
 *      {
 *          category: "string"
            description: "string" || null
            id: "integer"
            name: "string"
            recipe: "integer" || null
 *      }
 */

import $ from "jquery";
import React from "react";
import Context from "../../../context";
import ItemCreator from "./itemCreator";
import TextField from "../../../fields/textField";
import TextareaField from "../../../fields/textareaField";
import ImageField from "../../../fields/imageField";
import DropdownField from "../../../fields/dropdownField"
import ItemFinderField from "../../../fields/itemFinderField";
import NumberField from "../../../fields/numberField";
import NutritionCsvField from "../../../fields/nutritionCsvField";
import TagManager from "../tagManager";

// Media import
import redCloseIcon from "../../../media/icon-close-red.svg"

class IngredientCreator extends ItemCreator {
    constructor(props) {
        super(props)
        this.defaultNutrients = [
            {
                name: "energy(kcal)",
                value: 0,
                measurement: "kcal"
            },
            {
                name: "energy(kj)",
                value: 0,
                measurement: "kJ"
            },
            {
                name: "carbohydrate",
                value: 0,
                measurement: "g"
            },
            {
                name: "protein",
                value: 0,
                measurement: "g"
            },
            {
                name: "fat",
                value: 0,
                measurement: "g"
            },
            {
                name: "water",
                value: 0,
                measurement: "g"
            },
            {
                name: "water_from_drinks",
                value: 0,
                measurement: "g"
            },
            {
                name: "alcohol",
                value: 0,
                measurement: "g"
            },
            {
                name: "starch",
                value: 0,
                measurement: "g"
            },
            {
                name: "oligosaccharide",
                value: 0,
                measurement: "g"
            },
            {
                name: "fibre",
                value: 0,
                measurement: "g"
            },
            {
                name: "nsp",
                value: 0,
                measurement: "g"
            },
            {
                name: "sugars",
                value: 0,
                measurement: "g"
            },
            {
                name: "free_sugars",
                value: 0,
                measurement: "g"
            },
            {
                name: "glucose",
                value: 0,
                measurement: "g"
            },
            {
                name: "galactose",
                value: 0,
                measurement: "g"
            },
            {
                name: "fructose",
                value: 0,
                measurement: "g"
            },
            {
                name: "sucrose",
                value: 0,
                measurement: "g"
            },
            {
                name: "maltose",
                value: 0,
                measurement: "g"
            },
            {
                name: "lactose",
                value: 0,
                measurement: "g"
            },
            {
                name: "saturated_fat",
                value: 0,
                measurement: "g"
            },
            {
                name: "monounsaturated_fat",
                value: 0,
                measurement: "g"
            },
            {
                name: "cis-mono",
                value: 0,
                measurement: "g"
            },
            {
                name: "polyunsaturated_fat",
                value: 0,
                measurement: "g"
            },
            {
                name: "omega3(n-3)",
                value: 0,
                measurement: "g"
            },
            {
                name: "omega6(n-6)",
                value: 0,
                measurement: "g"
            },
            {
                name: "cis-poly",
                value: 0,
                measurement: "g"
            },
            {
                name: "trans-fatty_acids",
                value: 0,
                measurement: "g"
            },
            {
                name: "cholesterol",
                value: 0,
                measurement: "mg"
            },
            {
                name: "sodium",
                value: 0,
                measurement: "mg"
            },
            {
                name: "potassium",
                value: 0,
                measurement: "mg"
            },
            {
                name: "chloride",
                value: 0,
                measurement: "mg"
            },
            {
                name: "calcium",
                value: 0,
                measurement: "mg"
            },
            {
                name: "phosphorus",
                value: 0,
                measurement: "mg"
            },
            {
                name: "magnesium",
                value: 0,
                measurement: "mg"
            },
            {
                name: "iron",
                value: 0,
                measurement: "mg"
            },
            {
                name: "zinc",
                value: 0,
                measurement: "mg"
            },
            {
                name: "copper",
                value: 0,
                measurement: "mg"
            },
            {
                name: "manganese",
                value: 0,
                measurement: "mg"
            },
            {
                name: "selenium",
                value: 0,
                measurement: "ug"
            },
            {
                name: "iodine",
                value: 0,
                measurement: "ug"
            },
            {
                name: "vitamin_a_(ret_eq)",
                value: 0,
                measurement: "ug"
            },
            {
                name: "retinol",
                value: 0,
                measurement: "ug"
            },
            {
                name: "carotene",
                value: 0,
                measurement: "ug"
            },
            {
                name: "vitamin_d",
                value: 0,
                measurement: "ug"
            },
            {
                name: "vitamin_e",
                value: 0,
                measurement: "mg"
            },
            {
                name: "vitamin_k_1",
                value: 0,
                measurement: "ug"
            },
            {
                name: "thiamin_(b1)",
                value: 0,
                measurement: "mg"
            },
            {
                name: "riboflavin_(b2)",
                value: 0,
                measurement: "mg"
            },
            {
                name: "niacin_total_(b3)",
                value: 0,
                measurement: "mg"
            },
            {
                name: "niacin",
                value: 0,
                measurement: "mg"
            },
            {
                name: "tryptophan",
                value: 0,
                measurement: "mg"
            },
            {
                name: "pantothenic_acid_(b5)",
                value: 0,
                measurement: "mg"
            },
            {
                name: "vitamin_b_6",
                value: 0,
                measurement: "mg"
            },
            {
                name: "folates_(b9)_total",
                value: 0,
                measurement: "ug"
            },
            {
                name: "vitamin_b_12",
                value: 0,
                measurement: "ug"
            },
            {
                name: "biotin_(b7)",
                value: 0,
                measurement: "ug"
            },
            {
                name: "vitamin_c",
                value: 0,
                measurement: "mg"
            },
            {
                name: "gi_(estimated)",
                value: 0,
                measurement: ""
            },
            {
                name: "gl",
                value: 0,
                measurement: ""
            },
            {
                name: "caffeine",
                value: 0,
                measurement: "mg"
            }
        ]
    }

    canSubmit() {
        if (this.props.item) {
            //If updating an existing ingredient
            let canSubmit = false;
            Object.keys(this.state.changes).forEach(key => {
                if (key && this.state.changes[key] !== undefined) {
                    canSubmit = true;
                }
            });
            if (canSubmit) return true;
        } else {
            if (this.state.item.name) {
                return true;
            }
        }
        //Return false by default.
        return false;
    }

    getTags(id) {
        $.get(`${this.context.apiRoot}/ingredients/${id}/tags`).done(res => {
            this.setState({ tags: res.tags });
        }).catch(err => {
            console.error(err);
        });
    }

    renderTagManager(item) {
        // Only showing tags when editing an item
        return (
            <TagManager
                onChange={() => {
                    if (this.getTags) this.getTags(item.id);
                }}
                tagType="ingredients"
                item={item}
                tags={this.state.tags}
            />
        )
    }

    renderNutritionalInfo(nutrients) {
        return nutrients
            .map(item => {
                return (
                    <div className="nutritional" key={item.name}>
                        <NumberField
                            label={item.name}
                            placeholder="value"
                            change={(err, value) => {
                                this.itemChanged("nutrients", nutrients.map(i => {
                                    if (i.name !== item.name) return i;
                                    return Object.assign({}, item, {
                                        value
                                    });
                                }));
                            }}
                        >
                            {item.value}
                        </NumberField>
                        {item.measurement
                            ?
                            <div className="measurement">{item.measurement}</div>
                            :
                            null
                        }
                    </div>
                )
            })
    }

    renderCreator() {
        // console.log("props:", this.props);
        return (
            <div className="creatorFields">
                <ImageField
                    label="Icon"
                    change={(err, val) => {
                        this.itemChanged("icon", val)
                    }}
                >
                    {this.state.item.icon ? this.state.item.icon : (
                        this.props.item ?
                            `https://static.resetyourhealth.com/static/media/ingredient_icons/${this.state.item.id}.svg`
                            : null
                    )}
                </ImageField>
                <TextField
                    label="Name"
                    change={(err, val) => {
                        this.itemChanged("name", val)
                    }}
                >
                    {this.state.item.name}
                </TextField>
                <TextareaField
                    label="Description"
                    change={(err, val) => {
                        this.itemChanged("description", val)
                    }}
                >
                    {this.state.item.description}
                </TextareaField>
                <DropdownField
                    label="Category"
                    options={["cupboard", "fresh", "frozen", "storeCupboard"]}
                    change={(err, val) => {
                        this.itemChanged("category", val)
                    }}

                >
                    {this.state.item.category}
                </DropdownField>
                <DropdownField
                    label="Base Measurement Type - IMPORTANT FOR SHOPPING LIST."
                    options={["weight", "fluid", "NONE"]}
                    change={(err, val) => {
                        this.itemChanged("baseMeasurement", val === "NONE" ? "" : val)
                    }}
                >
                    {this.state.item.baseMeasurement}
                </DropdownField>
                <div className="baseWeight">
                    <NumberField
                        label="Base Weight"
                        placeholder="Value in grams"
                        change={(err, val) => {
                            this.itemChanged("baseWeight", val);
                        }}
                    >
                        {this.state.item.baseWeight}
                    </NumberField>
                </div>
                {this.state.item.recipe ?
                    <div className="ingredientRecipe">
                        <span className="name">Linked recipe:</span>
                        <span className="value">
                            {this.state.item.recipe}
                            <img
                                alt="close icon"
                                src={redCloseIcon}
                                onClick={() => {
                                    this.itemChanged("recipe", null);
                                }}
                            />
                        </span>
                    </div>
                    :
                    null
                }
                <ItemFinderField
                    label={this.state.item.recipe ? null : "Link a recipe"}
                    itemName="recipes"
                    change={(err, val) => {
                        this.itemChanged("recipe", val.id);
                    }}
                />
                {this.state.item.recipe ? null : <div className="nutritionals">
                    <NutritionCsvField
                        change={(err, nutrients) => {
                            this.itemChanged("nutrients", nutrients);
                        }}
                    />
                    <div className="nutritionalGroup">
                        {/* {this.renderNutritionalInfo(
                            (this.state.item.nutrients && this.state.item.nutrients.length) ? this.state.item.nutrients : this.defaultNutrients
                        )} */}
                        {this.renderNutritionalInfo(this.defaultNutrients.map(nutrient => {
                            // See if the ingredient has this property.
                            let match = (this.state.item.nutrients || [])
                                .filter(i => i.name === nutrient.name)[0];
                            // If so, return that.
                            if (match) return match;
                            // Otherwise, return the blank one.
                            return Object.assign({}, nutrient);
                        }))}
                    </div>
                </div>}
            </div>
        )
    }
}

IngredientCreator.contextType = Context;

export default IngredientCreator;
