import React from "react";
import Field from "./field";

class NumberField extends Field {
    constructor(props) {
        super(props)
        this.settings = {
            type: "numberField"
        }
        this.state.displayValue = Number(props.children || "");
    }

    componentDidUpdate() {
        if (Number(this.props.children || "") !== Number(this.state.displayValue))
            this.setState({
                displayValue: Number(this.props.children || "")
            })
    }

    renderField() {
        return (
            <input
                placeholder={this.props.placeholder}
                type="text"
                value={this.state.displayValue}
                onChange={e => {
                    if (e.target.value.split('.').length > 2)
                        return;
                    let displayValue = e.target.value
                        .replace(/[^\d\.]/g, "")
                        .replace(/\.{2,}/g, ".");
                    this.setState({ displayValue }, () => {
                        this.change(
                            Number(displayValue)
                        );
                    });
                }}
            />
        )
    }

}

export default NumberField;

