/**
 *  PROPS:
 * 
 *      items: [Array] of objects.
 *      type: "String" - added to the css classes and used for different styling
 */
import $ from "jquery";
import React from "react";
import Context from "../../context";

//Media import (project specific)
import chevronLeft from "../../media/icon-chevron-left.svg";
import chevronRight from "../../media/icon-chevron-right.svg";

class Carousel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            position: 0
        }
        this.onResize = this._onResize.bind(this);
    }

    _onResize() {
        this.animate();
    }

    componentDidMount() {
        //Resize handler which check window width and changes the state.mode accordingly.
        $(window).on("resize", this.onResize);
    }

    componentWillUnmount() {
        //Clearing any bound events
        $(window).off("resize", this.onResize);
    }

    renderItems() {
        return 'Overwrite me!!!'
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.position !== this.state.position) {
            //if position changed animate the carousel
            this.animate()
        }    
    }

    animate() {
        //Getting the width of a single carousel item
        let moveBy = $(".carousel .carouselItem").outerWidth();
        //Calculating the position the carousel should move to
        let moveTo = moveBy * this.state.position;
        //Moving the carousel
        $(".carousel .items").css("transform", `translateX(${-moveTo}px)`);
    }

    render() {
        return (
            <div className={`carousel ${this.props.type}`}>
                {this.state.position > 0
                    ?
                    <img
                        alt="<"
                        src={chevronLeft}
                        className="chevron left"
                        onClick={() => {
                            this.setState({
                                position: this.state.position - 1
                            })
                        }}
                    />
                    :
                    null
                }
                {this.state.position < (this.props.items.length - 1)
                    ?
                    <img
                        alt=">"
                        src={chevronRight}
                        className="chevron right"
                        onClick={() => {
                            this.setState({
                                position: this.state.position + 1
                            })
                        }}
                    />
                    :
                    null
                }
                <div className="outer">
                    <div className="items">
                        {this.renderItems()}
                    </div>
                </div>
            </div>
        )
    }
}

Carousel.contextType = Context;

export default Carousel;