import $ from "jquery";
import React from "react";
import Context from "../../../context";
import Content from "../../../assets/content";
import { NavLink } from "react-router-dom";

class RecipesToPrep extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            recipes: []
        }
        this.gotRecipes = false;
    }

    componentDidMount() {
        // Get recipes
        if (Boolean(this.context.session.user) && !this.gotRecipes) {
            this.gotRecipes = true;
            this.getRecipes();
        }
    }

    componentDidUpdate() {
        // Get recipes
        if (Boolean(this.context.session.user) && !this.gotRecipes) {
            this.gotRecipes = true;
            this.getRecipes();
        }
    }

    getRecipes() {
        $.get({
            url: `${this.context.apiRoot}/recipes/by-type/basic?eligibleOnly=true`
        }).done(res => {
            this.setState({ recipes: res.results })
        }).catch(err => {
            console.error("Error getting recipes", err);
        })
    }

    renderRecipes() {
        if (!this.state.recipes.length) return null;
        return this.state.recipes.map(recipe => {
            return (
                <div className="col-12 col-md-6 col-lg-4 recipe-col" key={recipe.id}>
                    <div className="inner shadow1">
                        <div
                            className="mealImage"
                            style={{
                                backgroundImage: (recipe.images && recipe.images[0]) ?
                                    `url("${recipe.images[0]}")` :
                                    `url("/static/media/meals/${recipe.name}.jpg")`
                            }}
                        />
                        <div className="name">{recipe.name}</div>
                        <div className="description">{`${recipe.description ? recipe.description.substr(0, 175) : ""}...`}</div>
                        <NavLink
                            exact
                            to={`/account/recipe/${recipe.id}`}
                            className="button viewRecipe"
                        >
                            View
                        </NavLink>
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <div className="recipesToPrepView container">
                <div className="row">
                    <div className="col-12">
                        <div className="heading">
                            <Content>essential-recipes-page-text-top</Content>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {this.renderRecipes()}
                </div>
            </div>
        )
    }

}

RecipesToPrep.contextType = Context;

export default RecipesToPrep;

