import React from 'react';
import Content from '../../../assets/content';

const andBeyond = props => {
    return (
        <div className="beyondView container textSection">
            <div className="row">
                <div className="col-12">
                    <h1><Content>and-beyond-page-title</Content></h1>
                </div>
            </div>
            <div className="row first-row">
                <div className="col-12 col-md-7 order-md-last">
                    <Content>and-beyond-page-text-1</Content>
                </div>
                <div className="col-12 col-md-5 order-md-first">
                    <div className="imageFrame shadow2">
                        <Content>and-beyond-page-img-1</Content>
                    </div>    
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-8">
                    <Content>and-beyond-page-text-2</Content>
                </div>
                <div className="col-12 col-md-4">
                    <div className="imageFrame shadow2">
                        <Content>and-beyond-page-img-2</Content>
                    </div>    
                </div>
            </div>
        </div>
    )
}

export default andBeyond;