import React from "react";
import Field from "./field";
import TextField from "./textField";
import Context from "../context";
import $ from "jquery";

class ImageField extends Field {
    constructor(props) {
        super(props)
        this.settings = {
            type: "imageField"
        }
        this.state.uploading = false;
    }

    handleUpload(file) {
        $.post({
            url: `${this.context.apiRoot}/files/static`,
            // Make the path relative to the current folder.
            data: JSON.stringify({
                path: this.props.filePrefix ? `${this.props.filePrefix}/${file.name}` : file.name,
                type: file.type
            }),
            contentType: "application/json"
        }).done(response => {
            // Response is a signed S3 URL with permission to upload.
            let self = this;
            let xhr = new XMLHttpRequest()
            xhr.onreadystatechange = function () {
                if (this.readyState === 4) {
                    if (this.status === 200) {
                        // Set the state for rendering.
                        self.setState({ uploading: false }, () => {
                            self.change("https://static.resetyourhealth.com/"+response.fileName);
                        });
                    } else {
                        // Log failed upload.
                        console.error(`Couldn't upload file '${file.name}'.`, xhr.err);
                        self.setState({ uploading: false });
                    }
                }
            };
            xhr.open("PUT", response.url);
            xhr.setRequestHeader("Content-Type", file.type);
            xhr.send(file);
        }).catch(err => {
            console.error("Error getting S3 signed url: ", err);
            this.setState({ uploading: false });
        });
    }

    renderField() {
        let randId = Math.random();
        return <React.Fragment>
            <div className="image-preview" >
                <div style={this.props.children ? {
                    backgroundImage: `url("${this.props.children}")`
                } : {}} />
            </div>
            <div className="rightHandSide">
                <label
                    htmlFor={`image-upload-label-${randId}`}
                    className="button"
                >
                    Upload.
                </label>
                <TextField
                    change={(err, val) => {
                        this.change(val || null);
                    }}
                >
                    {this.props.children}
                </TextField>
            </div>
            <input
                style={{ display: "none" }}
                id={`image-upload-label-${randId}`}
                type="file"
                onChange={e => {
                    this.setState({
                        uploading: true
                    });
                    this.handleUpload(e.target.files[0])
                }}
            />
        </React.Fragment>
    }

}

ImageField.contextType = Context;

export default ImageField;

