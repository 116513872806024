import React from "react";
import Tab from "./tab";
//import ItemCreator from "../creators/itemCreator";
import Context from "../../../context";
import $ from "jquery";
import { questions } from "../../../assets/questionnaireQuestions";
import BarChart from "../../../assets/barChart";

// class Questionnaire extends ItemCreator {
//     renderCreator() {
//         return <div>
//             Hello
//         </div>
//     }
// }

class Questionnaires extends Tab {

    constructor(props, context) {
        super(props, context);
        this.displayName = "id";
        this.addingDisabled = true;
        this.state.before = null;
        this.state.after = null;
    }

    getItems() {
        $.get(`${this.context.apiRoot}/${this.props.route}`).done(res => {

            function average(type) {
                // Filter out only relevant questions.
                let questions = res.results.filter(q => q.type === type);
                let result = {
                    values: {},
                    gender: {},
                    ageRange: {},
                    count: questions.length
                }
                // Iterate the questions.
                questions.forEach(q => {
                    // Add up the values.
                    Object.keys(q.answers).forEach(key => {
                        result.values[key] = (result.values[key] || 0) + q.answers[key];
                    });
                    result.gender[q.gender] = (result.gender[q.gender] || 0) + 1;
                    result.ageRange[q.ageRange] = (result.ageRange[q.ageRange] || 0) + 1;
                });
                // And create a mean average.
                Object.keys(result.values).forEach(key => {
                    result.values[key] = Math.round(result.values[key] / questions.length);
                });
                Object.keys(result.gender).forEach(key => {
                    result.gender[key] = Math.round(result.gender[key] / questions.length);
                });
                Object.keys(result.ageRange).forEach(key => {
                    result.ageRange[key] = Math.round(result.ageRange[key] / questions.length);
                });
                return result;
            }

            this.setState({
                items: res.results,
                before: average("before"),
                after: average("after")
            });

        }).catch(err => {
            console.error(`Error getting ${this.props.route}`, err);
        });
    }

    findHighest(values) {
        let highest = 0;
        Object.keys(values).forEach(key => {
            if (values[key] > highest) highest = values[key];
        });
        return highest;
    }

    renderBelow() {
        // console.log(this.state.after, this.state.before);
        if (!this.state.after || !this.state.before) return null;
        return <div className="container">
            <div className="row">
                <div className="col-12">
                    <br />
                    <hr />
                </div>
                <div className="col-12">
                    <h2 className="title">Average Results</h2>
                    <div>
                        Pre-plan questionnaires: {this.state.before.count}
                    </div>
                    <div>
                        Post-plan questionnaires: {this.state.after.count}
                    </div>
                </div>
                <div className="col-12">
                    <hr />
                </div>

                {/* Age */}
                <div className="col-12 col-md-8 result">
                    <div className="inner">
                        <label>Age ranges.</label>
                        <BarChart
                            max={this.findHighest(this.state.before.ageRange) + 1}
                            min={0}
                            values={[
                                "Under 18",
                                "18 - 24",
                                "25 - 34",
                                "35 - 44",
                                "45 - 54",
                                "55 - 64",
                                "65+",
                            ].map(metric => {
                                return {
                                    label: metric,
                                    val: this.state.before.ageRange[metric] || 0,
                                    color: "#fcb316"
                                }
                            })}
                        />
                    </div>
                </div>

                {/* Gender */}
                <div className="col-12 col-md-4 result">
                    <div className="inner">
                        <label>Genders.</label>
                        <BarChart
                            max={this.findHighest(this.state.before.gender) + 1}
                            min={0}
                            values={[
                                "Male",
                                "Female",
                                "Other/Prefer not to say",
                            ].map((metric, index) => {
                                return {
                                    label: index === 2 ? "Other" : metric,
                                    val: this.state.before.gender[metric] || 0,
                                    color: "#c1d72d"
                                }
                            })}
                        />
                    </div>
                </div>

                {/* 1-10 questions */}
                {questions.map(question => {
                    return <div className="col-12 col-md-6 col-lg-4 result" key={question.id}>
                        <div className="inner">
                            <label>{question.question}</label>
                            <BarChart
                                values={[
                                    {
                                        label: "Before",
                                        val: this.state.before.values[question.id] || 0,
                                        color: "#fcb316"
                                    },
                                    {
                                        label: "After",
                                        val: this.state.after.values[question.id] || 0,
                                        color: "#c1d72d"
                                    },
                                ]}
                            />
                        </div>
                    </div>
                })}
            </div>
        </div>
    }

    renderRowHeader(item) {
        return `${item.uname} - ${item.type} #${item.id}`.toUpperCase();
    }

    renderContent() {
        let query = this.state.query.toLowerCase();
        let items = this.state.items.filter(item => {
            if (query) {
                return item.uname.toLowerCase().includes(query);
            } else {
                return true;
            }
        });
        //Limit results to 20 items per page (if more than 20 items)
        if (items.length > 15) {
            let start = this.state.page * 15;
            let end = start + 15;
            items = items.slice(start, end);
        }
        return items.map(item => {
            if (item.id === this.state.selected) {
                //If you want to edit thi item
                return this.itemCreator(item);
            } else {
                return (
                    <div
                        className="item"
                        key={item.id}
                        onClick={e => {
                            // if (e.target.className === "option") return null;
                            // this.setState({ selected: item.id, adding: false })
                        }}
                    >
                        {this.renderRowHeader(item)}
                    </div>
                )
            }
        })
    }

    itemCreator(item) {
        return null;
        // return (
        //     <Questionnaire
        //         key={"questionnaire" + (item ? item.id : "new")}
        //         displayName={this.displayName}
        //         item={item}
        //         close={() => {
        //             this.setState({ adding: false, selected: null })
        //         }}
        //     />
        // )
    }

}

Questionnaires.contextType = Context;

export default Questionnaires;