import $ from "jquery";
import React from 'react';
import Context from "../../../context";
import Content from "../../../assets/content";
import SliderField from "../../../fields/sliderField";
import BarChart from "../../../assets/barChart";
import FlipMove from "react-flip-move";
import { questions, categories } from "../../../assets/questionnaireQuestions";
import DropdownField from "../../../fields/dropdownField";

class Questionnaire extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            answers: {},
            category: 1,
            completed: false,
            working: false,
            ageRange: "25 - 34",
            gender: "Female"
        }
        this.ready = false;
        this.working = false;

    }

    componentDidMount() {
        $("html, body").scrollTop(0);
    }

    renderQuestion(question) {
        return <div className="col-12 question" key={question.id}>
            <SliderField
                label={question.question}
                validateAtStart={true}
                validator={value => {
                    if (typeof value !== "number") {
                        return "Please fill out this field."
                    }
                    return true;
                }}
                change={(err, val) => {
                    this.setState({
                        answers: {
                            ...this.state.answers,
                            [question.id]: val
                        }
                    });
                }}
            >
                {this.state.answers[question.id]}
            </SliderField>
            <br />
            <hr />
            <br />
        </div>
    }

    renderResult(question) {
        return <div className="col-12 col-md-6 col-lg-4 result" key={question.id}>
            <div className="inner">
                <label>{question.question}</label>
                <BarChart
                    values={[
                        {
                            label: "Before",
                            val: this.props.questionnaires[0].answers[question.id] || 0,
                            color: "#fcb316"
                        },
                        {
                            label: "After",
                            val: this.props.questionnaires[1].answers[question.id] || 0,
                            color: "#c1d72d"
                        },
                    ]}
                />
            </div>
        </div>
    }

    render() {
        let lastQuestion = null;
        let onLastPage = (this.state.category >= categories.length);

        let sectionCompleted = (() => {
            if (!["before", "after"].includes(this.props.submitType)) return;
            let result = true;
            questions
                .filter(q => q.category === this.state.category)
                .forEach(q => {
                    if (typeof this.state.answers[q.id] !== "number")
                        result = false;
                });
            return result;
        })();


        if (this.props.submitType === null) return "Loading...";

        if (this.state.completed === true) return (
            <div className="questionnaire">
                <div className="container">
                    <div className="row">
                        <div className="col-12" style={{ textAlign: "center", minHeight: "50vh" }}>
                            <br /><br /><br />
                            <h1>Thank you for completing your survey!</h1>
                            <br />
                            <p>
                                Head back to your <a href="/account/meal-plan/week/1">plan</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )

        return (
            <div className="questionnaire">
                <div className="container">
                    <div className="row">
                        {this.props.submitType === false ?
                            <div className="col-12" style={{ textAlign: "center" }}>
                                <br /><br /><br />
                                <h1>You've completed your questionnaires!</h1>
                                <br />
                                <p>
                                    Check out your comparison below. When you're done, head back to your plan <a href="/account/meal-plan/week/1">here</a>.
                                </p>
                                <br />
                                <br />
                            </div>
                            :
                            <div className="col-12">
                                <br />
                                <br />
                                <h1 className="sectionTitle">
                                    <Content>questionnaire-page-title</Content> {this.props.submitType === "before" ? " - Pre Plan" : " - Post Plan"}
                                </h1>
                                <br />
                                <Content>questionnaire-page-text-top</Content>
                                <br />
                            </div>
                        }
                    </div>
                </div>
                <FlipMove
                    className="container"
                    enterAnimation="fade"
                    leaveAnimation="fade"
                    style={{
                        minHeight: "100vh"
                    }}
                >
                    <div className="row category-page" key={this.state.category}>
                        {this.state.category === 1 && ["before", "after"].includes(this.props.submitType)
                            ?
                            <React.Fragment>
                                <div className="col-12 question-section-header">
                                    <h2>
                                        <span>

                                        </span>
                                        Basic Information
                                    </h2>
                                </div>
                                {/* Age */}
                                <div className="col-12 col-md-6">
                                    <DropdownField
                                        label="Select your age range."
                                        options={[
                                            "Under 18",
                                            "18 - 24",
                                            "25 - 34",
                                            "35 - 44",
                                            "45 - 54",
                                            "55 - 64",
                                            "65+",
                                        ]}
                                        change={(err, val) => {
                                            this.setState({
                                                ageRange: val
                                            });
                                        }}
                                    >
                                        {this.state.ageRange}
                                    </DropdownField>
                                </div>
                                {/* Gender */}
                                <div className="col-12 col-md-6">
                                    <DropdownField
                                        label="Select your gender."
                                        options={[
                                            "Male",
                                            "Female",
                                            "Other/Prefer not to say",
                                        ]}
                                        change={(err, val) => {
                                            this.setState({
                                                gender: val
                                            });
                                        }}
                                    >
                                        {this.state.gender}
                                    </DropdownField>
                                </div>
                            </React.Fragment>
                            : null
                        }
                        {/* 1 - 10 questions */}
                        {questions
                            .filter(question => {
                                if (this.props.submitType === false) {
                                    return true;
                                } else {
                                    return question.category === this.state.category
                                }
                            })
                            .map(question => {
                                let result = <React.Fragment key={question.id + "_wrap"}>
                                    {!lastQuestion || question.category !== lastQuestion.category ?
                                        <div className="col-12 question-section-header" key={question.id + " " + question.category}>
                                            <h2>
                                                <span>
                                                    {categories.map(c => c.id).indexOf(question.category) + 1}
                                                </span>
                                                {categories.find(c => c.id === question.category).label}
                                            </h2>
                                        </div>
                                        : null
                                    }
                                    {
                                        this.props.submitType === false ?
                                            this.renderResult(question)
                                            :
                                            this.renderQuestion(question)
                                    }
                                </React.Fragment>
                                lastQuestion = question;
                                return result;
                            })}
                    </div>
                    {this.props.submitType !== false
                        ?
                        sectionCompleted
                            ?
                            <div className="control" key="controls" style={{
                                textAlign: "center"
                            }}>
                                {!onLastPage
                                    ?
                                    <button
                                        className="button"
                                        onClick={e => {
                                            if (this.working) return;
                                            this.working = true;
                                            setTimeout(() => {
                                                this.setState({
                                                    category: this.state.category + 1
                                                }, () => {
                                                    this.working = false;
                                                });
                                            }, 200);
                                            $('html, body').animate({
                                                scrollTop: $(".category-page").offset().top
                                            }, 300);
                                        }}
                                    >Continue</button>
                                    :
                                    <button
                                        className="button"
                                        onClick={e => {
                                            this.working = true;
                                            this.setState({
                                                working: true
                                            });
                                            $.ajax({
                                                url: `${this.context.apiRoot}/questionnaires`,
                                                method: "POST",
                                                data: JSON.stringify({
                                                    answers: this.state.answers,
                                                    gender: this.state.gender,
                                                    ageRange: this.state.ageRange,
                                                    type: this.props.submitType
                                                }),
                                                contentType: "application/json"
                                            }).then(response => {
                                                // console.log(response);
                                                if (this.props.submitType === "before") {
                                                    this.working = false;
                                                    this.setState({
                                                        working: false,
                                                        completed: true
                                                    });
                                                } else {
                                                    window.location.reload();
                                                }
                                            }).catch(err => {
                                                // Check for an attempted double submission, and refresh if that's the case.
                                                if (
                                                    err.responseJSON.msg &&
                                                    /You\'ve already submitted the \'[\w\d]+\' questionnaire/.test(err.responseJSON.msg)
                                                ) {
                                                    window.location.reload();
                                                } else {
                                                    console.error("Error submitting questionnaire: ", err);
                                                    this.setState({
                                                        working: false
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        Submit Questionnaire
                                    </button>
                                }
                            </div>
                            : <div className="notFinished">
                                Please complete all the questions!
                            </div>
                        : null}
                </FlipMove>
                {this.state.working ?
                    <div className="overlay" />
                    :
                    null
                }
            </div>
        )
    }
}

Questionnaire.contextType = Context;

export default Questionnaire;