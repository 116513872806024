/**
 *  PROPS
 * 
 *      week: NUMBER(1-4)  
 */
import React from "react";
import Carousel from "../../../assets/carousel";
import $ from "jquery";

import { Link } from "react-router-dom";

// Media import
import chevronLeft from "../../../media/icon-chevron-left.svg";
import chevronRight from "../../../media/icon-chevron-right.svg";
import swapIcon from "../../../media/icon-swap-gray.svg";

class MealPlanCarousel extends Carousel {

    constructor(props) {
        super(props);
        this.state = {
            position: 0
        }
    }

    itemsRenderedCount() {
        if (this.context.mode === "mobile") {
            return 1
        } else if (this.context.mode === "tablet") {
            return 2
        } else if (this.context.mode === "desktop") {
            return 3
        }
    }

    renderItems() {
        return this.props.items
            .map(item => {
                // Getting the day number
                let itemLocked = !this.props.paid && item.day > 3;
                return (
                    <div
                        key={JSON.stringify(item)}
                        className="dayPlan carouselItem"
                    >
                        <div className="inner shadow2">
                            <div className="planHeading" style={itemLocked ? {
                                filter: "saturate(0)",
                                opacity: 0.6
                            } : {}}>
                                <div className="dayNumber">
                                    <span>Day</span>
                                    <span>{item.day}</span>
                                </div>
                                <div className="dayName">
                                    {item.date.format("dddd")}
                                </div>
                                <div className="date">
                                    <span>{item.date.format("D")}</span>
                                    <span>{item.date.format("MMM")}</span>
                                </div>
                            </div>
                            {itemLocked
                                ?
                                <div className="meals meals-locked">
                                    <div className="padlock">
                                        <img src="/static/media/padlock.svg" alt="locked" />
                                    </div>
                                    <button className="button" onClick={e => {
                                        $('html, body').animate({
                                            scrollTop: $(".payment-container").offset().top
                                        }, 300)
                                    }}>
                                        FULL PLAN
                                    </button>
                                </div>
                                :
                                <div className="meals">
                                    {item.meals.map((meal, index) => {
                                        let enabled = this.props.mealsEnabled[index];
                                        let img;
                                        if (meal) {
                                            img = (meal.images && meal.images[0]) ?
                                                meal.images[0] :
                                                `/static/media/meals/${meal.name}.jpg`;
                                        } else {
                                            img = "/static/media/loading.gif";
                                        }
                                        // Type titles for each meal.
                                        let types = [
                                            "BREAKFAST",
                                            "LUNCH",
                                            "DINNER"
                                        ];
                                        // Calculate the week number
                                        let week = Math.ceil(item.day / 7);
                                        // Render the tile.
                                        return enabled
                                            ?
                                            <React.Fragment
                                                key={meal ? `${item.day}-${index}` : Math.random()}
                                            >
                                                <Link
                                                    to={`/account/meal-plan/week/${week}/day/${(item.day % 7) || 7}/${index}`}

                                                    className="meal"
                                                >
                                                    <div
                                                        className="mealImage"
                                                        style={{ backgroundImage: `url("${img}")` }}
                                                    />
                                                    <div className="info">
                                                        <h2>{types[index]}</h2>
                                                        <p>{meal ? meal.name : "Loading..."}</p>
                                                    </div>
                                                </Link>
                                                {index === 1
                                                    ?
                                                    <div className="swap-symbol">
                                                        <div className="dash left"></div>
                                                        <div className="text">
                                                            <img src={swapIcon} alt="swap icon" />
                                                            SWAPPABLE
                                                    </div>
                                                        <div className="dash right"></div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </React.Fragment>
                                            :
                                            <React.Fragment
                                                key={meal ? `${item.day}-${index}-disabled` : Math.random()}
                                            >
                                                <div className="meal" style={{
                                                    opacity: 0.4,
                                                    cursor: "default"
                                                }}>
                                                    <div
                                                        className="mealImage"
                                                        style={{
                                                            backgroundImage: `url("/static/media/meals/Vegan Rainbow Stir-fry.jpg")`,
                                                            filter: "saturate(0)"
                                                        }}
                                                    />
                                                    <div className="info">
                                                        <h2>{types[index]}</h2>
                                                        <p>You opted to make your own {types[index].toLowerCase()}. Stick to the rules!</p>
                                                    </div>
                                                </div>
                                                {index === 1
                                                    ?
                                                    <div className="swap-symbol">
                                                        <div className="dash left"></div>
                                                        <div className="text">
                                                            <img src={swapIcon} alt="swap icon" />
                                                            SWAPPABLE
                                                        </div>
                                                        <div className="dash right"></div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </React.Fragment>
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                )
            })
    }

    render() {
        return (
            <div className={`carousel ${this.props.type}`}>
                {this.state.position > 0
                    ?
                    <img
                        src={chevronLeft}
                        className="chevron left"
                        alt="chevron left"
                        onClick={() => {
                            this.setState({
                                position: this.state.position - 1
                            })
                        }}
                    />
                    :
                    null
                }
                {this.state.position < (this.props.items.length - this.itemsRenderedCount())
                    ?
                    <img
                        src={chevronRight}
                        className="chevron right"
                        alt="chevron right"
                        onClick={() => {
                            this.setState({
                                position: this.state.position + 1
                            })
                        }}
                    />
                    :
                    null
                }
                <div className="outer">
                    <div className="items">
                        {this.renderItems()}
                    </div>
                </div>
            </div>
        )
    }
}

export default MealPlanCarousel; 