/**
 *  Starting component with basic functionality repeated across all pages.
 * 
 *  PROPS:
 *      
 *      pageName: STRING 
 */

import $ from "jquery";
import React from 'react';
import Context from '../context';

class Page extends React.Component {

    componentDidMount() {
        $("html, body").scrollTop(0);
    }

    pageContent() {
        return "Overwrite me pls!"
    }

    render() {
        return (
            <div className={`page ${this.props.pageName}`}>
                {this.pageContent()}
            </div>
        )
    }

}

Page.contextType = Context;

export default Page;